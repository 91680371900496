import { CreateDoctorDto, UpdateDoctorDto } from '@services/api'
import { DoctorActions } from '../actions'

export const autocompleteDoctor = () => ({
  type: DoctorActions.GET_AUTOCOMPLETE_DOCTORS_REQUEST,
})

export const findDoctor = (params: {
  /**  */
  id: number
}) => ({
  type: DoctorActions.GET_DOCTOR_REQUEST,
  payload: params,
})

export const findAllDoctor = () => ({
  type: DoctorActions.GET_DOCTORS_REQUEST,
})

export const addDoctor = (params: {
  /** requestBody */
  body?: CreateDoctorDto
}) => ({
  type: DoctorActions.POST_DOCTOR_REQUEST,
  payload: params,
})

export const updateDoctor = (params: {
  /**  */
  id: number
  /** requestBody */
  body?: UpdateDoctorDto
}) => ({
  type: DoctorActions.PUT_DOCTOR_REQUEST,
  payload: params,
})
