import { Filter, Sorting } from '@devexpress/dx-react-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SuperAdminDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type superAdminState = {
  superAdmins: {
    totalCount: number
    datas: SuperAdminDto[]
  }
  filters: Filter[]
  sorting: Sorting[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  selected?: SuperAdminDto
  columnOrder: string[]
}

const initialState: superAdminState = {
  superAdmins: {
    totalCount: 0,
    datas: [],
  },
  filters: [],
  sorting: [{ columnName: 'plannedDate', direction: 'asc' }],
  currentPage: 0,
  pageSize: 10,
  selection: [],
  selected: undefined,
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringDoctor',
    'open',
    'actions',
  ],
}

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    setSuperAdmins: (
      state,
      action: PayloadAction<PaginatedDto<SuperAdminDto>>,
    ) => {
      state.superAdmins = action.payload
    },
    removeSuperAdmins: (state) => {
      state.superAdmins = initialState.superAdmins
    },
    addSuperAdmin: (state, action: PayloadAction<SuperAdminDto>) => {
      state.superAdmins.datas = [...state.superAdmins.datas, action.payload]
      state.superAdmins.totalCount += 1
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setSelection: (state, action: PayloadAction<(string | number)[]>) => {
      const selection = action.payload
      const index = selection[0] as number
      state.selected = state.superAdmins.datas[index]
    },
    setSelected: (state, action: PayloadAction<SuperAdminDto>) => {
      state.selected = action.payload
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload
    },
    cleanSuperAdmin: () => initialState,
  },
})

export const {
  setSuperAdmins,
  removeSuperAdmins,
  addSuperAdmin,
  setFilters,
  setSorting,
  setPageSize,
  setCurrentPage,
  setSelection,
  setSelected,
  setColumnOrder,
  cleanSuperAdmin,
} = superAdminSlice.actions

export default superAdminSlice.reducer
