import { Control, Controller } from 'react-hook-form'

import { MenuItem } from '@material-ui/core'

import { TextInput, TextInputProps } from './TextInput'

export type SelectInputProps = TextInputProps & {
  options: { value: string | number; label: string }[]
  control: Control<any>
  defaultValue?: string | number
}

export function SelectInput({
  options,
  control,
  input,
  name = input,
  defaultValue = '',
  ...props
}: SelectInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <TextInput
          {...props}
          style={{ borderRadius: 5 }}
          input={input}
          name={name}
          select
          value={value}
          onChange={onChange}
        >
          {options.map((option, index: number) => (
            <MenuItem key={index} value={option.value}>
              <em>{option.label}</em>
            </MenuItem>
          ))}
        </TextInput>
      )}
    />
  )
}
