import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { SuperAdminDto, SuperAdminService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { SuperAdminActions } from '../actions'
import { addSuperAdmin, setSuperAdmins } from '../reducers/superAdminReducer'

function* findAll() {
  try {
    const superAdmins: PaginatedDto<SuperAdminDto> = yield call(
      SuperAdminService.findAll,
    )
    yield put(setSuperAdmins(superAdmins))
    yield put({
      type: SuperAdminActions.GET_SUPER_ADMINS_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: SuperAdminActions.GET_SUPER_ADMINS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    yield call(SuperAdminService.findOne, payload)
    yield put({
      type: SuperAdminActions.GET_SUPER_ADMIN_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: SuperAdminActions.GET_SUPER_ADMIN_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}
function* create({ payload }: AnyAction) {
  try {
    const superAdmin: SuperAdminDto = yield call(
      SuperAdminService.create,
      payload,
    )
    yield put(addSuperAdmin(superAdmin))
    yield put({
      type: SuperAdminActions.POST_SUPER_ADMIN_SUCCESS,
      payload: { message: 'New superAdmin successfully added to site' },
    })
  } catch (e) {
    yield put({
      type: SuperAdminActions.POST_SUPER_ADMIN_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* update({ payload }: AnyAction) {
  try {
    yield call(SuperAdminService.update, payload)
    yield put({
      type: SuperAdminActions.PUT_SUPER_ADMIN_SUCCESS,
      payload: { message: 'SuperAdmin successfully updated' },
    })
  } catch (e) {
    yield put({
      type: SuperAdminActions.PUT_SUPER_ADMIN_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* superAdminFlow() {
  yield all([
    takeLatest(SuperAdminActions.GET_SUPER_ADMINS_REQUEST, findAll),
    takeLatest(SuperAdminActions.GET_SUPER_ADMINS_REQUEST, findOne),
    takeLatest(SuperAdminActions.POST_SUPER_ADMIN_REQUEST, create),
    takeLatest(SuperAdminActions.PUT_SUPER_ADMIN_REQUEST, update),
  ])
}

const superAdminSagas = [fork(superAdminFlow)]

export default superAdminSagas
