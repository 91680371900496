import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeLatest,
} from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { AuthService, CapchaDto, PatientDto, UserService } from '@services/api'
import { AuthActions } from '../actions'
import {
  loginSuccess,
  logout,
  setCaptcha,
  setProfile,
} from '../reducers/authReducer'
import history from '@services/history'
import { Account } from '../reducers/authReducer'
import { removeAuthToken, setAuthToken } from '@services/storage'

function* login({ payload }: AnyAction) {
  try {
    const { data, timeout } = yield race({
      data: call(AuthService.login, payload),
      timeout: delay(5 * 100000),
    })
    const { token, user } = data
    if (timeout) throw new Error()
    yield call(setAuthToken, token)
    yield put(loginSuccess(user))
    const profile: Account = yield call(UserService.findMyAccount)
    yield put(setProfile(profile))
    yield put({
      type: AuthActions.LOGIN_SUCCESS,
      payload: { message: 'Authentication success' },
    })
    history.push('/')
  } catch (e) {
    if (e.response)
      yield put({
        type: AuthActions.LOGIN_ERROR,
        payload: { message: e.response.data.message },
      })
    else
      yield put({
        type: AuthActions.LOGIN_ERROR,
        payload: { message: 'NETWORK_ERROR' },
      })
    // yield put(storeToken, null);
    // yield put(authorizeFailure(e));
  }
}

function* logoutFlow() {
  try {
    // yield call(AuthService.logout)
    yield call(removeAuthToken)
    yield put(logout())
    yield put({
      type: AuthActions.LOGOUT_SUCCESS,
      payload: { message: 'You have been disconnected' },
    })
  } catch (e) {
    if (e.response)
      yield put({
        type: AuthActions.LOGIN_ERROR,
        payload: { message: e.response.data.message },
      })
  }
}

function* firstConnexion({ payload }: AnyAction) {
  try {
    const patient: PatientDto = yield call(
      AuthService.isValidFirstConnection,
      payload,
    )
    yield put({
      type: AuthActions.FIRST_CONNEXION_SUCCESS,
      payload: { message: 'Authentication success', patient: patient },
    })
    history.push(
      `/auth/register?id=${payload.accessionNumber}&b=${patient.birthDate}`,
    )
  } catch (e) {
    yield put({
      type: AuthActions.FIRST_CONNEXION_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* register({ payload }: AnyAction) {
  try {
    const { token, user } = yield call(
      AuthService.createUserFirstConnection,
      payload,
    )
    yield call(setAuthToken, token)
    yield put(loginSuccess(user))
    const profile: Account = yield call(UserService.findMyAccount)
    yield put(setProfile(profile))
    yield put({
      type: AuthActions.REGISTER_SUCCESS,
      payload: { message: 'Register success' },
    })
    history.push('/')
  } catch (e) {
    yield put({
      type: AuthActions.REGISTER_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* verifyEmail({ payload }: AnyAction) {
  try {
    yield call(AuthService.createLocalUserFromEmail, payload)
    yield put({
      type: AuthActions.VERIFY_EMAIL_SUCCESS,
      payload: { message: 'User successfully registered' },
    })
    history.push('/auth/login')
  } catch (e) {
    yield put({
      type: AuthActions.VERIFY_EMAIL_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* forgottenPassword({ payload }: AnyAction) {
  try {
    yield call(AuthService.forgottenPassword, payload)
    yield put({
      type: AuthActions.FORGOTTEN_PASSWORD_SUCCESS,
      payload: { message: 'An email has been sent to you.' },
    })
    history.push('/auth/login')
  } catch (e) {
    yield put({
      type: AuthActions.FORGOTTEN_PASSWORD_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* resetPassword({ payload }: AnyAction) {
  try {
    yield call(AuthService.resetPassword, payload)
    yield put({
      type: AuthActions.FORGOTTEN_PASSWORD_SUCCESS,
      payload: { message: 'Password successfully reset' },
    })
    history.push('/auth/login')
  } catch (e) {
    yield put({
      type: AuthActions.FORGOTTEN_PASSWORD_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* getCaptcha() {
  try {
    const captcha: CapchaDto = yield call(AuthService.getCaptcha)
    yield put(setCaptcha(captcha))
  } catch (e) {
    yield put({
      type: AuthActions.GET_CAPTCHA_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* updatePassword({ payload }: AnyAction) {
  try {
    yield call(AuthService.updatePassword, payload)
    yield put({
      type: AuthActions.PUT_PASSWORD_SUCCESS,
      payload: { message: 'Password successfully updated' },
    })
  } catch (e) {
    yield put({
      type: AuthActions.PUT_PASSWORD_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* updateEmail({ payload }: AnyAction) {
  try {
    yield call(AuthService.updateEmail, payload)
    yield put({
      type: AuthActions.PUT_EMAIL_SUCCESS,
      payload: { message: 'Email successfully updated' },
    })
  } catch (e) {
    yield put({
      type: AuthActions.PUT_EMAIL_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* updatePhone({ payload }: AnyAction) {
  try {
    yield call(UserService.sendPhoneVerification, payload)
    yield put({
      type: AuthActions.PUT_PHONE_SUCCESS,
      payload: { message: 'A code has been sent to you phone' },
    })
  } catch (e) {
    yield put({
      type: AuthActions.PUT_PHONE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* verifyCode({ payload }: AnyAction) {
  try {
    yield call(UserService.phoneVerify, payload)
    yield put({
      type: AuthActions.VERIFY_CODE_SUCCESS,
      payload: { message: 'Phone successfully updated' },
    })
  } catch (e) {
    yield put({
      type: AuthActions.VERIFY_CODE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* authFlow() {
  yield all([
    takeLatest(AuthActions.LOGIN_REQUEST, login),
    takeLatest(AuthActions.LOGOUT_REQUEST, logoutFlow),
    takeLatest(AuthActions.FIRST_CONNEXION_REQUEST, firstConnexion),
    takeLatest(AuthActions.REGISTER_REQUEST, register),
    takeLatest(AuthActions.VERIFY_EMAIL_REQUEST, verifyEmail),
    takeLatest(AuthActions.PUT_EMAIL_REQUEST, updateEmail),
    takeLatest(AuthActions.FORGOTTEN_PASSWORD_REQUEST, forgottenPassword),
    takeLatest(AuthActions.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(AuthActions.GET_CAPTCHA_REQUEST, getCaptcha),
    takeLatest(AuthActions.PUT_PASSWORD_REQUEST, updatePassword),
    takeLatest(AuthActions.PUT_PHONE_REQUEST, updatePhone),
    takeLatest(AuthActions.VERIFY_CODE_REQUEST, verifyCode),
  ])
}

const authSagas = [fork(authFlow)]

export default authSagas
