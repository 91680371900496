import { Control, Controller } from 'react-hook-form'

import { Box, Grid, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

import { colors } from '@utils/constants'
export type ColorInputProps = {
  control: Control<any>
  input: string
}
export function ColorInput({ control, input }: ColorInputProps) {
  return (
    <Controller
      name={input}
      control={control}
      render={({ onChange, value: selected }) => {
        return (
          <Grid
            container
            style={{
              backgroundColor: '#f1f1f1',
              padding: 10,
              borderRadius: 4,
            }}
          >
            {colors.map(({ color, value }, index) => (
              <Grid
                item
                key={index}
                style={{
                  padding: 5,
                  backgroundColor: 'white',
                  height: 30,
                  borderRadius: 4,
                  margin: 5,
                  position: 'relative',
                  cursor: 'pointer',
                }}
                xs={2}
                sm={1}
                onClick={() => onChange(value)}
              >
                <Tooltip title={color}>
                  <Box
                    bgcolor={value}
                    width="100%"
                    height="100%"
                    borderRadius={2}
                  >
                    {selected === value && (
                      <CheckIcon
                        style={{
                          color: 'white',
                          position: 'absolute',
                          margin: 'auto',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          width: 20,
                          height: 20,
                          fontWeight: 900,
                        }}
                      />
                    )}
                  </Box>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        )
      }}
    />
  )
}
