import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const rows = [
  {
    equipment: 'Scanner',
    exam: 'ColosScanner',
    label: 'Label 1',
    interval: '10 min',
    updated: false,
  },
  {
    equipment: 'Otoscopes',
    exam: 'ORL',
    label: 'Label 2',
    interval: '20 min',
    updated: true,
  },
  {
    equipment: 'Equipment X',
    exam: 'Infitaion',
    label: 'Label 3',
    interval: '10 min',
    updated: false,
  },
  {
    equipment: 'Equipment Y',
    exam: 'exam X',
    label: 'Label 4',
    interval: '30 min',
    updated: true,
  },
  {
    equipment: 'Equipment Z',
    exam: 'exam X',
    label: 'Label 5',
    interval: '45 min',
    updated: false,
  },
]

export default function BasicTable() {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Equipment</TableCell>
            <TableCell align="center">Type Exam</TableCell>
            <TableCell align="center">Label</TableCell>
            <TableCell align="center">Interval</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.equipment}>
              <TableCell component="th" scope="row" align="center">
                {row.equipment}
              </TableCell>
              <TableCell align="center">{row.exam}</TableCell>
              <TableCell align="center">{row.label}</TableCell>
              <TableCell align="center">{row.interval}</TableCell>
              <TableCell align="center">
                <IconButton aria-label="delete" size="small" onClick={() => {}}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
