import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './authReducer'
import alertReducer from './alertReducer'
import patientReducer from './patientReducer'
import superAdminReducer from './superAdminReducer'
import loadingReducer from './loadingReducer'
import doctorReducer from './doctorReducer'
import studyReducer from './studyReducer'
import adminReducer from './adminReducer'
import clientReducer from './clientReducer'
import siteReducer from './siteReducer'
import testReducers from './testReducers'
import appointementReducer from './appointementReducer'
import edamSlice from './edamReducer'
import logReducer from './logReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  patient: patientReducer,
  doctor: doctorReducer,
  admin: adminReducer,
  superAdmin: superAdminReducer,
  study: studyReducer,
  site: siteReducer,
  client: clientReducer,
  alert: alertReducer,
  loading: loadingReducer,
  test: testReducers,
  appointement: appointementReducer,
  edam: edamSlice,
  log: logReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
