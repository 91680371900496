import { forwardRef, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { DateInput, SelectInput, TextArea, TimeInput } from '@components/inputs'

import { addClientSchema } from '@utils/schemas'
import { defaultSelectList } from '@utils/constants'
import { CreateClientDto } from '@services/api'

//TODO useless conponent ?
type ExceptionalOpeningFormProps = {
  onSubmit: (values: CreateClientDto) => void
}
export const ExceptionalOpeningForm = forwardRef(
  ({ onSubmit }: ExceptionalOpeningFormProps, ref) => {
    const { handleSubmit, errors, control } = useForm<CreateClientDto>({
      resolver: yupResolver(addClientSchema),
    })

    const beforeSubmit = (clientDto: CreateClientDto) => {
      onSubmit(clientDto)
    }
    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(beforeSubmit)()
      },
    }))

    return (
      <Grid container spacing={3}>
        {/* ROW 2 */}
        <Grid item xs={12}>
          <DateInput
            //defaultDate={dateAppointmentStart}
            input="dateAppointment"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          {/* {dateAppointmentStart.toLocaleTimeString()} */}
          <TimeInput
            input="startTime"
            //defaultValue={dateAppointmentStart}
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <TimeInput
            input="endTime"
            // defaultValue={dateAppointmentEnd}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            options={defaultSelectList}
            control={control}
            input="room"
            errors={errors}
          />{' '}
        </Grid>

        <Grid item xs={12}>
          <TextArea input="description" />
        </Grid>
      </Grid>
    )
  },
)
