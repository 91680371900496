// import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors'

export const ROWS_NUMBER = 15

export type I18nLocale = 'fr' | 'en'

export const TIMEOUT_AUTO_LOGOUT = 10000 * 60 * 20 // 20min
export const TIMEOUT_WARNING_LOGOUT = 10000 * 60 * 15 // 15min
export const TIMEOUT_UNIT = 10000 * 60 // min

export enum AlertType {
  NONE,
  SUCCESS,
  ERROR,
  WARNING,
}

/**
 * Axios Constants
 */

export enum AxiosMessage {
  NETWORK_ERROR = 'Network Error',
}

export enum ErrorCode {
  PATIENT_ALREADY_REGISTERED = 'PATIENT_ALREADY_REGISTERED',
  PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND',
  PASSWORDTOKEN_NOT_VALID = 'PASSWORDTOKEN_NOT_VALID',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}

/**
 * Examination Status
 */

export enum ExamStatusType {
  NOT_PLANNED = 0,
  IN_COMING = 1,
  DONE = 2,
  MISSED = 3,
}

export const passwordStrengthValues = [
  'uppercase',
  'lowercase',
  'number',
  'special',
  'length',
]

/**
 * Referentiels
 */

export type GenderType =
  | 'MALE'
  | 'FEMALE'
  | 'TRANSGENDERMALETOFEMALE'
  | 'TRANSGENDERFEMALETOMALE'
  | 'OTHER'
  | 'UNREPORTED'

export const studySharingExpirations = [
  { value: 0, label: 'No Expiration' },
  { value: 1, label: '1 week' },
  { value: 2, label: '2 weeks' },
  { value: 3, label: '3 weeks' },
  { value: 4, label: '1 month' },
  { value: 8, label: '2 month' },
]

export const studySharingPermissions = [
  { value: 'VIEW', label: 'View' },
  { value: 'VIEW_AND_REPORT', label: 'View and Report' },
  { value: 'EXPORT', label: 'Export' },
]

export const sex = [
  { value: '', label: 'All' },
  { value: 'F', label: 'Female' },
  { value: 'M', label: 'Male' },
  { value: 'O', label: 'Other' },
  { value: 'U', label: 'Unknown' },
]

export const scannerStudy = [
  { value: '', label: '' },
  { value: 'scanner', label: 'Scanner' },
  { value: 'coloscanner', label: 'Coloscanner' },
  { value: 'arthroscanner', label: 'Arthroscanner' },
  { value: 'angioscan', label: 'Angioscan' },
  { value: 'uroscanner', label: 'Uroscanner' },
  { value: 'infiltration', label: 'Infiltration' },
  { value: 'uroscanner', label: 'Uroscanner' },
  { value: 'orl', label: 'ORL' },
]

export const bodyPart = [
  { value: '', label: '' },

  { value: 'cerebral', label: 'cerebral' },
  { value: 'crane', label: 'crane' },
  { value: 'bassin', label: 'bassin' },
  { value: 'cheville', label: 'cheville' },
  { value: 'coude', label: 'coude' },
  { value: 'de la face', label: 'de la face' },
  { value: 'epaule', label: 'epaule' },
  { value: 'genou', label: 'genou' },
  { value: 'hanche', label: 'hanche' },
  { value: 'jambe', label: 'jambe' },
  { value: 'main', label: 'main' },
  { value: 'maxillaire', label: 'maxillaire' },
  { value: 'pelvimetrie', label: 'pelvimetrie' },
  { value: 'pied', label: 'pied' },
  { value: 'poignet', label: 'poignet' },
  { value: 'rachis cervical', label: 'rachis cervical' },
  { value: 'rachis dorsal', label: 'rachis dorsal' },
  { value: 'rachis lombaire', label: 'rachis lombaire' },
  { value: 'sinus', label: 'sinus' },
  { value: 'thoracique', label: 'thoracique' },
  { value: 'abdomino-pelvienne', label: 'abdomino-pelvienne' },
  { value: 'thoraco-abdomino-pelvien', label: 'thoraco-abdomino-pelvien' },
  { value: 'poignet', label: 'poignet' },
]
/**
 * Icons Map
 */

// interface IconMap {
//   [key: string]: {
//     icon: IconProp
//     color: string
//   }
// }

// export const examStatusMap: IconMap = {
//   IN_COMING: {
//     icon: ['far', 'calendar-alt'],
//     color: '#1CBCD8',
//   },
//   DONE: {
//     icon: ['far', 'calendar-check'],
//     color: '#0CC27E',
//   },
//   MISSED: {
//     icon: ['far', 'calendar-times'],
//     color: '#FF586B',
//   },
//   NOT_PLANNED: {
//     icon: 'question',
//     color: '#868e96',
//   },
// }

export const categoriesProfession = [
  { value: 'C', label: 'Civil' },
  { value: 'E', label: 'Etudiant' },
  { value: 'F', label: 'Fonctionnaire' },
  { value: 'M', label: 'Militaire' },
]

export const professions = [
  { value: 'all', label: 'All' },
  { value: '10', label: 'Médecin' },
  { value: '21', label: 'Pharmacien' },
  { value: '40', label: 'Chirurgien-dentiste' },
  { value: '50', label: 'Masseur-kinésithérapeute' },
  { value: '70', label: 'Sage-femme' },
  { value: '80', label: 'Podologue' },
]

export const allProfessions = [
  { value: 193, label: 'Acteur caractérisé par son rôle' },
  { value: 155, label: 'Assistant de service social' },
  { value: 133, label: 'Assistant dentaire' },
  { value: 5, label: 'Audioprothésiste' },
  { value: 154, label: 'Chiropracteur' },
  { value: 3, label: 'Chirurgien-Dentiste' },
  { value: 19, label: 'Diététicien' },
  { value: 14, label: 'Epithésiste' },
  { value: 73, label: 'Equipe médico - sociale APA' },
  { value: 18, label: 'Ergothérapeute' },
  { value: 74, label: 'Gestionnaire de cas MAIA' },
  { value: 7, label: 'Infirmier' },
  {
    value: 79,
    label: 'Mandataire judiciaire à la protection des majeurs (MJPM)',
  },
  { value: 21, label: 'Manipulateur ERM' },
  { value: 127, label: 'Maquilleur permanent' },
  { value: 53, label: 'Masseur-Kinésithérapeute' },
  { value: 1, label: 'Médecin' },
  { value: 77, label: 'Non PS mettant en œuvre la méthode MAIA' },
  { value: 81, label: 'Non PS salariés ou intervenants au sein d\'ESMS' },
  { value: 13, label: 'Oculariste' },
  { value: 6, label: 'Opticien-Lunetier' },
  { value: 16, label: 'Orthophoniste' },
  { value: 10, label: 'Orthoprothésiste' },
  { value: 17, label: 'Orthoptiste' },
  { value: 12, label: 'Orthopédiste-Orthésiste' },
  { value: 156, label: 'Ostéopathe' },
  { value: 126, label: 'Perceur corporel' },
  { value: 2, label: 'Pharmacien' },
  { value: 134, label: 'Physicien médical' },
  { value: 78, label: 'Pilote MAIA' },
  { value: 11, label: 'Podo-Orthésiste' },
  { value: 113, label: 'Profession non PS' },
  { value: 93, label: 'Psychologue' },
  { value: 20, label: 'Psychomotricien' },
  { value: 153, label: 'Psychothérapeute' },
  { value: 9, label: 'Pédicure-Podologue' },
  { value: 4, label: 'Sage-Femme' },
  { value: 125, label: 'Tatoueur' },
  { value: 15, label: 'Technicien de laboratoire médical' },
]

export const SMSkills = [
  { value: 'SM57', label: 'Allergologie' },
  { value: 'SM01', label: 'Anatomie et cytologie pathologiques' },
  { value: 'SM02', label: 'Anesthesie-réanimation' },
  { value: 'SM03', label: 'Biologie médicale' },
  { value: 'SM66', label: 'Biologie médicale option agents infectieux' },
  {
    value: 'SM67',
    label: 'Biologie médicale option biologie de la reproduction',
  },
  { value: 'SM63', label: 'Biologie médicale option biologie générale' },
  {
    value: 'SM65',
    label: 'Biologie médicale option hématologie et immunologie',
  },
  {
    value: 'SM64',
    label:
      'Biologie médicale option médecine moléculaire, génétique et pharmacologie',
  },
  { value: 'SM04', label: 'Cardiologie et maladies vasculaires' },
  { value: 'SM05', label: 'Chirurgie générale' },
  { value: 'SM09', label: 'Chirurgie infantile' },
  { value: 'SM68', label: 'Chirurgie maxillo-faciale (réforme 2017)' },
  { value: 'SM06', label: 'Chirurgie maxillo-faciale' },
  { value: 'SM07', label: 'Chirurgie maxillo-faciale et stomatologie' },
  { value: 'SM56', label: 'CHIRURGIE ORALE' },
  { value: 'SM08', label: 'Chirurgie orthopédique et traumatologie' },
  {
    value: 'SM69',
    label: 'Chirurgie pédiatrique option chirurgie viscérale pédiatrique',
  },
  {
    value: 'SM70',
    label: 'Chirurgie pédiatrique option orthopédie pédiatrique',
  },
  { value: 'SM10', label: 'Chirurgie plastique reconstructrice et esthétique' },
  { value: 'SM11', label: 'Chirurgie thoracique et cardio-vasculaire' },
  { value: 'SM12', label: 'Chirurgie urologique' },
  { value: 'SM13', label: 'Chirurgie vasculaire' },
  { value: 'SM14', label: 'Chirurgie viscérale et digestive' },
  { value: 'SM15', label: 'Dermatologie et vénéréologie' },
  { value: 'SM16', label: 'Endocrinologie et métabolisme' },
  { value: 'SM62', label: 'Endocrinologie, diabétologie, nutrition' },
  { value: 'SM24', label: 'Gastro-entérologie et hépatologie' },
  { value: 'SM17', label: 'Génétique médicale' },
  { value: 'SM18', label: 'Gériatrie' },
  {
    value: 'SM52',
    label: 'Gynéco-obstétrique et Gynéco médicale option Gynéco-médicale',
  },
  {
    value: 'SM51',
    label: 'Gynéco-obstétrique et Gynéco médicale option Gynéco-obst',
  },
  { value: 'SM19', label: 'Gynécologie médicale' },
  { value: 'SM20', label: 'Gynécologie-obstétrique' },
  { value: 'SM22', label: 'Hématologie (option Maladie du sang)' },
  { value: 'SM23', label: 'Hématologie (option Onco-hématologie)' },
  { value: 'SM71', label: 'Hématologie (réforme 2017)' },
  { value: 'SM21', label: 'Hématologie' },
  { value: 'SM58', label: 'Maladies infectieuses et tropicales' },
  { value: 'SM59', label: 'Médecine d\'urgence' },
  { value: 'SM25', label: 'Médecine du travail' },
  { value: 'SM54', label: 'Médecine Générale' },
  { value: 'SM46', label: 'Médecine intensive-réanimation' },
  { value: 'SM27', label: 'Médecine interne' },
  { value: 'SM72', label: 'Médecine interne et immunologie clinique' },
  { value: 'SM60', label: 'Médecine légale et expertises médicales' },
  { value: 'SM28', label: 'Médecine nucléaire' },
  { value: 'SM29', label: 'Médecine physique et réadaptation' },
  { value: 'SM61', label: 'Médecine vasculaire' },
  { value: 'SM31', label: 'Neuro-chirurgie' },
  { value: 'SM33', label: 'Neuro-psychiatrie' },
  { value: 'SM32', label: 'Neurologie' },
  { value: 'SM30', label: 'Néphrologie' },
  { value: 'SM34', label: 'O.R.L et chirurgie cervico faciale' },
  { value: 'SM35', label: 'Oncologie (option onco-hématologie)' },
  { value: 'SM36', label: 'Oncologie option médicale' },
  { value: 'SM37', label: 'Oncologie option radiothérapie' },
  { value: 'SM38', label: 'Ophtalmologie' },
  { value: 'SM99', label: 'ORL et ophtalmologie' },
  { value: 'SM39', label: 'Oto-rhino-laryngologie' },
  { value: 'SM40', label: 'Pédiatrie' },
  { value: 'SM41', label: 'Pneumologie' },
  { value: 'SM42', label: 'Psychiatrie' },
  { value: 'SM43', label: 'Psychiatrie option enfant &amp; adolescent' },
  { value: 'SM26', label: 'Qualifié en Médecine Générale' },
  { value: 'SM44', label: 'Radio-diagnostic' },
  { value: 'SM55', label: 'Radio-diagnostic et Radio-Thérapie' },
  { value: 'SM45', label: 'Radio-thérapie ' },
  { value: 'SM47', label: 'Recherche médicale' },
  { value: 'SM48', label: 'Rhumatologie' },
  { value: 'SM49', label: 'Santé publique et médecine sociale' },
  { value: 'SM53', label: 'Spécialiste en Médecine Générale' },
  { value: 'SM50', label: 'Stomatologie' },
  // { value: 'SM', label: 'Slide Microscopy' },
  // { value: 'SMR', label: 'Stereometric Relationship' },
  // { csan ode: 'SM', label: 'San Marino', phone: '378' },
]

export const allSkills = [
  { value: '81', label: 'Acupuncture' },
  { value: 'DEC01', label: 'ADDICTOLOGIE' },
  { value: 'DSM200', label: 'Addictologie' },
  { value: 'CAPA01', label: 'Addictologie clinique' },
  { value: 'CAPA02', label: 'Aide médicale urgente' },
  { value: 'C33', label: 'Allergologie' },
  { value: 'CAPA03', label: 'Allergologie' },
  { value: 'SM57', label: 'Allergologie' },
  { value: 'DEC02', label: 'ALLERGOLOGIE ET IMMUNOLOGIE CLINIQUE' },
  { value: 'DSM201', label: 'Allergologie et immunologie clinique' },
  { value: 'SCH01', label: 'Anatomie et cytologie pathologiques' },
  { value: 'SM01', label: 'Anatomie et cytologie pathologiques' },
  { value: 'C01', label: 'Anatomie et cytologie pathologiques humaines' },
  { value: 'DEC03', label: 'ANDROLOGIE' },
  { value: 'DSM202', label: 'Andrologie' },
  { value: 'SM02', label: 'Anesthesie-réanimation' },
  { value: 'C03', label: 'Anesthésie-réanimation' },
  { value: 'SCH02', label: 'Anesthésie-réanimation' },
  { value: 'C34', label: 'Angéiologie' },
  { value: 'CAPA04', label: 'Angiologie' },
  {
    value: 'SCH03',
    label: 'Bactériologie-virologie, hygi&egrave;ne hospitali&egrave;re',
  },
  { value: 'SCH04', label: 'Biochimie' },
  { value: 'DSM203', label: 'Biochimie hormonale et métabolique' },
  {
    value: 'SCH05',
    label:
      'Biologie cellulaire,histologie,biologie du développement et de la reproduction',
  },
  { value: 'DSM204', label: 'Biologie des agents infectieux' },
  { value: 'SCH06', label: 'Biologie médicale' },
  { value: 'SM03', label: 'Biologie médicale' },
  { value: 'SM66', label: 'Biologie médicale option agents infectieux' },
  {
    value: 'SM67',
    label: 'Biologie médicale option biologie de la reproduction',
  },
  { value: 'SM63', label: 'Biologie médicale option biologie générale' },
  {
    value: 'SM65',
    label: 'Biologie médicale option hématologie et immunologie',
  },
  {
    value: 'SM64',
    label:
      'Biologie médicale option médecine moléculaire, génétique et pharmacologie',
  },
  { value: 'DSM205', label: 'Biologie moléculaire' },
  { value: 'SCH07', label: 'Biophysique' },
  { value: 'DEC07', label: 'CANCEROLOGIE OPTION BIOLOGIE EN CANCEROLOGIE' },
  { value: 'DSM239', label: 'CANCEROLOGIE OPTION BIOLOGIE EN CANCEROLOGIE' },
  { value: 'DEC05', label: 'CANCEROLOGIE OPTION CHIRURGIE CANCEROLOGIQUE' },
  { value: 'DSM237', label: 'CANCEROLOGIE OPTION CHIRURGIE CANCEROLOGIQUE' },
  { value: 'DEC08', label: 'CANCEROLOGIE OPTION IMAGERIE EN CANCEROLOGIE' },
  { value: 'DSM240', label: 'CANCEROLOGIE OPTION IMAGERIE EN CANCEROLOGIE' },
  { value: 'DEC06', label: 'CANCEROLOGIE OPTION RESEAUX DE CANCEROLOGIE' },
  { value: 'DSM238', label: 'CANCEROLOGIE OPTION RESEAUX DE CANCEROLOGIE' },
  {
    value: 'DEC04',
    label: 'CANCEROLOGIE OPTION TRAITEMENTS MEDICAUX DES CANCERS',
  },
  {
    value: 'DSM236',
    label: 'CANCEROLOGIE OPTION TRAITEMENTS MEDICAUX DES CANCERS',
  },
  { value: 'C35', label: 'Cancérologie' },
  { value: 'DSM206', label: 'Cancérologie' },
  { value: 'C07', label: 'Cardiologie' },
  { value: 'SCH09', label: 'Cardiologie et maladies vasculaires' },
  { value: 'SM04', label: 'Cardiologie et maladies vasculaires' },
  { value: 'DSM207', label: 'Chirurgie de la face et du cou' },
  { value: 'C83', label: 'Chirurgie face et cou' },
  { value: 'SCH10', label: 'Chirurgie générale' },
  { value: 'SM05', label: 'Chirurgie générale' },
  { value: 'SCH12', label: 'Chirurgie infantile' },
  { value: 'SM09', label: 'Chirurgie infantile' },
  { value: 'SM68', label: 'Chirurgie maxillo-faciale (réforme 2017)' },
  { value: 'C10', label: 'Chirurgie maxillo-faciale' },
  { value: 'SCH13', label: 'Chirurgie maxillo-faciale' },
  { value: 'SM06', label: 'Chirurgie maxillo-faciale' },
  { value: 'SM07', label: 'Chirurgie maxillo-faciale et stomatologie' },
  { value: 'SCD02', label: 'Chirurgie Orale' },
  { value: 'SM56', label: 'CHIRURGIE ORALE' },
  { value: 'C12', label: 'Chirurgie orthopédique' },
  { value: 'SM08', label: 'Chirurgie orthopédique et traumatologie' },
  { value: 'SCH14', label: 'Chirurgie orthopédique et traumatologique' },
  { value: 'C68', label: 'Chirurgie pédiatrique' },
  {
    value: 'SM69',
    label: 'Chirurgie pédiatrique option chirurgie viscérale pédiatrique',
  },
  {
    value: 'SM70',
    label: 'Chirurgie pédiatrique option orthopédie pédiatrique',
  },
  { value: 'DSM208', label: 'Chirurgie plastique et reconstructrice' },
  { value: 'C09', label: 'Chirurgie plastique reconstructrice et esthétique' },
  {
    value: 'SCH15',
    label: 'Chirurgie plastique reconstructrice et esthétique',
  },
  { value: 'SM10', label: 'Chirurgie plastique reconstructrice et esthétique' },
  { value: 'C11', label: 'Chirurgie thoracique' },
  { value: 'SCH16', label: 'Chirurgie thoracique et cardio-vasculaire' },
  { value: 'SM11', label: 'Chirurgie thoracique et cardio-vasculaire' },
  { value: 'SCH17', label: 'Chirurgie urologique' },
  { value: 'SM12', label: 'Chirurgie urologique' },
  { value: 'DSM209', label: 'Chirurgie vasculaire' },
  { value: 'SCH18', label: 'Chirurgie vasculaire' },
  { value: 'SM13', label: 'Chirurgie vasculaire' },
  { value: 'SCH11', label: 'Chirurgie viscérale et digestive' },
  { value: 'SM14', label: 'Chirurgie viscérale et digestive' },
  { value: 'DSM210', label: 'Cytolgénétique humaine' },
  { value: 'C15', label: 'Dermato-vénéréologie' },
  { value: 'SCH19', label: 'Dermatologie et vénéréologie' },
  { value: 'SM15', label: 'Dermatologie et vénéréologie' },
  { value: 'DEC09', label: 'DERMATOPATHOLOGIE' },
  { value: 'DSM211', label: 'Dermatopathologie' },
  { value: 'C36', label: 'Diabétologie-nutrition' },
  { value: 'C37', label: 'Endocrinologie' },
  { value: 'SCH20', label: 'Endocrinologie et métabolisme' },
  { value: 'SM16', label: 'Endocrinologie et métabolisme' },
  { value: 'C75', label: 'Endocrinologie maladies metaboliques' },
  { value: 'SM62', label: 'Endocrinologie, diabétologie, nutrition' },
  { value: 'CAPA05', label: 'Evaluation et traitement de la douleur' },
  {
    value: 'SI03',
    label:
      'Exerc. infirmier pratique avancée maladie rénale chroniq.,dialyse,transp. rénale',
  },
  {
    value: 'SI02',
    label:
      'Exercice infirmier en pratique avancée oncologie et hémato-oncologie',
  },
  {
    value: 'SI01',
    label:
      'Exercice infirmier en pratique avancée pathologies chroniques stabilisées',
  },
  {
    value: 'SI04',
    label: 'Exercice infirmier en pratique avancée santé mentale',
  },
  {
    value: 'FQ03',
    label: 'Exp. prat. art. R.5124-16 du CSP Thérapie Cellulaire',
  },
  { value: 'FQ02', label: 'Expérience prat. art. R.5124-16 du CSP Exploitant' },
  { value: 'FQ01', label: 'Expérience prat. art. R.5124-16 du CSP Fabricant' },
  { value: 'FQ04', label: 'Expérience pratique article R.5124-18 du CSP' },
  { value: 'FQ05', label: 'Expérience pratique article R.5141-129 du CSP' },
  { value: 'SCH22', label: 'Explorations fonctionnelles' },
  { value: 'DEC10', label: 'FOETOPATHOLOGIE' },
  { value: 'DSM212', label: 'Foetopathologie' },
  { value: 'SCH23', label: 'Gastro-entérologie et hépatologie' },
  { value: 'SM24', label: 'Gastro-entérologie et hépatologie' },
  { value: 'SCH24', label: 'Génétique' },
  { value: 'C72', label: 'Génétique médicale' },
  { value: 'SCH25', label: 'Génétique médicale' },
  { value: 'SM17', label: 'Génétique médicale' },
  { value: 'DSM213', label: 'Gériatrie' },
  { value: 'SCH38', label: 'Gériatrie' },
  { value: 'SM18', label: 'Gériatrie' },
  { value: 'CAPA06', label: 'Gérontologie' },
  {
    value: 'SM52',
    label: 'Gynéco-obstétrique et Gynéco médicale option Gynéco-médicale',
  },
  {
    value: 'SM51',
    label: 'Gynéco-obstétrique et Gynéco médicale option Gynéco-obst',
  },
  { value: 'SCH26', label: 'Gynécologie et obstétrique' },
  { value: 'C25', label: 'Gynécologie médicale' },
  { value: 'CEX24', label: 'Gynécologie médicale' },
  { value: 'SM19', label: 'Gynécologie médicale' },
  {
    value: 'C23',
    label: 'Gynécologie médicale et obstétrique (Bi compétence)',
  },
  { value: 'CEX22', label: 'Gynécologie médicale et obstétrique' },
  { value: 'SM20', label: 'Gynécologie-obstétrique' },
  { value: 'DEC11', label: 'HEMOBIOLOGIE - TRANSFUSION' },
  { value: 'SM22', label: 'Hématologie (option Maladie du sang)' },
  { value: 'SM23', label: 'Hématologie (option Onco-hématologie)' },
  { value: 'SM71', label: 'Hématologie (réforme 2017)' },
  { value: 'SM21', label: 'Hématologie' },
  { value: 'DSM214', label: 'Hématologie biologique' },
  { value: 'SCH27', label: 'Hématologie biologique' },
  { value: 'SCH28', label: 'Hématologie clinique' },
  { value: 'DSM215', label: 'Hématologie maladies du sang' },
  { value: 'C20', label: 'Hémobiologie' },
  { value: 'DSM216', label: 'Hémobiologie-transfusion' },
  { value: 'SCH29', label: 'Hémobiologie-transfusion' },
  { value: 'SP04', label: 'Hémovigilance' },
  { value: '80', label: 'Homéopathie' },
  { value: 'CAPA07', label: 'Hydrologie et climatologie médicales' },
  { value: 'SP02', label: 'Hygi&egrave;ne' },
  { value: 'SCH31', label: 'Immunologie biologique' },
  { value: 'SCH32', label: 'Immunologie clinique' },
  { value: 'DSM217', label: 'Immunologie et immunopathologie' },
  {
    value: 'C29',
    label: 'Maladies de l\'appareil digestif',
  },
  { value: 'C38', label: 'Maladies du sang' },
  { value: 'SM58', label: 'Maladies infectieuses et tropicales' },
  { value: 'SCH33', label: 'Maladies infectieuses, maladies tropicales' },
  {
    value: 'DEC14',
    label: 'MEDECINE D\'URGENCE',
  },
  { value: 'DEC12', label: 'MEDECINE DE LA DOULEUR ET MEDECINE PALLIATIVE' },
  { value: 'DEC13', label: 'MEDECINE DE LA REPRODUCTION' },
  { value: 'DEC15', label: 'MEDECINE DU SPORT' },
  { value: 'DEC16', label: 'MEDECINE LEGALE ET EXPERTISES MEDICALES' },
  { value: 'DEC17', label: 'MEDECINE VASCULAIRE' },
  { value: 'CAPA08', label: 'Médecine aérospatiale' },
  { value: 'C05', label: 'Médecine appliquée aux sports' },
  { value: 'SCD03', label: 'Médecine Bucco-Dentaire' },
  {
    value: 'DSM220',
    label: 'Médecine d\'urgence',
  },
  {
    value: 'SCH36',
    label: 'Médecine d\'urgence',
  },
  {
    value: 'SM59',
    label: 'Médecine d\'urgence',
  },
  { value: 'CAPA09', label: 'Médecine de catastrophe' },
  { value: 'DSM235', label: 'Médecine de la douleur et médecine palliative' },
  { value: 'DSM218', label: 'Médecine de la reproduction' },
  {
    value: 'SCH34',
    label: 'Médecine de la reproduction et gynécologie médical',
  },
  {
    value: 'CAPA11',
    label:
      'Médecine de santé au travail et prévention des risques professionnels',
  },
  { value: 'DSM219', label: 'Médecine du sport' },
  { value: 'C41', label: 'Médecine du travail' },
  { value: 'SCH35', label: 'Médecine du travail' },
  { value: 'SM25', label: 'Médecine du travail' },
  { value: 'CAPA10', label: 'Médecine et biologie du sport' },
  { value: 'C31', label: 'Médecine exotique' },
  { value: 'SCH37', label: 'Médecine générale' },
  { value: 'SM54', label: 'Médecine Générale' },
  { value: 'SM46', label: 'Médecine intensive-réanimation' },
  { value: 'SCH39', label: 'Médecine interne' },
  { value: 'SM27', label: 'Médecine interne' },
  { value: 'SM72', label: 'Médecine interne et immunologie clinique' },
  { value: 'C40', label: 'Médecine légale' },
  { value: 'SCH40', label: 'Médecine légale' },
  { value: 'DSM221', label: 'Médecine légale et expertises médicales' },
  { value: 'SM60', label: 'Médecine légale et expertises médicales' },
  { value: 'C69', label: 'Médecine nucléaire' },
  { value: 'DSM222', label: 'Médecine nucléaire' },
  { value: 'SCH41', label: 'Médecine nucléaire' },
  { value: 'SM28', label: 'Médecine nucléaire' },
  { value: 'SCH42', label: 'Médecine physique et de réadaptation' },
  { value: 'C60', label: 'Médecine physique et réadaptation' },
  { value: 'SM29', label: 'Médecine physique et réadaptation' },
  { value: 'CAPA12', label: 'Médecine pénitentiaire' },
  { value: 'C71', label: 'Médecine thermale' },
  { value: 'CAPA13', label: 'Médecine tropicale' },
  { value: 'DSM223', label: 'Médecine vasculaire' },
  { value: 'SM61', label: 'Médecine vasculaire' },
  { value: 'DEC18', label: 'NEONATOLOGIE' },
  { value: 'C45', label: 'Neuro-chirurgie' },
  { value: 'SM31', label: 'Neuro-chirurgie' },
  { value: 'C47', label: 'Neuro-psychiatrie' },
  { value: 'SM33', label: 'Neuro-psychiatrie' },
  { value: 'SCH44', label: 'Neurochirurgie' },
  { value: 'C43', label: 'Neurologie' },
  { value: 'SCH45', label: 'Neurologie' },
  { value: 'SM32', label: 'Neurologie' },
  { value: 'DEC19', label: 'NEUROPATHOLOGIE' },
  { value: 'DSM225', label: 'Neuropathologie' },
  { value: 'DSM224', label: 'Néonatalogie' },
  { value: 'C30', label: 'Néphrologie' },
  { value: 'SCH43', label: 'Néphrologie' },
  { value: 'SM30', label: 'Néphrologie' },
  { value: 'DEC20', label: 'NUTRITION' },
  { value: 'DSM226', label: 'Nutrition' },
  { value: 'SM34', label: 'O.R.L et chirurgie cervico faciale' },
  { value: 'C27', label: 'Obstétrique' },
  { value: 'CEX26', label: 'Obstétrique' },
  { value: 'SCH46', label: 'Odontologie polyvalente' },
  { value: 'SM35', label: 'Oncologie (option onco-hématologie)' },
  { value: 'SCH08', label: 'Oncologie médicale' },
  { value: 'SM36', label: 'Oncologie option médicale' },
  { value: 'SM37', label: 'Oncologie option radiothérapie' },
  { value: 'SCH56', label: 'Oncologie radiothérapique' },
  { value: 'SCH47', label: 'Ophtalmologie' },
  { value: 'SM38', label: 'Ophtalmologie' },
  { value: 'SM99', label: 'ORL et ophtalmologie' },
  { value: 'DEC21', label: 'ORTHOPEDIE DENTO-MAXILLO-FACIALE' },
  { value: 'SCD01', label: 'Orthopédie dento-faciale' },
  { value: 'C76', label: 'Orthopédie dento-maxillo-faciale' },
  { value: 'DSM227', label: 'Orthopédie dento-maxillo-faciale' },
  { value: 'SCH48', label: 'Oto-rhino-laryngologie' },
  { value: 'SM39', label: 'Oto-rhino-laryngologie' },
  { value: 'SCH49', label: 'Parasitologie' },
  {
    value: 'DEC22',
    label: 'PATHOLOGIE INFECTIEUSE ET TROPICALE, CLINIQUE ET BIOLOGIQUE',
  },
  {
    value: 'DSM228',
    label: 'Pathologie infectieuse et tropicale, clinique et biologique',
  },
  {
    value: 'SCH51',
    label: 'Pharmacie polyvalente et pharmacie hospitali&egrave;re',
  },
  {
    value: 'DSM229',
    label: 'Pharmacocinétique et métabolisme des médicaments',
  },
  {
    value: 'DEC23',
    label: 'PHARMACOLOGIE CLINIQUE ET EVALUATION DES THERAPEUTIQUES',
  },
  {
    value: 'DSM230',
    label: 'Pharmacologie clinique et éval. des thérapeutiques',
  },
  { value: 'SCH52', label: 'Pharmacologie clinique et toxicologie' },
  { value: 'SP03', label: 'Pharmacovigilance' },
  { value: 'C52', label: 'Phoniatrie' },
  { value: 'C51', label: 'Pédiatrie' },
  { value: 'SCH50', label: 'Pédiatrie' },
  { value: 'SM40', label: 'Pédiatrie' },
  { value: 'C54', label: 'Pneumologie' },
  { value: 'SCH53', label: 'Pneumologie' },
  { value: 'SM41', label: 'Pneumologie' },
  { value: 'CAPA14', label: 'Pratiques médico-judiciaires' },
  { value: 'C57', label: 'Psychiatrie' },
  { value: 'SM42', label: 'Psychiatrie' },
  { value: 'DEC24', label: 'PSYCHIATRIE DE L\'ENFANT ET DE L\'ADOLESCENT' },
  { value: 'DSM231', label: 'Psychiatrie de l\'enfant et de l\'adolescent' },
  { value: 'C58', label: 'Psychiatrie opt enfant adolescent' },
  { value: 'SM43', label: 'Psychiatrie option enfant &amp; adolescent' },
  { value: 'SCH54', label: 'Psychiatrie polyvalente' },
  { value: 'PAC00', label: 'Qualification PAC' },
  { value: 'SM26', label: 'Qualifié en Médecine Générale' },
  { value: 'SM44', label: 'Radio-diagnostic' },
  { value: 'SM55', label: 'Radio-diagnostic et Radio-Thérapie' },
  { value: 'SP01', label: 'Radio-pharmacie' },
  { value: 'SM45', label: 'Radio-thérapie ' },
  { value: 'SCH55', label: 'Radiologie' },
  { value: 'DSM232', label: 'Radiopharmacie et radiobiologie' },
  { value: 'SM47', label: 'Recherche médicale' },
  { value: 'C62', label: 'Rhumatologie' },
  { value: 'SCH58', label: 'Rhumatologie' },
  { value: 'SM48', label: 'Rhumatologie' },
  { value: 'C39', label: 'Réanimation' },
  { value: 'DSM233', label: 'Réanimation médicale' },
  { value: 'SCH57', label: 'Réanimation médicale' },
  { value: 'SCH21', label: 'Santé publique' },
  { value: 'SM49', label: 'Santé publique et médecine sociale' },
  { value: 'SM53', label: 'Spécialiste en Médecine Générale' },
  { value: 'SCH59', label: 'Stomatologie' },
  { value: 'SM50', label: 'Stomatologie' },
  { value: 'CAPA15', label: 'Technologie transfusionnelle' },
  { value: 'DSM234', label: 'Toxicologie biologique' },
  { value: 'SCH60', label: 'Toxicologie et pharmacologie' },
  { value: 'CAPA16', label: 'Toxicomanies et alcoologies' },
  { value: 'C13', label: 'Urologie' },
  { value: 'CEX64', label: 'Urologie' },
]

export const statusStudy = [
  { value: '', label: 'All' },
  { value: 'SC', label: 'Scheduled' },
  { value: 'IP', label: 'In Progress' },
  { value: 'CA', label: 'Canceled' },
  { value: 'CM', label: 'Completed' },
]

export const siteStatus = [
  { value: '', label: 'All' },
  { value: '101', label: 'Centre Hospitalier Régional (C.H.R.)' },
  { value: '114', label: 'Hôpital des armées' },
  { value: '128', label: 'Etablissement de Soins Chirurgicaux' },
  { value: '129', label: 'Etablissement de Soins Médicaux' },
  { value: '131', label: 'Centre de Lutte Contre Cancer' },
  {
    value: '292',
    label: 'Centre Hospitalier Spécialisé lutte Maladies Mentales',
  },
  { value: '355', label: 'Centre Hospitalier (C.H.)' },
  { value: '365', label: 'Etablissement de Soins Pluridisciplinaire' },
  { value: '696', label: 'Groupement de coopération sanitaire de moyens' },
  {
    value: '697',
    label: 'Groupement de coopération sanitaire - Etablissement de santé',
  },
  { value: '698', label: 'Autre Etablissement Loi Hospitalière' },
  { value: '699', label: 'Entité Ayant Autorisation' },
]

export const clientStatus = [
  { value: '', label: 'All' },
  { value: '1', label: 'Etat' },
  { value: '10', label: 'Etablissement Public National d\'Hospitalisation' },
  {
    value: '11',
    label: 'Etablissement Public Départemental d\'Hospitalisation',
  },
  { value: '13', label: 'Etablissement Public Communal d\'Hospitalisation' },
  {
    value: '14',
    label: 'Etablissement Public Intercommunal d\'Hospitalisation',
  },
  { value: '15', label: 'Etablissement Public Régional d\'Hospitalisation' },
  {
    value: '27',
    label: 'Etablissement Public à Caractère Industriel ou Commercial',
  },
  { value: '29', label: 'Groupement de Coopération Sanitaire public' },
  { value: '44', label: 'Régime Maladie des non Salariés non Agricole' },
  { value: '47', label: 'Société Mutualiste' },
  { value: '49', label: 'Autre Organisme Mutualiste' },
  {
    value: '60',
    label: 'Association Loi 1901 non Reconnue d\'Utilité Publique',
  },
  { value: '61', label: 'Association Loi 1901 Reconnue d\'Utilité Publique' },
  { value: '62', label: 'Association de Droit Local' },
  { value: '63', label: 'Fondation' },
  { value: '64', label: 'Congrégation' },
  { value: '65', label: 'Autre Organisme Privé à But non Lucratif' },
  { value: '70', label: 'Personne Physique' },
  { value: '72', label: 'Société A Responsabilité Limitée (S.A.R.L.)' },
  { value: '73', label: 'Société Anonyme (S.A.)' },
  { value: '74', label: 'Société Civile' },
  { value: '75', label: 'Autre Société' },
  { value: '76', label: 'Groupement d\'Intérêt Economique (G.I.E.)' },
  { value: '77', label: 'Autre Organisme Privé à Caractère Commercial' },
  {
    value: '78',
    label: 'Entreprise Unipersonnelle Responsabilité Limitée (E.U.R.L.)',
  },
  { value: '79', label: 'Société Civile de Moyens (S.C.M.)' },
  {
    value: '85',
    label: 'Soc. Exercice Libéral Responsabilité Limitée (S.E.L.A.R.L.)',
  },
  { value: '88', label: 'Société Civile Professionnelle (S.C.P.)' },
  { value: '89', label: 'Groupement de Coopération Sanitaire Privéete' },
  {
    value: '91',
    label: 'Société Exercice Libéral par Actions Simplifiée (S.E.L.A.S.)',
  },
  { value: '95', label: 'Société par Actions Simplifiée (S.A.S.)' },
]

export const modalityType = [
  { value: '', label: 'All' },
  // { value: 'AR', label: 'Autorefraction' },
  { value: 'AS', label: 'Angioscopy' },
  // { value: 'ASMT', label: 'Content Assessment Results' },
  // { value: 'AU', label: 'Audio' },
  { value: 'BDUS', label: 'Bone Densitometry (ultrasound)' },
  // { value: 'BI', label: 'Biomagnetic imaging' },
  // { value: 'BMD', label: 'Bone Densitometry (X-Ray)' },
  { value: 'CD', label: 'Color flow Doppler' },
  // { value: 'CF', label: 'Cinefluorography' },
  { value: 'CP', label: 'Colposcopy' },
  { value: 'CR', label: 'Computed Radiography' },
  // { value: 'CS', label: 'Cystoscopy' },
  { value: 'CT', label: 'Computed Tomography' },
  { value: 'DD', label: 'Duplex Doppler' },
  // { value: 'DF', label: 'Digital fluoroscopy' },
  // { value: 'DG', label: 'Diaphanography' },
  // { value: 'DM', label: 'Digital microscopy' },
  // { value: 'DOC', label: 'Document' },
  // { value: 'DS', label: 'Digital Subtraction Angiography' },
  { value: 'DX', label: 'Digital Radiography' },
  { value: 'EC', label: 'Echocardiography' },
  { value: 'ECG', label: 'Electrocardiography' },
  { value: 'EPS', label: 'Cardiac Electrophysiology' },
  { value: 'ES', label: 'Endoscopy' },
  // { value: 'FA', label: 'Fluorescein angiography' },
  // { value: 'FID', label: 'Fiducials' },
  // { value: 'FS', label: 'Fundoscopy' },
  // { value: 'GM', label: 'General Microscopy' },
  // { value: 'HC', label: 'Hard Copy' },
  // { value: 'HD', label: 'Hemodynamic Waveform' },
  // { value: 'IO', label: 'Intra-Oral Radiography' },
  // { value: 'IOL', label: 'Intraocular Lens Data' },
  // { value: 'IVOCT', label: 'Intravascular Optical Coherence Tomography' },
  // { value: 'IVUS', label: 'Intravascular Ultrasound' },
  // { value: 'KER', label: 'Keratometry' },
  // { value: 'KO', label: 'Key Object Selection' },
  // { value: 'LEN', label: 'Lensometry' },
  // { value: 'LP', label: 'Laparoscopy' },
  // { value: 'LS', label: 'Laser surface scan' },
  { value: 'MA', label: 'Magnetic resonance angiography' },
  { value: 'MG', label: 'Mammography' },
  { value: 'MR', label: 'Magnetic Resonance' },
  { value: 'MS', label: 'Magnetic resonance spectroscopy' },
  { value: 'NM', label: 'Nuclear Medicine' },
  { value: 'OAM', label: 'Ophthalmic Axial Measurements	' },
  // { value: 'OCT', label: 'Optical Coherence Tomography (non-Ophthalmic)' },
  // { value: 'OP', label: 'Ophthalmic Photography' },
  // { value: 'OPM', label: 'Ophthalmic Mapping' },
  // { value: 'OPR', label: 'Ophthalmic Refraction' },
  // { value: 'OPT', label: 'Ophthalmic Tomography' },
  // { value: 'OPV', label: 'Ophthalmic Visual Field' },
  // { value: 'OSS', label: 'Optical Surface Scan' },
  // { value: 'OT', label: 'Other' },
  // { value: 'PLAN', label: 'Plan' },
  // { value: 'PR', label: 'Presentation State' },
  { value: 'PT', label: 'Positron emission tomography (PET)' },
  { value: 'PX', label: 'Panoramic X-Ray' },
  // { value: 'REG', label: 'Registration' },
  { value: 'RESP', label: 'Respiratory Waveform' },
  { value: 'RF', label: 'Radio Fluoroscopy' },
  { value: 'RG', label: 'Radiographic imaging (conventional film/screen)' },
  { value: 'RTDOSE', label: 'Radiotherapy Dose' },
  { value: 'RTIMAGE', label: 'Radiotherapy Image' },
  { value: 'RTPLAN', label: 'Radiotherapy Plan' },
  { value: 'RTRECORD', label: 'RT Treatment Record' },
  { value: 'RTSTRUCT', label: 'Radiotherapy Structure Set' },
  // { value: 'RWV', label: 'Real World Value Map' },
  // { value: 'SEG', label: 'Segmentation' },
  // { value: 'SM', label: 'Slide Microscopy' },
  // { value: 'SMR', label: 'Stereometric Relationship' },
  // { value: 'SR', label: 'SR Document' },
  // { value: 'SRF', label: 'Subjective Refraction' },
  { value: 'ST', label: 'Single-photon emission computed tomography (SPECT)' },
  // { value: 'STAIN', label: 'Automated Slide Stainer' },
  // { value: 'TG', label: 'Thermography' },
  { value: 'US', label: 'Ultrasound' },
  // { value: 'VA', label: 'Visual Acuity' },
  // { value: 'VF', label: 'Videofluorography' },
  { value: 'XA', label: 'X-Ray Angiography' },
  { value: 'XC', label: 'External-camera Photography' },
]
export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: 'Cote d\'Ivoire', phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49', suggested: true },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33', suggested: true },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81', suggested: true },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: 'Korea, Democratic People\'s Republic of', phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: 'Lao People\'s Democratic Republic', phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1', suggested: true },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
]

export const dataAppointments = [
  {
    title: 'Website Re-Design Plan',
    startDate: '2018-10-31T10:00',
    endDate: '2018-10-31T11:15',
    id: 0,
    location: 'Radio 1',
    type: 'private',
    taken: true,
  },
  {
    title: 'Book Flights to San Fran for Sales Trip',
    startDate: '2018-10-31T10:00',
    endDate: '2018-10-31T11:15',
    id: 1,
    location: 'Radio 1',
    type: 'work',
    taken: true,
  },
  {
    title: 'Install New Router in Dev Room',
    startDate: '2018-5-25T10:00',
    endDate: '2018-5-25T11:15',
    id: 2,
    location: 'Echo 2',
    type: 'work',
    taken: true,
  },
  {
    title: 'Approve Personal Computer Upgrade Plan',
    startDate: new Date(2018, 5, 26, 10, 0),
    endDate: new Date(2018, 5, 26, 11, 0),
    id: 3,
    location: 'Scan 1',
    type: 'work',
    taken: 'close',
  },
  {
    title: 'Approve xxxxxxxxxxx',
    startDate: new Date(2018, 5, 27, 12, 0),
    endDate: new Date(2018, 5, 27, 13, 0),
    id: 23,
    location: 'Scan 1',
    type: 'work',
    taken: 'break',
  },
  {
    title: 'Final Budget Review',
    startDate: new Date(2018, 5, 26, 12, 0),
    endDate: new Date(2018, 5, 26, 13, 35),
    id: 4,
    location: 'Scan 1',
  },
  {
    title: 'New Brochures',
    startDate: new Date(2018, 5, 26, 14, 30),
    endDate: new Date(2018, 5, 26, 15, 45),
    id: 5,
    location: 'Scan 1',
  },
  {
    title: 'Install New Database',
    startDate: new Date(2018, 5, 27, 9, 45),
    endDate: new Date(2018, 5, 27, 11, 15),
    id: 6,
    location: 'Radio 1',
  },
  {
    title: 'Approve New Online Marketing Strategy',
    startDate: new Date(2018, 5, 27, 12, 0),
    endDate: new Date(2018, 5, 27, 14, 0),
    id: 7,
    location: 'Echo 1',
  },
  {
    title: 'Upgrade Personal Computers',
    startDate: new Date(2018, 5, 27, 15, 15),
    endDate: new Date(2018, 5, 27, 16, 30),
    id: 8,
    location: 'Echo 1',
  },
  {
    title: 'Customer Workshop',
    startDate: new Date(2018, 5, 28, 11, 0),
    endDate: new Date(2018, 5, 28, 12, 0),
    id: 9,
    location: 'Echo 1',
  },
  {
    title: 'Prepare 2015 Marketing Plan',
    startDate: new Date(2018, 5, 28, 11, 0),
    endDate: new Date(2018, 5, 28, 13, 30),
    id: 10,
    location: 'Radio 1',
  },
  {
    title: 'Brochure Design Review',
    startDate: new Date(2018, 5, 28, 14, 0),
    endDate: new Date(2018, 5, 28, 15, 30),
    id: 11,
    location: 'Scan 1',
  },
  {
    title: 'Create Icons for Website',
    startDate: new Date(2018, 5, 29, 10, 0),
    endDate: new Date(2018, 5, 29, 11, 30),
    id: 12,
    location: 'Scan 1',
  },
  {
    title: 'Upgrade Server Hardware',
    startDate: new Date(2018, 5, 29, 14, 30),
    endDate: new Date(2018, 5, 29, 16, 0),
    id: 13,
    location: 'Echo 1',
  },
  {
    title: 'Submit New Website Design',
    startDate: new Date(2018, 5, 29, 16, 30),
    endDate: new Date(2018, 5, 29, 18, 0),
    id: 14,
    location: 'Echo 1',
  },
  {
    title: 'Launch New Website',
    startDate: new Date(2018, 5, 29, 12, 20),
    endDate: new Date(2018, 5, 29, 14, 0),
    id: 15,
    location: 'Scan 1',
  },
  {
    title: 'Website Re-Design Plan',
    startDate: new Date(2018, 6, 2, 9, 30),
    endDate: new Date(2018, 6, 2, 15, 30),
    id: 16,
    location: 'Radio 1',
  },
  {
    title: 'Book Flights to San Fran for Sales Trip',
    startDate: new Date(2018, 6, 2, 12, 0),
    endDate: new Date(2018, 6, 2, 13, 0),
    id: 17,
    location: 'Echo 1',
  },
  {
    title: 'Install New Router in Dev Room',
    startDate: new Date(2018, 6, 2, 14, 30),
    endDate: new Date(2018, 6, 2, 17, 30),
    id: 18,
    location: 'Scan 1',
  },
  {
    title: 'Approve Personal Computer Upgrade Plan',
    startDate: new Date(2018, 6, 2, 16, 0),
    endDate: new Date(2018, 6, 3, 9, 0),
    id: 19,
    location: 'Scan 1',
  },
  {
    title: 'Final Budget Review',
    startDate: new Date(2018, 6, 3, 10, 15),
    endDate: new Date(2018, 6, 3, 13, 35),
    id: 20,
    location: 'Radio 1',
  },
  {
    title: 'New Brochures',
    startDate: new Date(2018, 6, 3, 14, 30),
    endDate: new Date(2018, 6, 3, 15, 45),
    id: 21,
    location: 'Echo 1',
  },
  {
    title: 'Install New Database',
    startDate: new Date(2018, 6, 3, 15, 45),
    endDate: new Date(2018, 6, 4, 12, 15),
    id: 22,
    location: 'Echo 1',
  },
  {
    title: 'Approve New Online Marketing Strategy',
    startDate: new Date(2018, 6, 4, 12, 35),
    endDate: new Date(2018, 6, 4, 14, 15),
    id: 23,
    location: 'Echo 1',
  },
  {
    title: 'Upgrade Personal Computers',
    startDate: new Date(2018, 6, 4, 15, 15),
    endDate: new Date(2018, 6, 4, 20, 30),
    id: 24,
    location: 'Scan 1',
  },
  {
    title: 'Customer Workshop',
    startDate: new Date(2018, 6, 5, 6, 0),
    endDate: new Date(2018, 6, 5, 14, 20),
    id: 25,
    location: 'Radio 1',
  },
  {
    title: 'Customer Workshop',
    startDate: new Date(2018, 6, 5, 14, 35),
    endDate: new Date(2018, 6, 5, 16, 20),
    id: 26,
    location: 'Radio 1',
  },
  {
    title: 'Customer Workshop 2',
    startDate: new Date(2018, 6, 5, 10, 0),
    endDate: new Date(2018, 6, 5, 11, 20),
    id: 27,
    location: 'Scan 1',
  },
  {
    title: 'Prepare 2015 Marketing Plan',
    startDate: new Date(2018, 6, 5, 20, 0),
    endDate: new Date(2018, 6, 6, 13, 30),
    id: 28,
    location: 'Echo 1',
  },
  {
    title: 'Brochure Design Review',
    startDate: new Date(2018, 6, 6, 14, 10),
    endDate: new Date(2018, 6, 6, 15, 30),
    id: 29,
    location: 'Echo 1',
  },
  {
    title: 'Create Icons for Website',
    startDate: new Date(2018, 6, 6, 10, 0),
    endDate: new Date(2018, 6, 7, 14, 30),
    id: 30,
    location: 'Radio 1',
  },
  {
    title: 'Upgrade Server Hardware',
    startDate: new Date(2018, 6, 3, 9, 30),
    endDate: new Date(2018, 6, 3, 12, 25),
    id: 31,
    location: 'Scan 1',
  },
  {
    title: 'Submit New Website Design',
    startDate: new Date(2018, 6, 3, 12, 30),
    endDate: new Date(2018, 6, 3, 18, 0),
    id: 32,
    location: 'Scan 1',
  },
  {
    title: 'Launch New Website',
    startDate: new Date(2018, 6, 3, 12, 20),
    endDate: new Date(2018, 6, 3, 14, 10),
    id: 33,
    location: 'Scan 1',
  },
  {
    title: 'Book Flights to San Fran for Sales Trip',
    startDate: new Date(2018, 5, 26, 0, 0),
    endDate: new Date(2018, 5, 27, 0, 0),
    id: 34,
    location: 'Radio 1',
  },
  {
    title: 'Customer Workshop',
    startDate: new Date(2018, 5, 29, 10, 0),
    endDate: new Date(2018, 5, 30, 14, 30),
    id: 35,
    location: 'Radio 1',
  },
  {
    title: 'Google AdWords Strategy',
    startDate: new Date(2018, 6, 3, 0, 0),
    endDate: new Date(2018, 6, 4, 10, 30),
    id: 36,
    location: 'Echo 1',
  },
  {
    title: 'Rollout of New Website and Marketing Brochures',
    startDate: new Date(2018, 6, 5, 10, 0),
    endDate: new Date(2018, 6, 9, 14, 30),
    id: 37,
    location: 'Echo 1',
  },
  {
    title: 'Update NDA Agreement',
    startDate: new Date(2018, 6, 1, 10, 0),
    endDate: new Date(2018, 6, 3, 14, 30),
    id: 38,
    location: 'Scan 1',
  },
  {
    title: 'Customer Workshop',
    startDate: new Date(2018, 6, 1),
    endDate: new Date(2018, 6, 2),
    allDay: true,
    id: 39,
    location: 'Radio 1',
  },
]
// ***************** User Roles *****************
export const userRole = [
  { value: 'patient', label: 'Patient' },
  { value: 'doctor', label: 'Doctor' },
  { value: 'admin', label: 'Admin' },
  { value: 'super_admin', label: 'Super Admin' },
  { value: 'user', label: 'Utilisateur' },
]
// *****************STAFF Roles & Specialities*****************
// ***************** Roles *****************
export const adminRole = [
  { value: 'healthExecutive', label: 'Health Executive' },
  { value: 'executiveDirecto', label: 'Executive Directo' },
  { value: 'financeDirector', label: 'Finance Director' },
  { value: 'accountant', label: 'Accountant' },
  { value: 'computerTechnician', label: 'Computer Technician' },
  { value: 'security', label: 'Security' },

  { value: 'RSSI', label: 'RSSI' },
  { value: 'DSI', label: 'DSI' },
  { value: 'secretary', label: 'Secretary' },
  { value: 'radiographer', label: 'Radiographer' },
  { value: 'serviceProvider', label: 'Service Provider' },
  { value: 'ITProvider', label: 'ITProvider' },

  { value: 'manager', label: 'Manager' },
  { value: 'radiologist', label: 'Radiologist' },
  { value: 'headRadiology', label: 'Head of Radiology' },
  { value: 'qualityManager', label: 'Quality Manager' },
  { value: 'headEmergencies', label: 'Head of Emergencies' },
  { value: 'biomedicalEngineer', label: 'Biomedical Engineer' },
]

// ***************** Specialities *****************\
export const radiologistSpecialities = [
  { value: '1', label: 'Oncology' },
  { value: '2', label: 'Pediatric' },
  { value: '3', label: 'MRI MSK' },
  { value: '4', label: 'Neuro Radio' },
  { value: '5', label: 'Vascular' },
  { value: '6', label: 'CT' },
  { value: '7', label: 'MRI' },
  { value: '8', label: 'Conventional radiology' },
]

export const typeImagingDoctor = [
  { value: '1', label: 'Conventional Radiology' },
  { value: '2', label: 'CT' },
  { value: '3', label: 'MR' },
  { value: '4', label: 'MG' },
  { value: '5', label: 'US' },
  { value: '6', label: 'CR-Dx' },
  { value: '7', label: 'NM' },
  { value: '8', label: 'PET' },
]

export const typeImaging = [
  { value: 1, label: 'Conventional Radiology' },
  { value: 2, label: 'CT Scan' },
  { value: 4, label: 'MRI' },
]

export const CT_SPECIALISTES = [
  { value: '5', label: 'Oncology' },
  { value: '6', label: 'Digestive' },
  { value: '7', label: 'Brain' },
  { value: '8', label: 'MSK' },
  { value: '9', label: 'Cardiac CT' },
  { value: '10', label: 'Thoracic' },
  { value: '11', label: 'Pediatric' },
]

export const MRI_SPECIALISTES = [
  { value: '12', label: 'Oncology' },
  { value: '13', label: 'Womens Health' },
  { value: '14', label: 'MSK' },
  { value: '15', label: 'Brain' },
  { value: '16', label: 'Thoracic' },
  { value: '17', label: 'Pediatric' },
]

export const TypeExercise = [
  { value: '1', label: 'Liberale' },
  { value: '2', label: 'PU' },
  { value: '3', label: 'PH' },
  { value: '4', label: 'Mixte' },
  { value: '5', label: 'Interne' },
  { value: '6', label: 'FFI' },
]

// *********** CHARTS ************

export const chartActivitySelect = [
  { value: '1', label: 'Number of Studies' },
  { value: '2', label: 'Top list of Refering physician' },
  { value: '3', label: 'Top list of Portal user' },
  { value: '4', label: 'Studies per modality' },
  { value: '5', label: '% of Studies booked Online' },
  { value: '6', label: '% of Studies with Admission' },
  { value: '7', label: 'Volume of Data Sent by customer' },
]

export const chartSecuritySelect = [
  { value: '1', label: 'Accounts unlocked' },
  { value: '2', label: 'Accounts login from different IP' },
  { value: '3', label: 'Prescritpion and Studies linked with deprecated RPPS' },
  {
    value: '4',
    label: 'Prescription and Studies linked with Doctors* without RPPS',
  },
]

export const chartPerformanceSelect = [
  { value: '1', label: 'Report Validattion Time(*acquisition)' },
  { value: '2', label: 'Transfer Local Time ' },
  { value: '3', label: 'Transfer Cloud Time' },
  { value: '4', label: 'Transfer Time' },
  { value: '5', label: 'Report Validation Time (*reception)' },
  { value: '6', label: 'Protocol Generation' },
  { value: '7', label: 'Rejected Studies (because of low quality)' },
  { value: '8', label: 'Number of Studies' },
]

export const chartSelectTop_10_100 = [
  { value: '1', label: '10' },
  { value: '2', label: '50' },
  { value: '3', label: '100' },
]

export const chartSelectTop_20_100 = [
  { value: '1', label: '20' },
  { value: '2', label: '50' },
  { value: '3', label: '100' },
]

export const chartSelectTelemedecine = [
  { value: '1', label: 'Xray' },
  { value: '2', label: 'CT' },
  { value: '3', label: 'MR' },
  { value: '3', label: 'CT + MR' },
]

// ********************** EDAM *****************
// --- MACRO

export const macroWhenSelect = [
  { value: '1', label: 'An exam is uploaded...' },
  { value: '2', label: 'An exam is received...' },
  { value: '3', label: 'An exam is shared in to your organisation' },
  { value: '4', label: 'A validated report is received' },
  { value: '5', label: 'A new procedure was created for a patient' },
]

export const macroActionSelect = [
  { value: '1', label: 'From sources' },
  { value: '2', label: 'Modality' },
  { value: '3', label: 'Institution' },
  { value: '4', label: 'Referring physician' },
  { value: '5', label: 'Patient' },
  { value: '6', label: 'Study Description' },
  { value: '7', label: 'HL7 Segment' },
  { value: '8', label: 'Day' },
  { value: '9', label: 'Time Range' },
  { value: '10', label: 'Age' },
  { value: '11', label: 'Gender' },
]

export const macroConditionSelect = [
  { value: '1', label: 'Contains' },
  { value: '2', label: 'Does not contain' },
  { value: '3', label: 'Is' },
  { value: '4', label: 'Is not' },
  { value: '5', label: 'Begins with' },
  { value: '6', label: 'Ends with' },
  { value: '7', label: '<=' },
  { value: '8', label: '>=' },
  { value: '9', label: '>' },
  { value: '10', label: '<' },
]

export const macroModalitySelect = [
  { value: '1', label: 'Computed Radiography (CR)' },
  { value: '2', label: 'Computed Temography (CT)' },
  { value: '3', label: 'Magnetic Resonance (MR)' },
  { value: '4', label: 'Nuclear Medicine (NM)' },
  { value: '5', label: 'Ultrasound (US)' },
]

export const macroDaySelect = [
  { value: '1', label: 'Sunday' },
  { value: '2', label: 'Monday' },
  { value: '3', label: 'Tuesday' },
  { value: '4', label: 'Wednesday' },
  { value: '5', label: 'Thursday' },
  { value: '6', label: 'Friday' },
  { value: '7', label: 'Saturday' },
]

// ----------------- Perform Following ------------------

export const macroPerformSelect = [
  { value: '1', label: 'Send email to' },
  { value: '2', label: 'Sent text messsage (SMS) to' },
  { value: '3', label: 'Push to PACS' },
  { value: '4', label: 'Share exam with professional' },
  { value: '5', label: 'Share exam with organisation' },
  { value: '6', label: 'Pre-fetch Prior exams' },
]

export const macroPerformPushToPacsSelect = [
  { value: '1', label: 'Add MRN Prefix' },
  { value: '2', label: 'Add Accession #Prefix' },
  { value: '3', label: 'Replace Institution' },
]

export const macroPerformSharePermission = [
  { value: '1', label: 'View Only' },
  { value: '2', label: 'View and Report' },
  { value: '3', label: 'Export' },
]

export const macroPerformShareExp = [
  { value: '1', label: 'No Expiration' },
  { value: '2', label: '1 week' },
  { value: '3', label: '2 weeks' },
]

export const macroPerformPreFetch = [
  { value: '1', label: 'Prefectch All Studies for Patient' },
  { value: '2', label: 'Prefetch only studies performed in the Last' },
]

export const genre = [
  { value: '1', label: 'MALE' },
  { value: '2', label: 'FEMALE' },
  { value: '3', label: 'TRANSGENDERMALETOFEMALE' },
  { value: '4', label: 'TRANSGENDERFEMALETOMALE' },
  { value: '5', label: 'OTHER' },
  { value: '6', label: 'UNREPORTED' },
]


// ***************** MED NUC *****************

export const wasteType = [
  { value: 'G', label: 'Gaz' },
  { value: 'L', label: 'Liquid' },
  { value: 'C', label: 'Solid' },
]
export const importanceLevel = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
  { value: 'mandatory', label: 'Mandatory' },
]

// ***************** EQUIPMENTS *****************
// ***************** IT Equipment List *****************

export const itEquipmentType = [
  { value: '', label: 'All' },
  { value: 'server', label: 'Server' },
  { value: 'vpn', label: 'VPN' },
  { value: 'router', label: 'Router' },
  { value: 'switch', label: 'Switch' },
  { value: 'other', label: 'Other' },
]
// ***************** Medical Equipment List *****************

export const medicalEquipmentType = [
  { value: '', label: 'All' },
  { value: 'US', label: 'Echographe' },
  { value: 'CT', label: 'Scanner' },
  { value: 'MR', label: 'IRM' },
  { value: 'RCT', label: 'Remote controlled table' },
  { value: 'BDUS', label: 'Osteodentisometre (ultrasound)' },
  { value: 'BMD', label: 'Osteodentisometre (X-Ray)' },
  { value: 'MG', label: 'Mammographe' },
  { value: 'EOS', label: 'EOS' },
  { value: 'PT', label: 'PET Scan' },
  { value: 'NM', label: 'Gamma Camera' },
  { value: 'LINAC', label: 'Linac' },
  { value: 'CP', label: 'Capteur plan' },
  { value: 'OT', label: 'Other' },
]

// ***************** Manufacturer List *****************
export const equipmentBrand = [
  { value: '', label: 'All' },
  { value: 'canon', label: 'Canon' },
  { value: 'cyberknife', label: 'CyberKnife' },
  { value: 'dms-appelem', label: 'DMS / Appelem' },
  { value: 'dms', label: 'DMS' },
  { value: 'elekta', label: 'Elekta' },
  { value: 'eos', label: 'EOS' },
  { value: 'fona', label: 'Fona' },
  { value: 'fuji', label: 'FujiFilm' },
  { value: 'giotto', label: 'Giotto' },
  { value: 'ge', label: 'General Electric' },
  { value: 'gmm', label: 'GMM' },
  { value: 'hitachi-fuji', label: 'Hitachi / FujiFilm' },
  { value: 'hologic', label: 'Hologic' },
  { value: 'mindray', label: 'Mindray' },
  { value: 'neuesoft', label: 'NeueSoft' },
  { value: 'philips', label: 'Philips' },
  { value: 'samsung-emdison', label: 'Samsung / Medison' },
  { value: 'sectra', label: 'Sectra' },
  { value: 'siemens', label: 'Siemens' },
  { value: 'sirona', label: 'Sirona' },
  { value: 'spectrum-dynamic', label: 'Spectrum Dynamic' },
  { value: 'stephanix', label: 'Stephanix' },
  { value: 'tomotherapy', label: 'Tomotherapy' },
  { value: 'carestream', label: 'Carestream' },
  { value: 'newtom', label: 'NewTom' },
  { value: 'varian', label: 'Varian' },
]

// ***************** Equipment Model list *****************
export const equipmentModelDefault = [
  { value: '1', label: 'Model 1' },
  { value: '2', label: 'Model 2' },
  { value: '3', label: 'Model 3' },
  { value: '4', label: 'Model 4' },
]

// ***************** Scanners *****************
export const EOSModel = [
  { value: '1', label: 'EOS Edge' },
  { value: '2', label: 'EOS System' },
]

export const scannerModelGE = [
  { value: '1', label: 'Revolution Apex' },
  { value: '2', label: 'Revolution Frontier' },
  { value: '3', label: 'Revolution ACT' },
  { value: '4', label: 'Revolution HD' },
  { value: '5', label: 'Optima' },
  { value: '6', label: 'Discovery RT' },
  { value: '7', label: 'Other' },
]

export const scannerModelSiemens = [
  { value: '1', label: 'Somatom Force' },
  { value: '2', label: 'Somatom Drive' },
  { value: '3', label: 'Somatom X-Cite' },
  { value: '4', label: 'Somatom Edge Plus' },
  { value: '5', label: 'Somatom Definition' },
  { value: '6', label: 'Other' },
]

export const scannerModelCanon = [
  { value: '1', label: 'Aquilion ONE' },
  { value: '2', label: 'Aquilion Prime' },
  { value: '3', label: 'Aquilion Lightning' },
  { value: '4', label: 'Aquilion Exceed' },
  { value: '5', label: 'Aquilion LB' },
  { value: '6', label: 'Other' },
]

export const scannerModelPhilips = [
  { value: '1', label: 'IQon' },
  { value: '2', label: 'Incisive CT' },
  { value: '3', label: 'Ingenuity' },
  { value: '4', label: 'MX16evo' },
  { value: '5', label: 'Other' },
]

export const IRMModelGE = [
  { value: '1', label: 'Signa Explorer' },
  { value: '2', label: 'Signa Voyager' },
  { value: '3', label: 'Signa Architect Air 3T' },
  { value: '4', label: 'Signa Premier Air 3T' },
  { value: '5', label: 'Signa Pioneer 3T' },
  { value: '6', label: 'Signa Discovery 3T' },
  { value: '7', label: 'Other' },
]

// ***************** IRM *****************
export const IRMModelSiemens = [
  { value: '1', label: 'Magnetom Sola' },
  { value: '2', label: 'Magnetom Altea' },
  { value: '3', label: 'Magnetom Sempra' },
  { value: '4', label: 'Magnetom Vida 3T' },
  { value: '5', label: 'Magnetom Lumina 3T' },
  { value: '6', label: 'Prisma 3T' },
  { value: '7', label: 'Other' },
]

export const IRMModelCanon = [
  { value: '1', label: 'Vantage Galan 3T' },
  { value: '2', label: 'Vantage Orian' },
  { value: '3', label: 'Vantage Elan' },
  { value: '4', label: 'Vantage Elan Osteo' },
  { value: '5', label: 'Other' },
]

export const IRMModelPhilips = [
  { value: '1', label: 'Ingenia Edition' },
  { value: '2', label: 'Ingenia Ambition' },
  { value: '3', label: 'Other' },
]

// ***************** Mammoograph *****************
export const mamoModelGE = [
  { value: '1', label: 'Pristina' },
  { value: '2', label: 'Serena' },
  { value: '3', label: 'Crystal Nova' },
  { value: '4', label: 'Other' },
]

export const mamoModelSiemens = [
  { value: '1', label: 'Mammomat Revelation' },
  { value: '2', label: 'Mammomat Inspiration' },
  { value: '3', label: 'Mammomat Fusion' },
  { value: '4', label: 'Other' },
]

export const mamoModelFuji = [
  { value: '1', label: 'Amulet' },
  { value: '2', label: 'Innovality' },
  { value: '3', label: 'Other' },
]

export const mamoModelHologic = [
  { value: '1', label: '3Dimension' },
  { value: '2', label: 'Selenia' },
  { value: '3', label: 'Other' },
]

export const mamoModelGiotto = [
  { value: '1', label: '3000' },
  { value: '2', label: '4000' },
  { value: '3', label: 'Other' },
]

// ***************** Echograph *****************
export const echoModelSamsung = [
  { value: '1', label: 'RS85A Prestige' },
  { value: '2', label: 'HS70A' },
  { value: '3', label: 'HM70A' },
  { value: '4', label: 'Other' },
]

export const echoModelToshiba = [
  { value: '1', label: 'Applio i' },
  { value: '2', label: 'Applio a' },
  { value: '3', label: 'Applio 400' },
  { value: '4', label: 'Applio 500' },
  { value: '5', label: 'XarioViamo' },
  { value: '6', label: 'Other' },
]

export const echoModelHitachi = [
  { value: '1', label: 'Arietta' },
  { value: '2', label: 'Lisendo' },
  { value: '3', label: 'Other' },
]

export const echoModelSiemens = [
  { value: '1', label: 'Acuson Sequoia' },
  { value: '2', label: 'Acuson Juniper' },
  { value: '3', label: 'Acuson Redwood' },
  { value: '4', label: 'Acuson NX2' },
  { value: '5', label: 'Acuson NX3' },
  { value: '6', label: 'Other' },
]

export const echoModelPhilips = [
  { value: '1', label: 'Eqip Elite' },
  { value: '2', label: 'Eqip CVX' },
  { value: '3', label: 'Lumify' },
  { value: '4', label: 'Affinity 30/50/70' },
  { value: '5', label: 'CX50' },
  { value: '6', label: 'InnoSight' },
  { value: '7', label: 'ClearVue850' },
  { value: '8', label: 'Other' },
]

export const echoModelSuperSonic = [
  { value: '1', label: 'Mach 20' },
  { value: '2', label: 'Mach30' },
  { value: '3', label: 'AirExplorer' },
  { value: '4', label: 'AirExplorer Ultimate' },
  { value: '5', label: 'Other' },
]

// ***************** Equipment Specs list *****************
export const scannerBarrettes = [
  { value: '1', label: '4' },
  { value: '2', label: '8' },
  { value: '3', label: '16' },
  { value: '4', label: '32' },
  { value: '5', label: '40' },
  { value: '6', label: '64' },
  { value: '7', label: '128' },
  { value: '8', label: '192' },
  { value: '9', label: '192+' },
]

export const magnetPower = [
  { value: '1', label: '0.5T' },
  { value: '2', label: '1T' },
  { value: '3', label: '1.5T' },
  { value: '4', label: '3T' },
  { value: '5', label: '7T' },
  { value: '6', label: '7T+' },
]

export const linacTps = [
  { value: '1', label: 'Eclipse' },
  { value: '2', label: 'Isogray' },
  { value: '3', label: 'Monaco' },
  { value: '4', label: 'XIO' },
  { value: '5', label: 'Pinnacle' },
  { value: '6', label: 'BrainLab' },
]

export const oncologyInformationSystem = [
  { value: '1', label: 'Aria' },
  { value: '2', label: 'Mosaiq' },
]

export const consoleConstructor = [
  { value: '1', label: 'Server' },
  { value: '2', label: 'Standalone' },
]

export const defaultList = [
  { value: '1', label: 'Choix 1' },
  { value: '2', label: 'Choix 2' },
  { value: '3', label: 'Choix 3' },
  { value: '4', label: 'Choix 4' },
]

export const locationOptions = [
  { value: 'assigned', label: 'Assigned to a room' },
  { value: 'not-assigned', label: 'Not assigned to a room' },
  { value: 'mobile', label: 'Mobile' },
]
export const dataGroupingTest = [
  {
    title: 'Website Re-Design Plan',
    roomId: 2,
    startDate: new Date(2018, 3, 30, 9, 30),
    endDate: new Date(2018, 4, 3, 11, 30),
    id: 0,
    location: 'Scan 1',
  },
  {
    title: 'Book Flights to San Fran for Sales Trip',
    roomId: 1,
    startDate: new Date(2018, 4, 10, 10, 0),
    endDate: new Date(2018, 4, 10, 12, 0),
    id: 1,
    location: 'Scan 1',
  },
  {
    title: 'Install New Router in Dev Room',
    roomId: 3,
    startDate: new Date(2018, 4, 7, 13),
    endDate: new Date(2018, 4, 7, 15, 30),
    id: 2,
    location: 'Scan 1',
  },
  {
    title: 'New Brochures',
    roomId: 2,
    startDate: new Date(2018, 4, 7, 13, 0),
    endDate: new Date(2018, 4, 7, 15, 15),
    id: 5,
  },
  {
    title: 'Install New Database',
    roomId: 1,
    startDate: new Date(2018, 4, 8, 9),
    endDate: new Date(2018, 4, 8, 12, 15),
    id: 6,
    location: 'Scan 1',
  },
  {
    title: 'Approve New Online Marketing Strategy',
    roomId: 3,
    startDate: new Date(2018, 4, 9, 12, 0),
    endDate: new Date(2018, 4, 9, 14, 0),
    id: 7,
    location: 'Scan 1',
  },
  {
    title: 'Upgrade Personal Computers',
    roomId: 1,
    startDate: new Date(2018, 4, 7, 9),
    endDate: new Date(2018, 4, 7, 11, 30),
    id: 8,
    location: 'Scan 1',
  },
  {
    title: 'Prepare 2018 Marketing Plan',
    roomId: 2,
    startDate: new Date(2018, 4, 10, 11, 0),
    endDate: new Date(2018, 4, 10, 13, 30),
    id: 9,
  },
  {
    title: 'Brochure Design Review',
    roomId: 3,
    startDate: new Date(2018, 4, 9, 11, 0),
    endDate: new Date(2018, 4, 9, 13, 30),
    id: 10,
  },
  {
    title: 'Upgrade Server Hardware',
    roomId: 1,
    startDate: new Date(2018, 4, 11, 9, 0),
    endDate: new Date(2018, 4, 11, 15, 0),
    id: 11,
  },
  {
    title: 'Submit New Website Design',
    roomId: 2,
    startDate: new Date(2018, 4, 11, 16, 30),
    endDate: new Date(2018, 4, 11, 18, 0),
    id: 12,
  },
  {
    title: 'Launch New Website',
    roomId: 3,
    startDate: new Date(2018, 4, 11, 12, 20),
    endDate: new Date(2018, 4, 11, 14, 0),
    id: 13,
  },
  {
    title: 'Google AdWords Strategy',
    roomId: 1,
    startDate: new Date(2018, 4, 14, 9, 0, 0),
    endDate: new Date(2018, 4, 14, 12, 0, 0),
    id: 14,
  },
  {
    title: 'Rollout of New Website and Marketing Brochures',
    roomId: 1,
    startDate: new Date(2018, 4, 14, 13, 0, 0),
    endDate: new Date(2018, 4, 14, 15, 30, 0),
    id: 15,
  },
  {
    title: 'Non-Compete Agreements',
    roomId: 3,
    startDate: new Date(2018, 4, 15, 13, 0, 0),
    endDate: new Date(2018, 4, 15, 15, 45, 0),
    id: 16,
  },
  {
    title: 'Approve Hiring of John Jeffers',
    roomId: 2,
    startDate: new Date(2018, 4, 15, 9, 0, 0),
    endDate: new Date(2018, 4, 15, 12, 0, 0),
    id: 17,
  },
  {
    title: 'Update NDA Agreement',
    roomId: 1,
    startDate: new Date(2018, 4, 15, 11, 0, 0),
    endDate: new Date(2018, 4, 15, 14, 15, 0),
    id: 18,
  },
  {
    title: 'Submit Signed NDA',
    roomId: 3,
    startDate: new Date(2018, 4, 16, 13, 0, 0),
    endDate: new Date(2018, 4, 16, 15, 0, 0),
    id: 21,
  },
  {
    title: 'Review Revenue Projections',
    roomId: 2,
    startDate: new Date(2018, 4, 16, 11, 0, 0),
    endDate: new Date(2018, 4, 16, 14, 0, 0),
    id: 22,
  },
  {
    title: 'Comment on Revenue Projections',
    roomId: 2,
    startDate: new Date(2018, 4, 14, 10, 0, 0),
    endDate: new Date(2018, 4, 14, 13, 0, 0),
    id: 23,
  },
  {
    title: 'Provide New Health Insurance Docs',
    roomId: 3,
    startDate: new Date(2018, 4, 18, 12, 0, 0),
    endDate: new Date(2018, 4, 18, 15, 0, 0),
    id: 24,
  },
  {
    title: 'Review Changes to Health Insurance Coverage',
    roomId: 2,
    startDate: new Date(2018, 4, 17, 9, 0, 0),
    endDate: new Date(2018, 4, 17, 13, 0, 0),
    id: 25,
  },
  {
    title: 'Review Training Course for any Ommissions',
    roomId: 1,
    startDate: new Date(2018, 4, 17, 11, 0, 0),
    endDate: new Date(2018, 4, 17, 14, 0, 0),
    id: 26,
  },
  {
    title: 'Website Re-Design Plan',
    roomId: 3,
    startDate: new Date(2018, 4, 21, 9, 30),
    endDate: new Date(2018, 4, 21, 11, 30),
    id: 27,
  },
  {
    title: 'Book Flights to San Fran for Sales Trip',
    roomId: 1,
    startDate: new Date(2018, 4, 24, 10, 0),
    endDate: new Date(2018, 4, 24, 12, 0),
    id: 28,
  },
  {
    title: 'Install New Router in Dev Room',
    roomId: 1,
    startDate: new Date(2018, 4, 21, 13),
    endDate: new Date(2018, 4, 21, 15, 30),
    id: 29,
  },
  {
    title: 'Approve Personal Computer Upgrade Plan',
    roomId: 3,
    startDate: new Date(2018, 4, 22, 10, 0),
    endDate: new Date(2018, 4, 22, 11, 0),
    id: 30,
  },
  {
    title: 'Final Budget Review',
    roomId: 2,
    startDate: new Date(2018, 4, 22, 12, 0),
    endDate: new Date(2018, 4, 22, 13, 35),
    id: 31,
  },
  {
    title: 'New Brochures',
    roomId: 2,
    startDate: new Date(2018, 4, 21, 13, 0),
    endDate: new Date(2018, 4, 21, 15, 15),
    id: 32,
  },
  {
    title: 'Install New Database',
    roomId: 3,
    startDate: new Date(2018, 4, 22, 9),
    endDate: new Date(2018, 4, 22, 12, 15),
    id: 33,
  },
  {
    title: 'Approve New Online Marketing Strategy',
    roomId: 2,
    startDate: new Date(2018, 4, 23, 12, 0),
    endDate: new Date(2018, 4, 23, 14, 0),
    id: 34,
  },
  {
    title: 'Upgrade Personal Computers',
    roomId: 1,
    startDate: new Date(2018, 4, 21, 9),
    endDate: new Date(2018, 4, 21, 11, 30),
    id: 35,
  },
  {
    title: 'Prepare 2018 Marketing Plan',
    roomId: 3,
    startDate: new Date(2018, 4, 24, 11, 0),
    endDate: new Date(2018, 4, 24, 13, 30),
    id: 36,
  },
  {
    title: 'Brochure Design Review',
    roomId: 1,
    startDate: new Date(2018, 4, 23, 11, 0),
    endDate: new Date(2018, 4, 23, 13, 30),
    id: 37,
  },
  {
    title: 'Create Icons for Website',
    roomId: 2,
    startDate: new Date(2018, 4, 25, 10, 0),
    endDate: new Date(2018, 4, 25, 11, 30),
    id: 38,
  },
  {
    title: 'Upgrade Server Hardware',
    roomId: 1,
    startDate: new Date(2018, 4, 25, 9, 0),
    endDate: new Date(2018, 4, 25, 15, 0),
    id: 39,
  },
  {
    title: 'Submit New Website Design',
    roomId: 3,
    startDate: new Date(2018, 4, 25, 16, 30),
    endDate: new Date(2018, 4, 25, 18, 0),
    id: 40,
  },
  {
    title: 'Launch New Website',
    roomId: 2,
    startDate: new Date(2018, 4, 25, 12, 20),
    endDate: new Date(2018, 4, 25, 14, 0),
    id: 41,
  },
  {
    title: 'Google AdWords Strategy',
    roomId: 1,
    startDate: new Date(2018, 4, 28, 9, 0, 0),
    endDate: new Date(2018, 4, 28, 12, 0, 0),
    id: 42,
    taken: 'close',
  },
  {
    title: 'Rollout of New Website and Marketing Brochures',
    roomId: 3,
    startDate: new Date(2018, 4, 28, 13, 0, 0),
    endDate: new Date(2018, 4, 28, 15, 30, 0),
    id: 43,
  },
  {
    title: 'Non-Compete Agreements',
    roomId: 2,
    startDate: new Date(2018, 4, 29, 13, 0, 0),
    endDate: new Date(2018, 4, 29, 15, 45, 0),
    id: 44,
  },
  {
    title: 'Approve Hiring of John Jeffers',
    roomId: 2,
    startDate: new Date(2018, 4, 29, 9, 0, 0),
    endDate: new Date(2018, 4, 29, 12, 0, 0),
    id: 45,
  },
  {
    title: 'Update NDA Agreement',
    roomId: 3,
    startDate: new Date(2018, 4, 29, 11, 0, 0),
    endDate: new Date(2018, 4, 29, 14, 15, 0),
    id: 46,
  },
  {
    title: 'Update Employee Files with New NDA',
    roomId: 1,
    startDate: new Date(2018, 5, 1, 9, 0, 0),
    endDate: new Date(2018, 5, 1, 11, 45, 0),
    id: 47,
  },
  {
    title: 'Submit Questions Regarding New NDA',
    roomId: 1,
    startDate: new Date(2018, 4, 30, 10, 0, 0),
    endDate: new Date(2018, 4, 30, 11, 30, 0),
    id: 48,
    taken: 'pause',
  },
  {
    title: 'Submit Signed NDA',
    roomId: 3,
    startDate: new Date(2018, 4, 30, 13, 0, 0),
    endDate: new Date(2018, 4, 30, 15, 0, 0),
    id: 49,
  },
  {
    title: 'Review Revenue Projections',
    roomId: 2,
    startDate: new Date(2018, 4, 30, 11, 0, 0),
    endDate: new Date(2018, 4, 30, 14, 0, 0),
    id: 50,
  },
  {
    title: 'Comment on Revenue Projections',
    roomId: 2,
    startDate: new Date(2018, 4, 28, 10, 0, 0),
    endDate: new Date(2018, 4, 28, 13, 0, 0),
    id: 51,
  },
  {
    title: 'Provide New Health Insurance Docs',
    roomId: 3,
    startDate: new Date(2018, 5, 1, 12, 0, 0),
    endDate: new Date(2018, 5, 1, 15, 0, 0),
    id: 52,
  },
  {
    title: 'Review Changes to Health Insurance Coverage',
    roomId: 2,
    startDate: new Date(2018, 4, 31, 9, 0, 0),
    endDate: new Date(2018, 4, 31, 13, 0, 0),
    id: 53,
  },
  {
    title: 'Review Training Course for any Ommissions',
    roomId: 1,
    startDate: new Date(2018, 4, 31, 11, 0, 0),
    endDate: new Date(2018, 4, 31, 14, 0, 0),
    id: 54,
  },
  {
    title: 'Approve New Online Marketing Strategy',
    roomId: 3,
    startDate: new Date(2018, 4, 28, 12, 0),
    endDate: new Date(2018, 4, 28, 14, 0),
    allDay: true,
    id: 55,
  },
  {
    title: 'Install New Router in Dev Room',
    roomId: 1,
    startDate: new Date(2018, 4, 29, 13),
    endDate: new Date(2018, 4, 29, 15, 30),
    allDay: true,
    id: 56,
  },
  {
    title: 'Google AdWords Strategy',
    roomId: 1,
    startDate: new Date(2018, 4, 31, 9, 0, 0),
    endDate: new Date(2018, 4, 31, 12, 0, 0),
    allDay: true,
    id: 57,
  },
  {
    title: 'Review Changes to Health Insurance Coverage',
    roomId: 2,
    startDate: new Date(2018, 5, 1, 9, 0, 0),
    endDate: new Date(2018, 5, 1, 13, 0, 0),
    allDay: true,
    id: 58,
  },
]

export const priorityData = [
  {
    text: 'Low Priority',
    id: 1,
  },
  {
    text: 'High Priority',
    id: 2,
  },
]

export const defaultSelectList = [
  { value: 1, label: 'Option 1' },
  { value: 2, label: 'Option 2' },
  { value: 3, label: 'Option 3' },
  { value: 4, label: 'Option 4' },
  { value: 5, label: 'Option 5' },
]

export const colors = [
  { color: 'blue', value: blue[900] },
  { color: 'blueGrey', value: blueGrey[900] },
  { color: 'brown', value: brown[900] },
  { color: 'cyan', value: cyan[900] },
  { color: 'deepOrange', value: deepOrange[900] },
  { color: 'deepPurple', value: deepPurple[900] },
  { color: 'green', value: green[900] },
  { color: 'grey', value: grey[900] },
  { color: 'indigo', value: indigo[900] },
  { color: 'lightBlue', value: lightBlue[900] },
  { color: 'lightGreen', value: lightGreen[900] },
  { color: 'lime', value: lime[900] },
  { color: 'orange', value: orange[900] },
  { color: 'pink', value: pink[900] },
  { color: 'purple', value: purple[900] },
  { color: 'red', value: red[900] },
  { color: 'teal', value: teal[900] },
  { color: 'yellow', value: yellow[900] },
]
