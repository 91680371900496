/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class MirthService {
  /**
   *
   */
  static createOrder(
    params: {
      /**  */
      externalId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ORM';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { externalId: params['externalId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createObservation(
    params: {
      /**  */
      externalId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ORU';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { externalId: params['externalId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static receiveInstance(
    params: {
      /**  */
      externalId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/OMG';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { externalId: params['externalId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mergePatient(
    params: {
      /**  */
      externalId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ADT/A40';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { externalId: params['externalId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerPatient(
    params: {
      /**  */
      externalId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ADT/A04';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { externalId: params['externalId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class GedService {
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/download';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadFile(
    params: {
      /**  */
      id: number;
      /**  */
      type: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GedFileDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/upload';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { type: params['type'] };
      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DoctorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDoctorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateDoctorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Doctor> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static getPhoneVerificationCheck(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendPhoneVerification(
    params: {
      /** requestBody */
      body?: CreatePhoneAuthMethod;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static phoneVerify(
    params: {
      /** requestBody */
      body?: PhoneVerifyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/verify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findMyAccount(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LogService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
      /**  */
      startDate: string;
      /**  */
      endDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/log';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
        startDate: params['startDate'],
        endDate: params['endDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   *
   */
  static getCaptcha(options: IRequestOptions = {}): Promise<CapchaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/logout';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isValidFirstConnection(
    params: {
      /**  */
      accessionNumber: string;
      /**  */
      birthDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accessionNumber: params['accessionNumber'], birthDate: params['birthDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createUserFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterPatientDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createLocalUserFromEmail(
    params: {
      /** requestBody */
      body?: LocalUserRegisterFromMailDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/verify-mail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgottenPassword(
    params: {
      /**  */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/forgotten-password';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: LocalUserResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/reset-password';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: LocalUserUpdatePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/update-password';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEmail(
    params: {
      /** requestBody */
      body?: LocalUserForgetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/update-email';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ApiKeyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateApiKeyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lock(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}/lock';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unLockApiKey(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}/unlock';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SiteService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSiteDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateSiteDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UpdateSiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ClientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClientDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateClientDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FinessService {
  /**
   *
   */
  static createAll(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static autocomplete(
    params: {
      /**  */
      finessNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinessDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess/autocomplete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { finessNumber: params['finessNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      finessNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinessDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess/{finessNumber}';
      url = url.replace('{finessNumber}', params['finessNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PatientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PatientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdatePatientDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PatientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateReferringDoctor(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdatePatientReferringDoctorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}/referring-doctor';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudyService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findMyStudies(options: IRequestOptions = {}): Promise<Study[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/my-studies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static download(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/download';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static shareStudy(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateSharedStudyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SharedStudyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/share';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unshareStudy(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/unshare';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAdvice(
    params: {
      /**  */
      id: number;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Study> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/advice';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteAdvice(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Study> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/advice';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WorklistService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<WorklistDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/worklist';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateAdminDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateAdminDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SuperAdminService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSuperAdminDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SuperAdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SuperAdmin> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateSuperAdminDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RppsService {
  /**
   *
   */
  static createAll(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[];
      /**  */
      limit: number;
      /**  */
      skip: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filters: params['filters'], limit: params['limit'], skip: params['skip'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static autocomplete(
    params: {
      /**  */
      rppsNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RppsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps/autocomplete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { rppsNumber: params['rppsNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      rppsNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RppsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps/{rppsNumber}';
      url = url.replace('{rppsNumber}', params['rppsNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ViewerService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ViewerDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateViewerDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateViewerDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Viewer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface GedFileDto {
  /**  */
  id: number;

  /**  */
  type: string;

  /**  */
  relatedEntity: number;

  /**  */
  filename: string;

  /**  */
  mimeType: string;
}

export interface MedicalEquipment {
  /**  */
  id: number;

  /**  */
  room: string;

  /**  */
  location: string;

  /**  */
  type: EnumMedicalEquipmentType;

  /**  */
  model?: string;

  /**  */
  manufacturer: string;

  /**  */
  commissioningDate: Date;

  /**  */
  dose: boolean;

  /**  */
  shared: boolean;

  /**  */
  active: boolean;

  /**  */
  site: Site;

  /**  */
  siteId: number;
}

export interface ITEquipment {
  /**  */
  id: number;

  /**  */
  type: string;

  /**  */
  ip: string;

  /**  */
  mac: string;

  /**  */
  port: number;

  /**  */
  infos: string;

  /**  */
  site: Site;
}

export interface PrepRoom {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  status: EnumPrepRoomStatus;

  /**  */
  isActive: boolean;

  /**  */
  examRoom: ExamRoom;
}

export interface ExamRoom {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  openingTime: Date;

  /**  */
  recoveryTime: Date;

  /**  */
  breakTime: Date;

  /**  */
  closingTime: Date;

  /**  */
  shared: boolean;

  /**  */
  xrayCompatible: boolean;

  /**  */
  mutualized: boolean;

  /**  */
  emergency: boolean;

  /**  */
  color: string;

  /**  */
  site: Site;

  /**  */
  prepRooms: PrepRoom[];

  /**  */
  active: boolean;
}

export interface Site {
  /**  */
  primary: boolean;

  /**  */
  client: CombinedClientTypes;

  /**  */
  externalId: string;

  /**  */
  name: string;

  /**  */
  streetAddress: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  state: string;

  /**  */
  country: string;

  /**  */
  id: number;

  /**  */
  clientId: number;

  /**  */
  finessNumber: string;

  /**  */
  finessStructNumber: string;

  /**  */
  siteStatus: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  fax: string;

  /**  */
  geocode: string;

  /**  */
  medicalEquipments: MedicalEquipment[];

  /**  */
  itEquipments: ITEquipment[];

  /**  */
  examRooms: ExamRoom[];

  /**  */
  capacity: number;
}

export interface LocalAuthMethod {
  /**  */
  email: string;

  /**  */
  password: string;

  /**  */
  verificationUUID?: string;

  /**  */
  resetPasswordUUID?: string;

  /**  */
  endLockout?: Date;

  /**  */
  failedAttempt?: number;
}

export interface CpsAuthMethod {
  /**  */
  email: string;

  /**  */
  password: string;
}

export interface PhoneAuthMethod {
  /**  */
  phoneNumber: string;

  /**  */
  phoneVerificationCode?: string;

  /**  */
  isVerified: boolean;
}

export interface AuthMethod {
  /**  */
  local: LocalAuthMethod;

  /**  */
  cps?: CpsAuthMethod;

  /**  */
  phone?: PhoneAuthMethod;
}

export interface User {
  /**  */
  role: Role;

  /**  */
  id: number;

  /**  */
  authMethod: AuthMethod;
}

export interface Admin {
  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  client: CombinedClientTypes;

  /**  */
  role: AdminRole;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  clientId: number;
}

export interface SecurityPreferences {
  /**  */
  failureNumber: number;

  /**  */
  lockoutPeriod: number;

  /**  */
  inactivityTimeout: number;

  /**  */
  twoFAMandatory: boolean;

  /**  */
  twoFAMethod: string;

  /**  */
  passwordChangesMandatory: boolean;

  /**  */
  passwordChangesPeriod: number;

  /**  */
  samePasswordAllowed: boolean;
}

export interface Viewer {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  brand: string;
}

export interface ViewerPreferences {
  /**  */
  patientViewer: Viewer;

  /**  */
  doctorViewer: Viewer;

  /**  */
  radioViewer: Viewer;

  /**  */
  tomoViewer: Viewer;

  /**  */
  nuclearViewer: Viewer;
}

export interface SharingPreferences {
  /**  */
  maxPermission: string;

  /**  */
  permission: string;

  /**  */
  ownershipAccept: boolean;

  /**  */
  expiration: number;
}

export interface ClientPreferences {
  /**  */
  security: SecurityPreferences;

  /**  */
  viewers: ViewerPreferences;

  /**  */
  sharing: SharingPreferences;
}

export interface Client {
  /**  */
  name: string;

  /**  */
  status: string;

  /**  */
  type: ClientType;

  /**  */
  siretNumber: string;

  /**  */
  sirenNumber: string;

  /**  */
  streetAddress: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  country: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  id: number;

  /**  */
  finessNumber: string;

  /**  */
  state: string;

  /**  */
  fax: string;

  /**  */
  externalId: string;

  /**  */
  logo: string;

  /**  */
  sites: Site[];

  /**  */
  admins: Admin[];

  /**  */
  preferences: ClientPreferences;
}

export interface StudyType {
  /**  */
  label: string;

  /**  */
  code: string;
}

export interface Report {
  /**  */
  type: ReportType;

  /**  */
  status: ReportStatus;

  /**  */
  id: number;

  /**  */
  ressource: number;

  /**  */
  study: Study;
}

export interface Order {
  /**  */
  status: OrderStatus;

  /**  */
  id: number;

  /**  */
  ressource: number;
}

export interface Study {
  /**  */
  id: number;

  /**  */
  externalId: string;

  /**  */
  instanceUID: string;

  /**  */
  attendingDoctor: Admin;

  /**  */
  referringDoctor: Doctor;

  /**  */
  status: EnumStudyStatus;

  /**  */
  plannedDate: Date;

  /**  */
  editedDate: Date;

  /**  */
  cancelDate: Date;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  type: StudyType;

  /**  */
  patient: Patient;

  /**  */
  visit: string;

  /**  */
  site: Client;

  /**  */
  accessionNumber: string;

  /**  */
  service: string;

  /**  */
  room?: string;

  /**  */
  modality: string;

  /**  */
  bodyPart: string;

  /**  */
  reports: Report[];

  /**  */
  reportReceivedAt: Date;

  /**  */
  order: Order;

  /**  */
  hasImage: boolean;

  /**  */
  imageReceivedCloudAt: Date;

  /**  */
  imageReceivedLocalAt: Date;

  /**  */
  acquisitionDate: Date;

  /**  */
  SR: boolean;

  /**  */
  GSPS: boolean;

  /**  */
  SC: boolean;

  /**  */
  shares: SharedStudy[];
}

export interface SharedStudy {
  /**  */
  permission: SharedStudyPermission;

  /**  */
  id: number;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  doctorId: number;

  /**  */
  doctor: Doctor;

  /**  */
  studyId: number;

  /**  */
  study: Study;
}

export interface Doctor {
  /**  */
  externalId: string;

  /**  */
  externalIds: ExternalId[];

  /**  */
  rppsNumber: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  motherMaidenName: string;

  /**  */
  sex: Sex;

  /**  */
  streetAddress: string;

  /**  */
  zipCode: string;

  /**  */
  city: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  country: string;

  /**  */
  speciality: string;

  /**  */
  category: string;

  /**  */
  skill: string;

  /**  */
  profession: string;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  state: string;

  /**  */
  shares: SharedStudy[];
}

export interface Patient {
  /**  */
  ssn: string;

  /**  */
  title: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  surName: string;

  /**  */
  motherMaidenName: string;

  /**  */
  sex: Sex;

  /**  */
  clients: CombinedClientsTypes;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  externalId: string;

  /**  */
  birthDate: Date;

  /**  */
  placeOfBirth: string;

  /**  */
  streetAddress: string;

  /**  */
  zipCode: string;

  /**  */
  city: string;

  /**  */
  state: string;

  /**  */
  country: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  legalPerson: string;

  /**  */
  maritalStatus: string;

  /**  */
  mergeDate: Date;

  /**  */
  mergePatient: Patient;

  /**  */
  externalIds: ExternalId[];

  /**  */
  referringDoctor: Doctor;

  /**  */
  blackListedDoctors: Doctor[];

  /**  */
  ALD: boolean;

  /**  */
  CSS: boolean;

  /**  */
  CMU: boolean;

  /**  */
  consent: string;

  /**  */
  consentDate: Date;

  /**  */
  studies: Study[];
}

export interface ExternalId {
  /**  */
  id: number;

  /**  */
  value: string;

  /**  */
  label: string;

  /**  */
  description: string;

  /**  */
  source: string;

  /**  */
  patient: Patient;
}

export interface CreateDoctorDto {
  /**  */
  externalIds: ExternalId[];

  /**  */
  rppsNumber: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  motherMaidenName: string;

  /**  */
  sex: Sex;

  /**  */
  streetAddress: string;

  /**  */
  zipCode: string;

  /**  */
  city: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  country: string;

  /**  */
  speciality: string;

  /**  */
  category: string;

  /**  */
  skill: string;

  /**  */
  profession: string;

  /**  */
  externalId?: string;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  state: string;

  /**  */
  shares: SharedStudy[];
}

export interface DoctorDto {
  /**  */
  externalId: string;

  /**  */
  externalIds: ExternalId[];

  /**  */
  rppsNumber: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  motherMaidenName: string;

  /**  */
  sex: Sex;

  /**  */
  streetAddress: string;

  /**  */
  zipCode: string;

  /**  */
  city: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  country: string;

  /**  */
  speciality: string;

  /**  */
  category: string;

  /**  */
  skill: string;

  /**  */
  profession: string;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  state: string;

  /**  */
  shares: SharedStudy[];
}

export interface Filter {
  /**  */
  columnName: string;

  /**  */
  operation?: string;

  /**  */
  value?: string;
}

export interface UpdateDoctorDto {
  /**  */
  externalId?: string;

  /**  */
  externalIds?: ExternalId[];

  /**  */
  rppsNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  motherMaidenName?: string;

  /**  */
  sex?: Sex;

  /**  */
  streetAddress?: string;

  /**  */
  zipCode?: string;

  /**  */
  city?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  mobileNumber?: string;

  /**  */
  country?: string;

  /**  */
  speciality?: string;

  /**  */
  category?: string;

  /**  */
  skill?: string;

  /**  */
  profession?: string;

  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  account?: User;

  /**  */
  state?: string;

  /**  */
  shares?: SharedStudy[];
}

export interface CreatePhoneAuthMethod {
  /**  */
  phoneNumber: string;
}

export interface PhoneVerifyRequest {
  /**  */
  verificationCode: string;
}

export interface LogDto {
  /**  */
  role: Role;

  /**  */
  _id?: string;

  /**  */
  createdAt: Date;

  /**  */
  id: number;

  /**  */
  entityId: number;

  /**  */
  action: EnumLogDtoAction;
}

export interface CapchaDto {
  /**  */
  data: string;

  /**  */
  code: string;
}

export interface LoginDto {
  /**  */
  email: string;

  /**  */
  password: string;
}

export interface UserDto {
  /**  */
  role: Role;

  /**  */
  email: string;

  /**  */
  client: number;

  /**  */
  phone: string;

  /**  */
  security: SecurityPreferences;

  /**  */
  id: number;
}

export interface LoginResDto {
  /**  */
  token?: string;

  /**  */
  user: UserDto;
}

export interface LocalUserRegisterPatientDto {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  accessionNumber: string;

  /**  */
  birthDate: Date;
}

export interface LocalUserRegisterFromMailDto {
  /**  */
  password: string;

  /**  */
  userId: number;

  /**  */
  verificationUUID: string;
}

export interface LocalUserResetPasswordDto {
  /**  */
  newPassword: string;

  /**  */
  resetPasswordUUID: string;
}

export interface LocalUserUpdatePasswordDto {
  /**  */
  newPassword: string;
}

export interface LocalUserForgetPasswordDto {
  /**  */
  email: string;
}

export interface CreateApiKeyDto {
  /**  */
  site: Site;

  /**  */
  expireAt: Date;
}

export interface ApiKeyDto {
  /**  */
  id: number;

  /**  */
  key: string;

  /**  */
  site: Site;

  /**  */
  expireAt: Date;

  /**  */
  locked: boolean;
}

export interface SiteDto {
  /**  */
  primary: boolean;

  /**  */
  client: CombinedClientTypes;

  /**  */
  externalId: string;

  /**  */
  name: string;

  /**  */
  streetAddress: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  state: string;

  /**  */
  country: string;

  /**  */
  id: number;

  /**  */
  clientId: number;

  /**  */
  finessNumber: string;

  /**  */
  finessStructNumber: string;

  /**  */
  siteStatus: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  fax: string;

  /**  */
  geocode: string;

  /**  */
  medicalEquipments: MedicalEquipment[];

  /**  */
  itEquipments: ITEquipment[];

  /**  */
  examRooms: ExamRoom[];

  /**  */
  capacity: number;
}

export interface CreateSiteDto {
  /**  */
  primary: boolean;

  /**  */
  name: string;

  /**  */
  streetAddress: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  state: string;

  /**  */
  country: string;

  /**  */
  clientId: number;

  /**  */
  finessNumber: string;

  /**  */
  finessStructNumber: string;

  /**  */
  siteStatus: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  fax: string;

  /**  */
  capacity: number;
}

export interface UpdateSiteDto {
  /**  */
  primary?: boolean;

  /**  */
  client?: CombinedClientTypes;

  /**  */
  externalId?: string;

  /**  */
  name?: string;

  /**  */
  streetAddress?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  country?: string;

  /**  */
  id?: number;

  /**  */
  clientId?: number;

  /**  */
  finessNumber?: string;

  /**  */
  finessStructNumber?: string;

  /**  */
  siteStatus?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  fax?: string;

  /**  */
  geocode?: string;

  /**  */
  medicalEquipments?: MedicalEquipment[];

  /**  */
  itEquipments?: ITEquipment[];

  /**  */
  examRooms?: ExamRoom[];

  /**  */
  capacity?: number;
}

export interface ClientDto {
  /**  */
  name: string;

  /**  */
  status: string;

  /**  */
  type: ClientType;

  /**  */
  siretNumber: string;

  /**  */
  sirenNumber: string;

  /**  */
  streetAddress: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  country: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  id: number;

  /**  */
  finessNumber: string;

  /**  */
  state: string;

  /**  */
  fax: string;

  /**  */
  externalId: string;

  /**  */
  logo: string;

  /**  */
  sites: Site[];

  /**  */
  admins: Admin[];

  /**  */
  preferences: ClientPreferences;
}

export interface CreateClientDto {
  /**  */
  name: string;

  /**  */
  status: string;

  /**  */
  type: ClientType;

  /**  */
  siretNumber: string;

  /**  */
  streetAddress: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  country: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  finessNumber: string;

  /**  */
  state: string;

  /**  */
  fax: string;

  /**  */
  admins: Admin[];
}

export interface UpdateClientDto {
  /**  */
  name?: string;

  /**  */
  status?: string;

  /**  */
  type?: ClientType;

  /**  */
  siretNumber?: string;

  /**  */
  sirenNumber?: string;

  /**  */
  streetAddress?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;

  /**  */
  country?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;

  /**  */
  finessNumber?: string;

  /**  */
  state?: string;

  /**  */
  fax?: string;

  /**  */
  logo?: string;

  /**  */
  sites?: Site[];

  /**  */
  admins?: Admin[];

  /**  */
  preferences?: ClientPreferences;
}

export interface FinessDto {
  /**  */
  id: number;

  /**  */
  finessNumber: string;

  /**  */
  finessStructNumber: string;

  /**  */
  siretNumber: string;

  /**  */
  name: string;

  /**  */
  phone: string;

  /**  */
  fax: string;

  /**  */
  street: string;

  /**  */
  BP: string;

  /**  */
  zipCode: string;

  /**  */
  open_date: Date;

  /**  */
  category: string;

  /**  */
  status: string;

  /**  */
  pricing: string;

  /**  */
  PSPH: string;
}

export interface PatientDto {
  /**  */
  ssn: string;

  /**  */
  title: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  surName: string;

  /**  */
  motherMaidenName: string;

  /**  */
  sex: Sex;

  /**  */
  clients: CombinedClientsTypes;

  /**  */
  _id?: string;

  /**  */
  referringDoctor: DoctorDto;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  externalId: string;

  /**  */
  birthDate: Date;

  /**  */
  placeOfBirth: string;

  /**  */
  streetAddress: string;

  /**  */
  zipCode: string;

  /**  */
  city: string;

  /**  */
  state: string;

  /**  */
  country: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  legalPerson: string;

  /**  */
  maritalStatus: string;

  /**  */
  mergeDate: Date;

  /**  */
  mergePatient: Patient;

  /**  */
  externalIds: ExternalId[];

  /**  */
  blackListedDoctors: Doctor[];

  /**  */
  ALD: boolean;

  /**  */
  CSS: boolean;

  /**  */
  CMU: boolean;

  /**  */
  consent: string;

  /**  */
  consentDate: Date;

  /**  */
  studies: Study[];
}

export interface UpdatePatientDto {
  /**  */
  ssn?: string;

  /**  */
  title?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  surName?: string;

  /**  */
  motherMaidenName?: string;

  /**  */
  sex?: Sex;

  /**  */
  clients?: CombinedClientsTypes;

  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  account?: User;

  /**  */
  externalId?: string;

  /**  */
  birthDate?: Date;

  /**  */
  placeOfBirth?: string;

  /**  */
  streetAddress?: string;

  /**  */
  zipCode?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  country?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  mobileNumber?: string;

  /**  */
  legalPerson?: string;

  /**  */
  maritalStatus?: string;

  /**  */
  mergeDate?: Date;

  /**  */
  mergePatient?: Patient;

  /**  */
  externalIds?: ExternalId[];

  /**  */
  referringDoctor?: Doctor;

  /**  */
  blackListedDoctors?: Doctor[];

  /**  */
  ALD?: boolean;

  /**  */
  CSS?: boolean;

  /**  */
  CMU?: boolean;

  /**  */
  consent?: string;

  /**  */
  consentDate?: Date;

  /**  */
  studies?: Study[];
}

export interface UpdatePatientReferringDoctorDto {
  /**  */
  shareOld: boolean;

  /**  */
  shareNew: boolean;

  /**  */
  rppsNumber: string;
}

export interface StudyDto {
  /**  */
  id: number;

  /**  */
  externalId: string;

  /**  */
  instanceUID: string;

  /**  */
  attendingDoctor: Admin;

  /**  */
  referringDoctor: Doctor;

  /**  */
  status: EnumStudyDtoStatus;

  /**  */
  plannedDate: Date;

  /**  */
  editedDate: Date;

  /**  */
  cancelDate: Date;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  type: StudyType;

  /**  */
  patient: Patient;

  /**  */
  visit: string;

  /**  */
  site: Client;

  /**  */
  accessionNumber: string;

  /**  */
  service: string;

  /**  */
  room?: string;

  /**  */
  modality: string;

  /**  */
  bodyPart: string;

  /**  */
  reports: Report[];

  /**  */
  reportReceivedAt: Date;

  /**  */
  order: Order;

  /**  */
  hasImage: boolean;

  /**  */
  imageReceivedCloudAt: Date;

  /**  */
  imageReceivedLocalAt: Date;

  /**  */
  acquisitionDate: Date;

  /**  */
  SR: boolean;

  /**  */
  GSPS: boolean;

  /**  */
  SC: boolean;

  /**  */
  shares: SharedStudy[];
}

export interface CreateSharedStudyDto {
  /**  */
  permission: SharedStudyPermission;

  /**  */
  endDate: Date;

  /**  */
  doctorId: number;

  /**  */
  studyId: number;
}

export interface SharedStudyDto {
  /**  */
  permission: SharedStudyPermission;

  /**  */
  id: number;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  doctorId: number;

  /**  */
  doctor: Doctor;

  /**  */
  studyId: number;

  /**  */
  study: Study;
}

export interface WorklistPatientDto {
  /**  */
  id: string;

  /**  */
  birthDate: Date;

  /**  */
  firstname: string;

  /**  */
  lastname: string;

  /**  */
  surname: string;

  /**  */
  sex: EnumWorklistPatientDtoSex;
}

export interface WorklistDoctorDto {
  /**  */
  rpps: string;

  /**  */
  lastname: string;

  /**  */
  firstname: string;
}

export interface WorklistDto {
  /**  */
  accessionNumber: string;

  /**  */
  studyInstanceUID: string;

  /**  */
  patient: WorklistPatientDto;

  /**  */
  title: string;

  /**  */
  modality: string;

  /**  */
  room: string;

  /**  */
  description: string;

  /**  */
  siteName: string;

  /**  */
  performingPhysician: WorklistDoctorDto;

  /**  */
  referringPhysician: WorklistDoctorDto;

  /**  */
  plannedDate: Date;

  /**  */
  procedureId?: string;

  /**  */
  procedureStepId?: string;

  /**  */
  scheduledAET?: string;
}

export interface AdminDto {
  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  client: CombinedClientTypes;

  /**  */
  role: AdminRole;

  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  clientId: number;

  /**  */
  email: string;
}

export interface CreateAdminDto {
  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  role: AdminRole;

  /**  */
  email: string;

  /**  */
  accountId: number;

  /**  */
  clientId: number;
}

export interface UpdateAdminDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  mobileNumber?: string;

  /**  */
  client?: CombinedClientTypes;

  /**  */
  role?: AdminRole;

  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  account?: User;

  /**  */
  clientId?: number;
}

export interface SuperAdmin {
  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;
}

export interface CreateSuperAdminDto {
  /**  */
  email: string;

  /**  */
  password: string;

  /**  */
  accountId: number;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;
}

export interface SuperAdminDto {
  /**  */
  id: number;

  /**  */
  accountId: number;

  /**  */
  account: User;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;
}

export interface UpdateSuperAdminDto {
  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  account?: User;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  mobileNumber?: string;
}

export interface RppsDto {
  /**  */
  id: number;

  /**  */
  rppsNumber: string;

  /**  */
  title: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  profession: string;

  /**  */
  category: string;

  /**  */
  skill: string;

  /**  */
  skillType: string;

  /**  */
  streetAddress: string;

  /**  */
  zipCode: string;

  /**  */
  phoneNumber: string;

  /**  */
  mobileNumber: string;

  /**  */
  fax: string;

  /**  */
  email: string;

  /**  */
  emailPro: string;
}

export interface ViewerDto {
  /**  */
  _id?: string;

  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  brand: string;
}

export interface CreateViewerDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  brand: string;
}

export interface UpdateViewerDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  brand?: string;
}

export enum Sex {
  'A' = 'A',
  'F' = 'F',
  'M' = 'M',
  'N' = 'N',
  'O' = 'O',
  'U' = 'U'
}

export enum ClientType {
  'PUBLIC' = 'PUBLIC',
  'LIBERAL' = 'LIBERAL',
  'MIXTE' = 'MIXTE'
}
export enum EnumMedicalEquipmentType {
  'US' = 'US',
  'CT' = 'CT',
  'MR' = 'MR',
  'RCT' = 'RCT',
  'BDUS' = 'BDUS',
  'BMD' = 'BMD',
  'MG' = 'MG',
  'EOS' = 'EOS',
  'PT' = 'PT',
  'NM' = 'NM',
  'LINAC' = 'LINAC',
  'CP' = 'CP',
  'OT' = 'OT'
}
export enum EnumPrepRoomStatus {
  'FREE' = 'FREE',
  'BUSY' = 'BUSY'
}
export type CombinedClientTypes = Client;
export enum AdminRole {
  'healthExecutive' = 'healthExecutive',
  'executiveDirector' = 'executiveDirector',
  'financeDirector' = 'financeDirector',
  'accountant' = 'accountant',
  'computerTechnician' = 'computerTechnician',
  'security' = 'security',
  'RSSI' = 'RSSI',
  'DSI' = 'DSI',
  'secratary' = 'secratary',
  'radiographer' = 'radiographer',
  'serviceProvider' = 'serviceProvider',
  'ITProvider' = 'ITProvider',
  'manager' = 'manager',
  'radiologist' = 'radiologist',
  'headEmergencies' = 'headEmergencies',
  'headRadiology' = 'headRadiology',
  'biomedicalEngineer' = 'biomedicalEngineer',
  'qualityManager' = 'qualityManager'
}

export enum Role {
  'patient' = 'patient',
  'doctor' = 'doctor',
  'admin' = 'admin',
  'super_admin' = 'super_admin'
}

export enum SharedStudyPermission {
  'VIEW' = 'VIEW',
  'VIEW_AND_REPORT' = 'VIEW_AND_REPORT',
  'EXPORT' = 'EXPORT'
}

export enum ReportType {
  'LOCAL_FILE' = 'LOCAL_FILE',
  'URL' = 'URL'
}

export enum ReportStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'FINAL' = 'FINAL',
  'NON_FINAL' = 'NON_FINAL'
}

export enum OrderStatus {
  'VALIDATED' = 'VALIDATED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'REFUSED' = 'REFUSED'
}
export enum EnumStudyStatus {
  'SCHEDULED' = 'SCHEDULED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'LOCKED' = 'LOCKED',
  'IMAGE_RECEIVED' = 'IMAGE_RECEIVED',
  'DONE' = 'DONE'
}
export type CombinedClientsTypes = Client;
export enum EnumLogDtoAction {
  'LOGIN' = 'LOGIN',
  'AUTO_LOGOUT' = 'AUTO_LOGOUT',
  'LOGOUT' = 'LOGOUT',
  'GET_CLIENT' = 'GET_CLIENT',
  'GET_SITE' = 'GET_SITE',
  'UPDATE_PHONE_NUMBER' = 'UPDATE_PHONE_NUMBER',
  'UPDATE_SOCIAL_SECURITY_NUMBER' = 'UPDATE_SOCIAL_SECURITY_NUMBER',
  'CHANGE_PASSWORD' = 'CHANGE_PASSWORD',
  'CHANGE_EMAIL' = 'CHANGE_EMAIL',
  'CHANGE_PROFILE' = 'CHANGE_PROFILE',
  'GET_PROFILE' = 'GET_PROFILE',
  'UPDATE_PROFILE' = 'UPDATE_PROFILE',
  'VIEW_STUDY' = 'VIEW_STUDY',
  'VIEW_REPORT' = 'VIEW_REPORT',
  'SHARE_STUDY' = 'SHARE_STUDY',
  'UNSHARE_STUDY' = 'UNSHARE_STUDY',
  'VIEW_IMAGES' = 'VIEW_IMAGES',
  'UPLOAD_ADIVCE' = 'UPLOAD_ADIVCE',
  'REMOVE_ADVICE' = 'REMOVE_ADVICE',
  'VIEW_ADVICE' = 'VIEW_ADVICE',
  'MERGE_PATIENT_MOVE_STUDY' = 'MERGE_PATIENT_MOVE_STUDY',
  'CHANGE_STATE_ADVICE' = 'CHANGE_STATE_ADVICE',
  'VIEW_PATIENT' = 'VIEW_PATIENT',
  'UPLOAD_CONSENT' = 'UPLOAD_CONSENT',
  'VIEW_CONSENT' = 'VIEW_CONSENT',
  'ADD_REFERRING' = 'ADD_REFERRING',
  'ADD_DOCTOR_TO_PORFILE' = 'ADD_DOCTOR_TO_PORFILE',
  'REMOVE_DOCTOR_FROM_PORFILE' = 'REMOVE_DOCTOR_FROM_PORFILE',
  'MERGE_PATIENT' = 'MERGE_PATIENT',
  'HL7_ORM_RECEIVED' = 'HL7_ORM_RECEIVED',
  'HL7_ORU_RECEIVED' = 'HL7_ORU_RECEIVED',
  'HL7_OMG_RECEIVED' = 'HL7_OMG_RECEIVED',
  'HL7_SIU_RECEIVED' = 'HL7_SIU_RECEIVED',
  'HL7_ADT_A40_RECEIVED' = 'HL7_ADT_A40_RECEIVED',
  'HL7_ADT_A04_RECEIVED' = 'HL7_ADT_A04_RECEIVED',
  'HL7_ADT_RECEIVED' = 'HL7_ADT_RECEIVED',
  'DCM_RECEIVED' = 'DCM_RECEIVED',
  'DCM_SENT' = 'DCM_SENT',
  'DCM_RECEIVED_LOCAL' = 'DCM_RECEIVED_LOCAL',
  'DCM_RECEIVED_CLOUD' = 'DCM_RECEIVED_CLOUD',
  'FINESS_AUTOCOMPLETE' = 'FINESS_AUTOCOMPLETE'
}
export enum EnumStudyDtoStatus {
  'SCHEDULED' = 'SCHEDULED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'LOCKED' = 'LOCKED',
  'IMAGE_RECEIVED' = 'IMAGE_RECEIVED',
  'DONE' = 'DONE'
}
export enum EnumWorklistPatientDtoSex {
  'A' = 'A',
  'F' = 'F',
  'M' = 'M',
  'N' = 'N',
  'O' = 'O',
  'U' = 'U'
}
