import { createRef, memo, useState } from 'react'

import { Add } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'

import { EtherFile } from '@services/extendedType'

import FileItem from './FileItem'

interface Props {
  accept?: string
  files: EtherFile[]
  onChange(files: File[]): void
  onDeleteFile(file: EtherFile): void
  onDownloadFile(file: EtherFile): void
  multiple?: boolean
}

export const FileInput = ({
  accept,
  files,
  onChange,
  onDeleteFile,
  onDownloadFile,
  multiple,
}: Props) => {
  const inputRef = createRef<HTMLInputElement>()
  const [over, setover] = useState(false)

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (event.target.files.length > 4) {
        alert('Max number of file is 4 !')
        return false
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index]
        if (file.size > 4194304) {
          alert('Max file size is 4Mo !')
          return false
        }
      }
      onChange(Array.from(event.target.files))
    }
  }

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <ul className="input-files">
      {files.map((file, i) => (
        <FileItem
          index={i}
          key={i}
          file={file}
          onDelete={(item) => onDeleteFile(item)}
          onDownload={() => onDownloadFile(file)}
        />
      ))}
      {!multiple && !files.length && (
        <li
          onDrop={(e: any) => {
            e.preventDefault()
            // e.persist()
            e.target = e.dataTransfer
            handleFilesChange(e)
          }}
          onDragOver={(e) => {
            e.preventDefault()
            setover(true)
          }}
          onDragLeave={(e) => {
            e.preventDefault()
            setover(false)
          }}
          onClick={handleClick}
          className={
            over ? 'item-base item-input over' : 'item-base item-input'
          }
        >
          <Tooltip title="Add">
            <Add />
          </Tooltip>
          <input
            ref={inputRef}
            type="file"
            name="myFiles"
            accept={accept}
            multiple={multiple}
            onChange={handleFilesChange}
          />
        </li>
      )}
    </ul>
  )
}

export const MemoizedFileInput = memo(FileInput)
