import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { each, filter, orderBy } from 'lodash'

type edamState = {
  listActions: Array<any>
}

const initialState = { listActions: [] } as edamState

const edamSlice = createSlice({
  name: 'edam',
  initialState,
  reducers: {
    getListActions: (state, action: PayloadAction<Array<any>>) => {
      state.listActions = action.payload
    },

    addActions: (state, action: PayloadAction<Object>) => {
      state.listActions = [...state.listActions, action.payload]
    },

    updateActions: (state, action: PayloadAction<any>) => {
      let newArray = filter(
        state.listActions,
        (o) => o.key !== action.payload.key,
      )
      const newObject = action.payload.value
      newObject.key = action.payload.key
      newArray = [...newArray, newObject]
      newArray = orderBy(newArray, ['key'], ['asc'])
      state.listActions = newArray
    },

    removeActions: (state, action: PayloadAction<string>) => {
      const _filter = filter(state.listActions, (o) => o.key !== action.payload)

      let rArray: Array<any> = []

      each(_filter, (o, index) => {
        o.key = `Action ${index + 1}`
        rArray = [...rArray, o]
      })

      state.listActions = rArray
    },
  },
})

export const { getListActions, addActions, removeActions, updateActions } =
  edamSlice.actions

export default edamSlice.reducer
