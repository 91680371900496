import { forwardRef, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { TextInput } from '@components/inputs'
import { SelectInput } from '@components/inputs'

import { siteStatus } from '@utils/constants'
import { EcSpaceHorizontal } from '@utils/Spacing'
import { addClient } from '@state/actions/clientActions'
import { addClientSchema } from '@utils/schemas'
import { CreateClientDto, SiteDto } from '@services/api'

import { CommonEnrollment } from './CommonEnrollment'

//TODO useless component ?
export const HospitalCheckInfos = forwardRef(
  ({ site }: { site?: SiteDto }, ref) => {
    const dispatch = useDispatch()

    const { register, handleSubmit, errors, control } =
      useForm<CreateClientDto>({
        resolver: yupResolver(addClientSchema) as any,
        defaultValues: site,
      })
    console.log(errors)

    const onSubmit = (clientDto: CreateClientDto) => {
      dispatch(addClient({ body: clientDto }))
    }
    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(onSubmit)()
      },
    }))

    return (
      <CommonEnrollment>
        <Grid item xs={11} style={{ display: 'flex' }}>
          <TextInput input="finessNumber" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="name" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <SelectInput
            options={siteStatus}
            control={control}
            errors={errors}
            input="typeHospital"
          />
        </Grid>

        <Grid item xs={11} style={{ display: 'flex' }}>
          <TextInput input="street" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="city" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="postalCode" inputRef={register} errors={errors} />
        </Grid>

        <Grid item xs={11} style={{ display: 'flex' }}>
          <TextInput input="firstName" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="lastName" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="email" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="phone" inputRef={register} errors={errors} />
          <EcSpaceHorizontal size={20} />
          <TextInput input="role" inputRef={register} errors={errors} />
        </Grid>

        <Grid item xs={10} style={{ display: 'flex' }}>
          <Grid item xs={7} style={{ display: 'flex' }}></Grid>
        </Grid>
      </CommonEnrollment>
    )
  },
)
