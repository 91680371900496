import { AnyAction } from 'redux'

import { AlertActions } from '../actions'
type Variant = 'error' | 'info' | 'default' | 'warning' | 'success'

type Snackbar = {
  message: string
  key: number
  options: {
    key: number
    variant?: Variant
    action: (key: number) => JSX.Element
  }
  dismissed: boolean
  displayed: boolean
}

type AlertState = {
  notifications: Snackbar[]
}

const initialState: AlertState = {
  notifications: [],
}

const alertReducer = (state: AlertState = initialState, action: AnyAction) => {
  const { type, payload } = action
  const matches = /(.*)_(ERROR|SUCCESS)/.exec(type)
  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  // if (!matches) return state
  // const intl = useIntl()

  if (matches && payload) {
    const [, request, variant] = matches
    if (variant.toLowerCase() === ('error' || 'warning' || 'success')) {
      const notification = {
        message: payload.message || request, // || intl.formatMessage({ id: message }),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: variant.toLowerCase(),
        },
      }

      action = {
        type: AlertActions.ENQUEUE_SNACKBAR,
        payload: {
          ...notification,
          key: new Date().getTime() + Math.random(),
        },
      }
    }
  }
  switch (action.type) {
    case AlertActions.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: payload.key,
            ...action.payload,
          },
        ],
      }

    case AlertActions.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.payload.dismissAll || notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      }

    case AlertActions.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key === action.payload,
        ),
      }

    default:
      return state
  }
}
export default alertReducer
// const alertSlice = createSlice({
//     name: 'alert',
//     initialState,
//     reducers: {
//         enqueueSnackbar(state, action: PayloadAction<any>) {
//             const { key } = action.payload
//             state.notifications = [...state.notifications, { key: key, ...action.payload }]
//         },
//         closeSnackbar(state, action: PayloadAction<any>) {
//             const { key, dismissAll } = action.payload
//             state.notifications = state.notifications.map((notification) =>
//                 dismissAll || notification.key === key
//                     ? { ...notification, dismissed: true }
//                     : { ...notification }
//             )
//         },
//         removeSnackbar(state, action: PayloadAction<any>) {
//             state.notifications = state.notifications.filter(
//                 (notification) => notification.key !== action.payload
//             )
//         }
//     }
// })

// export const {
//     enqueueSnackbar
// } = alertSlice.actions

// export default alertSlice.reducer
