import { Control, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Switch, Box, Typography } from '@material-ui/core'

type EcSwitchInputProps = {
  control: Control<any>
  input: string
  name?: string
  defaultValue?: any
}
export function EcSwitchInput({
  input,
  name = input,
  control,
  defaultValue,
  ...props
}: EcSwitchInputProps) {
  const intl = useIntl()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <Box display="flex" alignItems="center">
          <Typography>
            {input && intl.formatMessage({ id: 'inputs.labels.' + input })}
          </Typography>
          <Switch
            {...props}
            checked={value}
            //  onChange={onChange}
            onChange={(e) => onChange(e.target.checked)}
            // name={name}
            color="primary"
          />
        </Box>
      )}
    />
  )
}
// label={input && <FormattedMessage id={'inputs.labels.' + input} />}
// placeholder={
//   input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
// }
// error={errors && !!errors[name]}
// helperText={
//   errors && errors[name] && <FormattedMessage id={errors[name].message} />
// }
