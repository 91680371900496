import { CreateClientDto, UpdateClientDto } from '@services/api'
import { ClientActions } from '../actions'

export const addClient = (params: {
  /** requestBody */
  body?: CreateClientDto
}) => ({
  type: ClientActions.POST_CLIENT_REQUEST,
  payload: params,
})

export const findClient = (params: {
  /**  */
  id: number
}) => ({
  type: ClientActions.GET_CLIENT_REQUEST,
  payload: params,
})

export const findAllClient = () => ({
  type: ClientActions.GET_CLIENTS_REQUEST,
})

export const updateClient = (params: {
  /**  */
  id: number
  /** requestBody */
  body?: UpdateClientDto
}) => ({
  type: ClientActions.PUT_CLIENT_REQUEST,
  payload: params,
})
