import { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid } from '@material-ui/core'
import {
  AutocompleteInput,
  DateInput,
  SelectInput,
  SwitchInput,
  TextArea,
  TimeInput,
} from '@components/inputs'

import { PatientDialog } from '../../../containers/app/patient/PatientDialog'
import { DoctorDto } from '@services/api'
import { addClientSchema } from '@utils/schemas'
import { bodyPart, scannerStudy } from '@utils/constants'
type Values = {
  dataAppointment: Date
  startDate: string
  endDate: string
  salle: string
  room: string
  doctor: string
  preferedDoctor: string
  type: string
  study: string
  emergency: string
  hospitalized?: string
  referringDoctor?: string
  comments: string
  waitingList: string
}

export const UpdateAppointmentForm = forwardRef((_, ref) => {
  const [openModal, setOpenModal] = useState(false)
  const [doctor] = useState<DoctorDto>()
  // const { start, end }: any = slots
  // const dateAppointmentStart = new Date(start)
  // const dateAppointmentEnd = new Date(end)

  const { register, handleSubmit, errors, control, watch } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {
      room: '',
    },
  })
  console.log(doctor)
  console.log(watch())
  console.log(errors)

  const onSubmit = () => {
    // const newClient: ClientDto = {
    //   name: values.startDate,
    //   siretNumber: values.salle.substring(0, values.salle.length - 5),
    //   sirenNumber: values.salle,
    //   companyStatus: values.room,
    //   streetAddress: values.doctor,
    //   postalCode: values.preferedDoctor,
    //   city: values.type,
    //   state: values.type,
    //   country: values.study,
    //   email: values.comments,
    //   phoneNumber: values.emergency,
    //   //fax: values.hospitalized,
    //  // sites: [],
    // }
    // dispatch(addClient({ body: newClient }))
  }
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <Grid container spacing={4}>
      {/* Left */}

      <Grid item xs={6}>
        <SelectInput
          options={[
            { value: '1', label: 'Room 1' },
            { value: '2', label: 'Room 2' },
          ]}
          control={control}
          input="room"
          errors={errors}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectInput
          options={scannerStudy}
          control={control}
          input="type"
          errors={errors}
        />
      </Grid>

      {/* ROW 2 */}
      <Grid item xs={4}>
        <DateInput
          // defaultDate={dateAppointmentStart}
          input="dateAppointment"
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item xs={4}>
        {/* {dateAppointmentStart.toLocaleTimeString()} */}
        <TimeInput
          input="startTime"
          // defaultValue={dateAppointmentStart}
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item xs={4}>
        <TimeInput
          input="endTime"
          // defaultValue={dateAppointmentEnd}
          control={control}
          errors={errors}
        />
      </Grid>

      {/* ROW 3 */}
      <Grid item xs={6}>
        {/* <AutocompleteDoctorInput onSelect={setDoctor} /> */}
        <AutocompleteInput
          input="searchDoctor"
          options={[
            { value: '1', label: 'Doctor 1' },
            { value: '2', label: 'Doctor 2' },
          ]}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteInput
          input="searchDoctor"
          options={[
            { value: '1', label: 'Doctor 1' },
            { value: '2', label: 'Doctor 2' },
          ]}
        />
      </Grid>

      {/* ROW 4 */}
      <Grid item xs={5}>
        <SelectInput
          options={bodyPart}
          control={control}
          input="bodyPart"
          errors={errors}
        />
      </Grid>

      <Grid item xs={3}>
        <Button
          color="primary"
          onClick={() => setOpenModal(true)}
          style={{ paddingLeft: 0 }}
        >
          + Add Body Part
        </Button>
      </Grid>

      <Grid item xs={3}>
        <SwitchInput label="Injected" />
      </Grid>

      {/* ROW 5 */}
      <Grid item xs={5}>
        <SwitchInput label="Prefered Doctor" />
      </Grid>
      <Grid item xs={5}>
        <SwitchInput label="Emergency" />
      </Grid>

      {/* ROW 6 */}
      <Grid item xs={5}>
        <SwitchInput label="Hospitalized" />
      </Grid>
      <Grid item xs={5}>
        <SwitchInput label="Waiting List" />
      </Grid>

      {/* ROW 7 */}
      <Grid item xs={6}>
        <AutocompleteInput
          input="patient"
          options={[
            { value: '1', label: 'Patient 1' },
            { value: '2', label: 'Patient 2' },
          ]}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          color="primary"
          // onClick={() => setOpenModal(true)}
          style={{ paddingLeft: 0 }}
        >
          View detail patient
        </Button>{' '}
      </Grid>
      <Grid item xs={3}>
        <Button
          color="primary"
          onClick={() => setOpenModal(true)}
          style={{ paddingLeft: 0 }}
        >
          + New Patient
        </Button>{' '}
      </Grid>

      {/* ROW 8 */}
      <Grid item xs={12}>
        <TextArea input="comment" inputRef={register} errors={errors} />
      </Grid>

      {openModal && (
        <PatientDialog
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
        />
      )}
    </Grid>
  )
})
