import { AnyAction } from 'redux'
import { all, fork, put, takeLatest } from 'redux-saga/effects'

import { UserActions } from '../actions'

function* openViewer({ payload }: AnyAction) {
  try {
    const { externalId } = payload
    const url = `${process.env.REACT_APP_RADIO_VIEWER_URL}&action=view&studyInstanceUID=${externalId}`
    const win = window.open(url, '_blank')
    if (win) {
      win.focus()
    } else {
      throw new Error('OPEN_VIEWER_ERROR')
    }
  } catch (e) {
    yield put({
      type: UserActions.OPEN_VIEWER_ERROR,
      message: 'default.msg.error',
    })
  }
}

export function* userFlow() {
  yield all([takeLatest(UserActions.OPEN_VIEWER_REQUEST, openViewer)])
}

const userSagas = [fork(userFlow)]

export default userSagas
