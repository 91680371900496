import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'

import { addClientSchema } from '@utils/schemas'
import { DateInput, PhoneInput2, TextInput } from '@components/inputs'
import { Button } from '@components/buttons'

type Values = {
  startDate: string
  endDate: string
  salle: string
  room: string
  doctor: string
  preferedDoctor: string
  type: string
  study: string
  emergency: string
  hospitalized?: string
  referringDoctor?: string
  comments: string
  waitingList: string
}

type PatientFormProps = {
  row?: any //CreatePatientDto
  onClose?: () => void
}

export function PatientForm({ row, ...props }: PatientFormProps) {
  // console.log('Row', row)
  // console.log('Props', props)
  const { onClose } = props
  //const dispatch = useDispatch()

  const { register, handleSubmit, errors, control, watch } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {
      room: '',
      ...row,
    },
  })
  console.log(watch())
  console.log(errors)

  const onSubmit = () => {
    // console.log(values)
    // const newClient: ClientDto = {
    //   name: values.startDate,
    //   siretNumber: values.salle.substring(0, values.salle.length - 5),
    //   sirenNumber: values.salle,
    //   companyStatus: values.room,
    //   streetAddress: values.doctor,
    //   postalCode: values.preferedDoctor,
    //   city: values.type,
    //   state: values.type,
    //   country: values.study,
    //   email: values.comments,
    //   phoneNumber: values.emergency,
    //   fax: values.hospitalized,
    //   sites: [],
    // }
    // dispatch(addClient({ body: newClient }))
  }
  // useImperativeHandle(ref, () => ({
  //   submit() {
  //     handleSubmit(onSubmit)()
  //   },
  // }))

  return (
    <Grid container spacing={3}>
      {/* row 1 */}
      <Grid item xs={12}>
        {/* <AutocompleteInput input="ReferringDoctor" /> */}
      </Grid>
      {/* row 2 */}
      <Grid item xs={6}>
        <TextInput input="firstName" inputRef={register} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <TextInput input="lastName" inputRef={register} errors={errors} />
      </Grid>

      {/* row 3 */}
      <Grid item xs={6}>
        <DateInput input="birthDate" control={control} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <TextInput input="birthPlace" inputRef={register} errors={errors} />
      </Grid>

      {/* row 4 */}
      <Grid item xs={6}>
        <PhoneInput2 name="phoneNumber" control={control} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <PhoneInput2 name="phoneNumber" control={control} errors={errors} />
      </Grid>

      {/* row 5 */}
      <Grid item xs={6}>
        <TextInput input="streetAddress" inputRef={register} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <TextInput input="postalCode" inputRef={register} errors={errors} />
      </Grid>

      {/* row 6 */}
      <Grid item xs={6}>
        <TextInput input="city" inputRef={register} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <TextInput input="country" inputRef={register} errors={errors} />
      </Grid>

      {/* row 7 */}
      <Grid item xs={6}>
        <TextInput input="email" inputRef={register} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <TextInput input="ssn" inputRef={register} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        {/* <AutocompleteInput input="ReferringDoctor" /> */}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} justify="flex-end">
          <Grid item>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="Save"
            />
          </Grid>
          {onClose && (
            <Grid item>
              <Button onClick={onClose} color="secondary" text="Cancel" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
