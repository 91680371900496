import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import { LogActions } from '../actions'
import { AnyAction } from 'redux'
import { LogDto, LogService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { setLogs } from '../reducers/logReducer'

function* findAll({ payload }: AnyAction) {
  try {
    const logs: PaginatedDto<LogDto> = yield call(LogService.findAll, payload)
    yield put(setLogs(logs))
    yield put({
      type: LogActions.GET_LOGS_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: LogActions.GET_LOGS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* logFlow() {
  yield all([takeLatest(LogActions.GET_LOGS_REQUEST, findAll)])
}

const logSagas = [fork(logFlow)]

export default logSagas
