import { CreateSharedStudyDto } from '@services/api'
import { StudyActions } from '../actions'

export const findAllStudy = () => ({
  type: StudyActions.GET_STUDIES_REQUEST,
  payload: {},
})

export const findOneStudy = (params: {
  /**  */
  id: number
}) => ({
  type: StudyActions.GET_STUDY_REQUEST,
  payload: params,
})
export const shareStudy = (params: {
  /** requestBody */
  body?: CreateSharedStudyDto
}) => ({
  type: StudyActions.POST_SHARE_STUDY_REQUEST,
  payload: params,
})
export const unshareStudy = (params: {
  /**  */
  id?: number
  /**  */
  studyId?: number
}) => ({
  type: StudyActions.POST_UNSHARE_STUDY_REQUEST,
  payload: params,
})

export const uploadOrder = (params: {
  /**  */
  id: number
  /**  */
  files: File[]
}) => ({
  type: StudyActions.POST_UPLOAD_ORDER_REQUEST,
  payload: params,
})

export const downloadOrder = (params: {
  /**  */
  id: number
}) => ({
  type: StudyActions.POST_DOWNLOAD_ORDER_REQUEST,
  payload: params,
})

export const downloadReport = (params: {
  /**  */
  id: number
}) => ({
  type: StudyActions.POST_DOWNLOAD_REPORT_REQUEST,
  payload: params,
})

export const deleteOrder = (params: {
  /**  */
  id: number
}) => ({
  type: StudyActions.POST_DELETE_ORDER_REQUEST,
  payload: params,
})

export const downloadImage = (params: {
  /**  */
  id: number
}) => ({
  type: StudyActions.GET_DOWNLOAD_IMAGE_REQUEST,
  payload: params,
})
