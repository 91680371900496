import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { Box, Grid, Icon, Paper, Theme, makeStyles } from '@material-ui/core'
import { truncate } from 'lodash'

import Text from '@components/Text'
import { EcSpaceVertical } from '@utils/Spacing'
const useStyles = makeStyles((theme: Theme) => ({
  card: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    color: '#fff',
  },
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

export const superAdminLinks = [
  {
    to: '/sa/equipments',
    title: 'Technical platform',
    style: { backgroundImage: 'linear-gradient(45deg,#843cf7,#38b8f2)' },
    icon: 'fas fa-vials',
  },
  {
    to: '/sa/clients',
    title: 'Clients',
    style: { backgroundImage: 'linear-gradient(45deg,#23bcbb,#45e994)' },
    icon: 'fa fa-hospital-user',
  },
  {
    to: '/sa/admins',
    title: 'Admins',
    style: { backgroundImage: 'linear-gradient(45deg,#000,#53346d)' },
    icon: 'fa fa-user-shield',
  },
  {
    to: '/sa/sites',
    title: 'Sites',
    style: { backgroundImage: 'linear-gradient(45deg,#843cf7,#38b8f2)' },
    icon: 'fas fa-clinic-medical',
  },
  {
    to: '/sa/doctors',
    title: 'Referring Doctors',
    style: { backgroundImage: 'linear-gradient(45deg,#23bcbb,#45e994)' },
    icon: 'fa fa-user-md',
  },
  {
    to: '/sa/patients',
    title: 'Patients',
    style: { backgroundImage: 'linear-gradient(45deg,#000,#53346d)' },
    icon: 'fa fa-procedures',
  },
  {
    to: '/sa/stats',
    title: 'Stats',
    style: { backgroundImage: 'linear-gradient(45deg,#843cf7,#38b8f2)' },
    icon: 'fa fa-chart-bar',
  },
  {
    to: '/sa/dataset',
    title: 'Dataset',
    style: { backgroundImage: 'linear-gradient(45deg,#23bcbb,#45e994)' },
    icon: 'fa fa-database',
  },
  {
    to: '/sa/update-dataset',
    title: 'Update dataset',
    style: { backgroundImage: 'linear-gradient(45deg,#000,#53346d)' },
    icon: 'fa fa-sync',
  },
  {
    to: '/sa/logs',
    title: 'Logs',
    style: { backgroundImage: 'linear-gradient(45deg,#843cf7,#38b8f2)' },
    icon: 'fa fa-chart-area',
  },
]

export default function HomeSuperAdmin() {
  const classes = useStyles()

  return (
    <Grid container spacing={isMobileOnly ? 2 : 3}>
      {superAdminLinks.map((item, key) => (
        <Grid item xs={4} key={key}>
          <Link to={item.to}>
            <Paper className={classes.paper} style={item.style}>
              {/* <Grid item container alignItems="center" justifyContent="space-between">
                <Text text={item.title} variant="h4" />
                <Icon className={item.icon} fontSize="large" />
              </Grid> */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isMobileOnly ? 'column-reverse' : 'row'}
              >
                <Text
                  text={
                    isMobileOnly
                      ? truncate(item.title, { length: 9 })
                      : item.title
                  }
                  variant={isMobileOnly ? 'h6' : 'h4'}
                />
                {isMobileOnly && <EcSpaceVertical size={4} />}
                <Icon className={item.icon} fontSize="large" />
              </Box>
              {/* <OneElementHome title={item.title} icon={item.icon} /> */}
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
