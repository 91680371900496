import { useState } from 'react'

import { Box } from '@material-ui/core'
import RoomServiceOutlinedIcon from '@material-ui/icons/RoomServiceOutlined'
import { Button } from '@components/buttons'
import { SelectRoomsDialog } from '@components//dialogs'

export function SelectRooms() {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <Box>
      <Button
        variant="outlined"
        textColor="primary"
        color="primary"
        text="Selection Of Rooms"
        size="large"
        startIcon={<RoomServiceOutlinedIcon />}
        onClick={() => {
          setOpenDialog(true)
        }}
      />

      {openDialog && (
        <SelectRoomsDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false)
          }}
        />
      )}
    </Box>
  )
}
