import { useState, useMemo, useEffect } from 'react'
import { Control, Controller } from 'react-hook-form'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import throttle from 'lodash/throttle'

import Text from '../Text'

type AddressInputProps = {
  name: string
  control: Control<any>
}

export function ZipFranceInput({ name, control }: AddressInputProps) {
  const [options, setOptions] = useState<Properties[]>([])
  const [inputValue, setInputValue] = useState('')
  // const loading = open && options.length === 0

  const fetchMemo = useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: ({ features }: { features: Feature[] }) => void,
        ) => {
          const response: Response = await fetch(
            'https://api-adresse.data.gouv.fr/search/?q=' + request.input,
          )
          callback(await response.json())
        },
        200,
      ),
    [],
  )

  useEffect(() => {
    let active = true

    // if (inputValue === '') {
    //   setOptions(value ? [value] : []);
    //   return undefined;
    // }

    fetchMemo(
      { input: inputValue },
      ({ features }: { features: Feature[] }) => {
        if (active) {
          let newOptions = [] as Properties[]

          // if (value) {
          //   newOptions = [value];
          // }

          if (features) {
            newOptions = [
              ...newOptions,
              ...features.map(({ properties }) => properties),
            ]
          }

          setOptions(newOptions)
        }
      },
    )

    return () => {
      active = false
    }
  }, [inputValue, fetchMemo])
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          style={{ color: 'black' }}
          {...props}
          fullWidth
          noOptionsText={<Text text="No results" color="primary" />}
          options={options}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(option) => option.postcode}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
            />
          )}
          onInputChange={(event, data) => setInputValue(data)}
          onChange={(_, data) => {
            setOptions(data ? [data, ...options] : options)
            onChange(data?.postcode)
          }}
        />
      )}
      name={name}
      control={control}
    />
  )
}
