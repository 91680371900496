import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Footer from '@components/Footer'
import { RootState } from '@state/reducers/root'

export default function AuthLayout({ children, ...props }: any) {
  const isAuth = useSelector(({ auth }: RootState) => auth.isAuth)
  if (isAuth)
    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  return (
    <>
      <main
        style={{
          minHeight: '94.99vh',
          //overflowY: 'auto',
          // background: "no-repeat url('https://source.unsplash.com/collection/19862719/1920x1080')",
          // backgroundImage: 'linear-gradient(45deg, #303f9f, #14adce)',
        }}
      >
        {children}
        {/* style height 5 vh */}
        <Footer />
      </main>
    </>
  )
}
