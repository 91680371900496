import { Control, DeepMap, FieldError } from 'react-hook-form'

import { Grid } from '@material-ui/core'
import { EcSwitchInput, SelectInput, TextInput } from '@components/inputs'

import { radiologistSpecialities } from '@utils/constants'

// Roles forms
type FormTypeProps = {
  control: Control<any>
  errors?: DeepMap<any, FieldError>
}

export function RadiologistForm({ control, errors }: FormTypeProps) {
  return (
    <>
      <Grid item xs={6}>
        <SelectInput
          options={radiologistSpecialities}
          control={control}
          input="speciality"
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput input="rpps" errors={errors} />
      </Grid>
    </>
  )
}

export function RadiographerForm({ control, errors }: FormTypeProps) {
  return (
    <>
      <Grid item xs={6}>
        <TextInput input="rpps" errors={errors} />
      </Grid>
      <Grid item xs={12}>
        <EcSwitchInput control={control} input="MRI" />
        <EcSwitchInput control={control} input="CT" />
        <EcSwitchInput control={control} input="mammography" />
        <EcSwitchInput control={control} input="ultrasoundDiploma" />
      </Grid>
    </>
  )
}

export function SecretaryForm({ control }: FormTypeProps) {
  return (
    <>
      <Grid item xs={12}>
        <EcSwitchInput control={control} input="phoneAppointment" />
        <EcSwitchInput control={control} input="accounting" />
        <EcSwitchInput control={control} input="transcription" />
        <EcSwitchInput control={control} input="patientReception" />
        <EcSwitchInput control={control} input="inventory" />
      </Grid>
    </>
  )
}
