import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      //  maxWidth: 220,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      padding: 2,
    },
  }),
)

interface option {
  value: string | number
  label: string
}

export type EcSelectProps = {
  options: option[]
  className?: string
  fullWidth?: boolean
  label?: string
  handleSelected?: any
}

export default function EcSelect({
  options,
  label,
  handleSelected,
  fullWidth = true,
}: EcSelectProps) {
  const classes = useStyles()
  const [select, setSelect] = useState<string | number>('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelect(event.target.value as string)
    if (handleSelected) {
      handleSelected(event.target.value as string)
    }
  }

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.formControl}
    >
      <InputLabel
        className={classes.label}
        htmlFor="outlined-age-native-simple"
      >
        {label || 'label'}
      </InputLabel>
      <Select
        native
        value={select}
        onChange={handleChange}
        label={label}
        fullWidth={fullWidth}
      >
        {options.map((option, index: number) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}
