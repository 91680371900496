import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { TextFieldProps } from '@material-ui/core'
import { KeyboardTimePicker } from '@material-ui/pickers'

type DateTimeInputProps = TextFieldProps & {
  control: Control<any>
  input: string
  errors?: DeepMap<any, FieldError>
  fullWidth?: boolean
  defaultValue?: Date
}

export function TimeInput({
  input,
  control,
  errors,
  defaultValue = new Date(),
}: DateTimeInputProps) {
  const intl = useIntl()
  return (
    <Controller
      name={input}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <KeyboardTimePicker
          style={{ margin: 0 }}
          fullWidth
          size="small"
          onChange={onChange}
          value={value}
          clearable
          margin="dense"
          inputVariant="outlined"
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          InputLabelProps={{
            style: {
              fontWeight: 500,
              padding: '0px 5px 2px 5px',
              borderRadius: 2,
            },
          }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              fontSize: 15.5,
            },
          }}
          label={input && intl.formatMessage({ id: 'inputs.labels.' + input })}
          placeholder={
            input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
          }
          error={errors && !!errors[input]}
          helperText={
            errors &&
            errors[input] &&
            intl.formatMessage({ id: errors[input].message })
          }
        />
      )}
    />
  )
}
