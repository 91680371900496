import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { AutoLogoutDialog } from '@components/dialogs'
import Navbar from '@components/NavBar'
import Footer from '@components/Footer'
import Drawer from '@components/Drawer'
import { Avatar, Backdrop, Theme, makeStyles } from '@material-ui/core'

import Logo from '../assets/img/logos/logo.png'

// import ConfirmMobileModal from '@containers/app/Confir mMobileModal'
// import useWindowSize from '@hooks/useWindowSize'
import { RootState } from '@state/reducers/root'
import { ClientDto, Role, UserDto } from '@services/api'
import { logout } from '@state/actions/authActions'
import { findClient } from '@state/actions/clientActions'

// toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  animatedLogo: {
    animation: '$animation 2s ease-in-out alternate infinite',
  },
  '@keyframes animation': {
    from: {
      transform: 'scale(1)',
    },
    to: {
      transform: 'scale(2)',
    },
  },
}))
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
export default function AppLayout({ children, ...props }: any) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [sidebar, setSidebar] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const {
    isAuth,
    user,
    profile,
    loading,
    client,
    // mobileConfirmed,
    // hasConsent,
  } = useSelector(({ auth, client, loading }: RootState) => ({
    isAuth: auth.isAuth,
    user: auth.user as UserDto,
    client: client.selected as ClientDto,
    profile: auth.profile,
    loading: loading['GET_PATIENT_PROFILE'],
    //TODO consent + phone
    mobileConfirmed: true, //auth.user.isPhoneConfirmed,
    hasConsent: true, //auth.user.isConsent
  }))
  //   const fetched = true //TODO usereferentieluseReferentiels(isAuth)

  let logoutTimeout: NodeJS.Timeout
  const handleLogout = () => {
    resetTimeout()
    dispatch(logout())
  }

  const clearTimeOut = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  const setTimeOut = () => {
    logoutTimeout = setTimeout(() => setOpen(true), 5 * 60 * 1000)
  }

  const resetTimeout = () => {
    clearTimeOut()
    setTimeOut()
  }

  const handleStayLogged = () => {
    resetTimeout()
    setOpen(false)
  }

  useEffect(() => {
    for (const i in events) {
      window.addEventListener(events[i], resetTimeout)
    }
    setTimeOut()
    return () => {
      clearTimeOut()
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout)
      }
    }
  })

  useEffect(() => {
    //TODO error wih patient

    if (user && user.role !== 'super_admin') {
      dispatch(findClient({ id: user?.client }))
    }
  }, [dispatch, user])

  // console.log('User', user);
  // console.log('Fetching', fetching)
  // console.log('Profile:', profile);

  if (!isAuth)
    return (
      <Redirect
        to={{ pathname: '/auth/login', state: { from: props.location } }}
      />
    )

  if (loading || !profile)
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <Avatar src={Logo} alt="logo" className={classes.animatedLogo} />
      </Backdrop>
    )
  return (
    <>
      <Drawer
        toggleDrawer={setSidebar}
        open={sidebar}
        role={user.role as Role}
        client={client}
      />
      <Navbar
        user={profile}
        toggleDrawer={setSidebar}
        client={client}
        role={user.role as Role}
      />
      <main
        style={{
          flex: 1,
          paddingLeft: isMobileOnly ? '' : 65,
          paddingTop: 2.5,
        }}
      >
        {children}
      </main>
      <Footer />
      <AutoLogoutDialog
        open={open}
        onClose={() => setOpen(false)}
        handleStayLogged={handleStayLogged}
        handleLogout={handleLogout}
      />
      {/* {!isAdmin && !isSuperAdmin && mobileConfirmed && (
        <ConfirmMobileModal />
    )} */}
    </>
  )
}
