import { Box, TableCell, TableRow, Typography } from '@material-ui/core'

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export function DayScaleLayoutComponent({ children }: any) {
  return (
    <TableRow
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        //width: 400,
        padding: 2,
      }}
    >
      {children.map((value: any, i: number) => {
        const { startDate } = value.props
        const date = new Date(startDate)
        // console.log('xxxxxxxxxx', date.getDay());
        return (
          <TableCell
            key={i}
            className="ec-dayScal"
            style={{
              //  width: 55,
              // height: 40,
              //backgroundColor: '#f1f1f1',
              padding: 4,
              //margin: 4
              border: 'none',
              borderRadius: 8,

              width: 150,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" style={{ fontSize: 12 }}>
                {days[date.getDay()]}
              </Typography>
              <Typography
                variant="h3"
                style={{ fontSize: 22, fontWeight: 400, marginTop: 3 }}
              >
                {date.getDate()}
              </Typography>
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}
