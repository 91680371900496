import { all } from 'redux-saga/effects'

import authSagas from './authSaga'
import userSagas from './userSaga'
import superAdminSagas from './superAdminSaga'
import adminSagas from './adminSaga'
import clientSagas from './clientSaga'
import patientSagas from './patientSaga'
import doctorSagas from './doctorSaga'
import studySagas from './studySaga'
import siteSagas from './siteSaga'
import finessSagas from './finessSaga'
import rppsSagas from './rppsSaga'
import logSagas from './logSaga'

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...patientSagas,
    ...doctorSagas,
    ...superAdminSagas,
    ...adminSagas,
    ...studySagas,
    ...siteSagas,
    ...clientSagas,
    ...finessSagas,
    ...rppsSagas,
    ...userSagas,
    ...logSagas,
  ])
}
