import MUIRichTextEditor from 'mui-rte'
import { MuiThemeProvider, createTheme } from '@material-ui/core'

const defaultTheme = createTheme()

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 10,
        width: '100%',
        border: '1px solid #f1f1f1',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
      },

      editor: {
        padding: '20px',
        height: '100px',
        maxHeight: '200px',
        overflow: 'auto',
      },
      placeHolder: {
        paddingLeft: 20,
        width: 'inherit',
        position: 'static',
      },
    },
  },
})

export function TextEditor() {
  // console.log('Errors', errors, errors[input]);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <MUIRichTextEditor label="Type something here..." />
    </MuiThemeProvider>
  )
}
