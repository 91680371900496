import Box from '@material-ui/core/Box'
import BasicTable from '../BasicTable'

export default function UpdatedTypes() {
  return (
    <Box>
      <BasicTable />
    </Box>
  )
}
