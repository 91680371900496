export function getAuthToken() {
  return localStorage.getItem('authToken')
}

export function setAuthToken(token: string) {
  localStorage.setItem('authToken', token)
}

export function removeAuthToken() {
  console.log('REMOVE_AUTH_TOKEN')

  localStorage.removeItem('authToken')
}
