import { useState, useEffect } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import { Box, ListItemText, MenuItem, Typography } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      // margin: theme.spacing(3),
    },
  }),
)

export type Props = {
  options: { value: string | number; label: string }[]
  label: string
  onValueChange?: (a: string[]) => void
}

export function CheckBoxGroupInput({
  options,
  label,
  onValueChange,
  ...props
}: Props) {
  const classes = useStyles()
  const [option, setOption] = useState<string[]>([])

  useEffect(() => {
    if (onValueChange) {
      onValueChange(option)
    }
  }, [option, onValueChange])

  return (
    <div className={classes.root} {...props}>
      <FormControl className={classes.formControl}>
        <Typography className="title-check-group">{label}</Typography>
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
          {options.map((opt, i) => {
            const indexOf = option.indexOf(opt.label)
            return (
              <MenuItem
                key={i}
                value={opt.label}
                onClick={() => {
                  if (indexOf > -1) {
                    setOption([
                      ...option.slice(0, indexOf),
                      ...option.slice(indexOf + 1),
                    ])
                  } else {
                    setOption([...option, opt.label])
                  }
                }}
              >
                <Checkbox checked={indexOf > -1} />
                <ListItemText primary={opt.label} />
              </MenuItem>
            )
          })}
        </Box>
      </FormControl>
    </div>
  )
}
