import { useState } from 'react'

import { Box, Dialog, DialogContent, Paper, Tab, Tabs } from '@material-ui/core'
import { DialogTitle } from '@components/titles'
import { makeStyles } from '@material-ui/core/styles'

import UpdatedTypes from './UpdatedTypes'
import AllTypes from './AllTypes'

const useStyles = makeStyles({
  root: {
    // flexGrow: 1,
    width: 700,
  },
})

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

type TypeOfExaminationDialogProps = {
  onClose: () => void
  open: boolean
}

export function TypeOfExaminationDialog({
  onClose,
  open,
}: TypeOfExaminationDialogProps) {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle title="Type Of Examination" onClose={onClose} />
      <DialogContent>
        <Paper className={classes.root} variant="outlined">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="ALL EXAMS" />
            <Tab label="MY EXAMS" />
          </Tabs>
        </Paper>

        <TabPanel value={value} index={0}>
          <AllTypes />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UpdatedTypes />
        </TabPanel>
      </DialogContent>
    </Dialog>
  )
}
