import { useState, Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@material-ui/core'
import { throttle, upperFirst } from 'lodash'

import { autocompleteFiness } from '@state/actions/finessActions'
import { SiteDto } from '@services/api'
import { RootState } from '@state/reducers/root'

type AutocompleteDoctorInputProps = {
  onSelect: (site: { site: SiteDto }) => void
  site?: SiteDto
  autoRppsClass?: string
}
export function AutocompleteFinessInput({
  onSelect,
  site,
  autoRppsClass,
}: AutocompleteDoctorInputProps) {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState(
    `${site?.finessNumber} ${site?.name}`,
  )
  const { loading, options } = useSelector(({ site, loading }: RootState) => ({
    options: site.options,
    loading: loading['GET_AUTOCOMPLETE_FINESS'],
  }))

  console.log('get options', options)
  const fetch = useMemo(
    () =>
      throttle(({ inputValue }: { inputValue: string }) => {
        dispatch(autocompleteFiness({ finessNumber: inputValue }))
      }, 200),
    [dispatch, inputValue],
  )

  // console.log(doctor)
  useEffect(
    () => setInputValue(site ? `${site.finessNumber} ${site.name}` : ''),
    [site],
  )
  useEffect(() => {
    // console.log(inputValue)
    if (inputValue) {
      fetch({ inputValue })
    }
  }, [dispatch, fetch, inputValue])

  return (
    <Autocomplete
      className={autoRppsClass}
      fullWidth
      size="small"
      loading={loading}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${upperFirst(option.name)}`}
      onChange={(event, site) => {
        onSelect(site)
      }}
      onInputChange={(event, newInputValue) => {
        // console.log('NewInputValue', newInputValue)
        setInputValue(newInputValue)
      }}
      noOptionsText={'Search a hospital (Finess)'}
      // inputValue={inputValue}
      options={options}
      filterOptions={(options) => options}
      renderOption={(option) => (
        <Box className="ec-box-auto" style={{ fontSize: '.85rem', padding: 2 }}>
          <Box color="text.secondary">{option.finessNumber}</Box>{' '}
          {upperFirst(option.name)}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search a hospital (Finess)"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
