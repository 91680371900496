import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

// const RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const signFailSchema = Yup.object().shape({
  failure: Yup.number().required('validation.field.required'),
  period: Yup.number().required('validation.field.required'),
  window: Yup.number().required('validation.field.required'),
  timeout: Yup.number().required('validation.field.required'),
})

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  password: Yup.string().required('validation.password.required'),
  captcha: Yup.string(),
  confirmCaptcha: Yup.string()
    .required('validation.captcha.required')
    .oneOf([Yup.ref('captcha'), null], 'validation.captcha.failed'),
})

export const firstconnexionSchema = Yup.object().shape({
  studyId: Yup.string().required('examen-id.required'),
  birthDate: Yup.date().required('birthdate.required'),
  captcha: Yup.string(),
  confirmCaptcha: Yup.string()
    .required('validation.captcha.required')
    .oneOf([Yup.ref('captcha'), null], 'validation.captcha.failed'),
})

export const forgottenPassowrdSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})
// export const registerSchema = Yup.object().shape({
//   email: Yup.string().required('email.required').email('email.invalid'),
//   phoneNumber: Yup.string()
//     .required('register.msg.error.phone.required')
//     .test('isPhone', 'register.msg.error.phone.format', (v) =>
//       isValidPhoneNumber(v)
//     ),
// })

export const registerAdmin1Schema = Yup.object().shape({
  email: Yup.string().required('email.required'),
  finess: Yup.string().required('finess.required'),
  // mobile: Yup.string()
  //   .required('register.msg.error.phone.required')
  //   .test('isPhone', 'register.msg.error.phone.format', (v) =>
  //     isValidPhoneNumber(v)
  //   ),
})

export const registerAdminSchema = Yup.object().shape({
  firstname: Yup.string().required('firstname.required'),
  lastname: Yup.string().required('lastname.required'),
  // phone: Yup.string()
  //   .required('register.msg.error.phone.required')
  //   .test('isPhone', 'register.msg.error.phone.format', (v) =>
  //     isValidPhoneNumber(v)
  //   ),
  // mobile: Yup.string()
  //   .required('register.msg.error.phone.required')
  //   .test('isPhone', 'register.msg.error.phone.format', (v) =>
  //     isValidPhoneNumber(v)
  //   ),
  password: Yup.string().required('password.required'),
})

export const forgotpasswordSchema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
})

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const verifyEmailSchema = Yup.object().shape({
  password: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const updateEmailSchema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
})

export const createDoctorSchema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
  phoneNumber: Yup.string()
    .test('isPhone', 'register.msg.error.phone.format', (v) =>
      isValidPhoneNumber(v),
    )
    .required('register.msg.error.phone.required'),
})

export const phoneCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required('validation.field.required')
    .length(6)
    .matches(/^[0-9]{6}$/, 'validation.code.match'),
})

export const addClientSchema = Yup.object().shape({
  name: Yup.string().required('validation.field.required'),
  status: Yup.string().required('validation.field.required'),
  siretNumber: Yup.string()
    .required('validation.field.required')
    .length(9)
    .matches(/^[0-9]{9}$/, 'validation.code.codeEin'),
  // address: Yup.string().required('validation.field.required'),
  streetAddress: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  phoneNumber: Yup.string()
    .required('validation.field.required')
    .min(14, 'phone number is not valid'),
  fax: Yup.string(),
  website: Yup.string(),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const addStaffSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  phoneNumber: Yup.string()
    .required('validation.field.required')
    .min(14, 'phone number is not valid'),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const addAdminSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  client: Yup.string().required('validation.field.required'),
  phoneNumber: Yup.string()
    .required('validation.field.required')
    .min(14, 'phone number is not valid'),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const siteSchema = Yup.object().shape({
  name: Yup.string().required('validation.field.required'),
  // finessNumber: Yup.string().required('validation.field.required'),
  siteStatus: Yup.string().required('validation.field.required'),
  // address: Yup.string().required('validation.field.required'),
  streetAddress: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  phoneNumber: Yup.string().required('validation.field.required'),
  fax: Yup.string(),
  website: Yup.string(),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const doctorSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('validation.field.required'),
    lastName: Yup.string().required('validation.field.required'),
    rppsNumber: Yup.string().required('validation.field.required'),
    skill: Yup.string(),
    profession: Yup.string().required('validation.field.required'),
    category: Yup.string().required('validation.field.required'),
    country: Yup.string().required('validation.field.required'),
    email: Yup.string()
      .email('validation.email.invalid')
      // .required('validation.field.required'),
      .when('emailPro', {
        is: (emailPro: string) => !emailPro || emailPro.length === 0,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    emailPro: Yup.string()
      .email('validation.email.invalid')
      .when('email', {
        is: (email: string) => !email || email.length === 0,
        then: Yup.string().required('validation.field.required'),
        otherwise: Yup.string(),
      }),
  },
  [['email', 'emailPro']],
)

export const itEquipmentSchema = Yup.object().shape({
  site: Yup.string().required('validation.field.required'),
  type: Yup.string().required('validation.field.required'),
  ip: Yup.string().required('validation.field.required'),
  port: Yup.string().required('validation.field.required'),
})

export const updateDoctorSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('validation.field.required'),
    lastName: Yup.string().required('validation.field.required'),
    rppsNumber: Yup.string().required('validation.field.required'),
    city: Yup.string().required('validation.field.required'),
    skill: Yup.string(),
    profession: Yup.string(),
    category: Yup.string().required('validation.field.required'),
    country: Yup.string().required('validation.field.required'),
    website: Yup.string(),
    fax: Yup.string(),
    phoneNumber: Yup.string()
      .required('validation.field.required')
      .min(14, 'phone number is not valid'),

    // phoneNumber: Yup.string().required('validation.field.required')
    // .length(9)
    // .matches(/^[0-9]{9}$/, 'validation.code.codeEin'),

    email: Yup.string()
      .email('validation.email.invalid')
      //.required('validation.field.required'),
      .when('emailPro', {
        is: (emailPro: string) => !emailPro || emailPro.length === 0,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    emailPro: Yup.string()
      .email('validation.email.invalid')
      .when('email', {
        is: (email: string) => !email || email.length === 0,
        then: Yup.string().required('validation.field.required'),
        otherwise: Yup.string(),
      }),
  },
  [['email', 'emailPro']],
)

export const updateGeneralInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  sex: Yup.string().required('validation.field.required'),
  street: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  birthPlace: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  ssn: Yup.string().required('validation.field.required'),
})

export const addRoomSchema = Yup.object().shape({
  name: Yup.string().required('validation.field.required'),
  openingTime: Yup.date().required('validation.field.required'),
  recoveryTime: Yup.date().required('validation.field.required'),
  breakTime: Yup.date().required('validation.field.required'),
  closingTime: Yup.date().required('validation.field.required'),
  color: Yup.string().required('validation.field.required'),
})

export const pidSchema = Yup.object().shape({
  PID1: Yup.string().required('validation.field.required'),
  PID2: Yup.string().required('validation.field.required'),
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  motherMaidenName: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  sex: Yup.string().required('validation.field.required'),
  streetAddress: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  countryCode: Yup.string().required('validation.field.required'),
  // phoneNumber: Yup.string()
  //   .test('isPhone', 'register.msg.error.phone.format', (v) =>
  //     isValidPhoneNumber(v),
  //   )
  //   .required('register.msg.error.phone.required'),
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  accountNumber: Yup.string().required('validation.field.required'),
  systemName: Yup.string().required('validation.field.required'),
  ssn: Yup.string().required('validation.field.required'),
})

export const pv1Schema = Yup.object().shape({
  attendingdoctorId: Yup.string().required('validation.field.required'),
  attendingDoctorFirstName: Yup.string().required('validation.field.required'),
  attendingDoctorLastName: Yup.string().required('validation.field.required'),
  attendingDoctorPrefix: Yup.string().required('validation.field.required'),
  attendingdoctorIdType: Yup.string().required('validation.field.required'),
  referringdoctorId: Yup.string().required('validation.field.required'),
  referringDoctorFirstName: Yup.string().required('validation.field.required'),
  referringDoctorLastName: Yup.string().required('validation.field.required'),
  referringDoctorPrefix: Yup.string().required('validation.field.required'),
  referringdoctorIdType: Yup.string().required('validation.field.required'),
})

export const orcSchema = Yup.object().shape({
  placeOrderNumber: Yup.string().required('validation.field.required'),
  fillerOrderNumber: Yup.string().required('validation.field.required'),
  orderStatus: Yup.string().required('validation.field.required'),
  quantity: Yup.string().required('validation.field.required'),
  interval: Yup.string().required('validation.field.required'),
  priority: Yup.string().required('validation.field.required'),
  startDateTime: Yup.date().required('validation.field.required'),
  attendingdoctorId: Yup.string().required('validation.field.required'),
  attendingDoctorFirstName: Yup.string().required('validation.field.required'),
  attendingDoctorLastName: Yup.string().required('validation.field.required'),
  attendingDoctorPrefix: Yup.string().required('validation.field.required'),
  attendingdoctorIdType: Yup.string().required('validation.field.required'),
})

export const obrSchema = Yup.object().shape({
  OBRID: Yup.string().required('validation.field.required'),
  entityIdentifier: Yup.string().required('validation.field.required'),
  systemName: Yup.string().required('validation.field.required'),
  accessionNumber: Yup.string().required('validation.field.required'),
  studyCode: Yup.string().required('validation.field.required'),
  studyTitle: Yup.string().required('validation.field.required'),
  plannedDate: Yup.date().required('validation.field.required'),
  attendingdoctorId: Yup.string().required('validation.field.required'),
  attendingDoctorFirstName: Yup.string().required('validation.field.required'),
  attendingDoctorLastName: Yup.string().required('validation.field.required'),
  attendingDoctorPrefix: Yup.string().required('validation.field.required'),
  attendingdoctorIdType: Yup.string().required('validation.field.required'),
  modality: Yup.string().required('validation.field.required'),
  quantity: Yup.string().required('validation.field.required'),
  interval: Yup.string().required('validation.field.required'),
  priority: Yup.string().required('validation.field.required'),
  pointOfCare: Yup.string().required('validation.field.required'),
  room: Yup.string().required('validation.field.required'),
})

export const obxSchema = Yup.object().shape({
  OBXID: Yup.string().required('validation.field.required'),
  systemName: Yup.string().required('validation.field.required'),
  DOC: Yup.string().required('validation.field.required'),
  JPEG: Yup.string().required('validation.field.required'),
  Base64: Yup.string().required('validation.field.required'),
  PDFBase64: Yup.string().required('validation.field.required'),
})

export const zdsSchema = Yup.object().shape({
  studyInstanceUID: Yup.string().required('validation.field.required'),
  ApplicationID: Yup.string().required('validation.field.required'),
  Application: Yup.string().required('validation.field.required'),
  DICOM: Yup.string().required('validation.field.required'),
})
