import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSnackbar } from 'notistack'
// import { removeSnackbar } from '@state/actions/alert'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import { AlertActions } from '@state/actions'
import { RootState } from '@state/reducers/root'

let displayed: number[] = []

export default function Notifier() {
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const notifications = useSelector(
    ({ alert }: RootState) => alert.notifications,
  )

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id]
  }
  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }
  const removeSnackbar = (key: number) => ({
    type: AlertActions.REMOVE_SNACKBAR,
    payload: key,
  })

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key)
          return
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          action: (key: number) => (
            <IconButton aria-label="delete" onClick={() => closeSnackbar(key)}>
              <Delete />
            </IconButton>
          ),
          ...options,
          // onClose: (event, reason, myKey) => {
          //     if (options.onClose) {
          //         options.onClose(event, reason, myKey);
          //     }
          // },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey as number))
            removeDisplayed(myKey as number)
          },
        })

        // keep track of snackbars that we've displayed
        storeDisplayed(key)
      },
    )
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}
