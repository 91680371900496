import { forwardRef, useImperativeHandle, useState } from 'react'

import { Control, DeepMap, FieldError, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@material-ui/core'
import { DateInput, EcSwitchInput, SelectInput } from '@components/inputs'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

import { TimeInput } from '../../inputs/TimeInput'
import { EcSpaceHorizontal, EcSpaceVertical } from '@utils/Spacing'
import { DoctorDto } from '@services/api'
import { addClientSchema } from '@utils/schemas'

type Values = {
  dataAppointment: Date
  startDate: string
  endDate: string
  salle: string
  room: string
  doctor: string
  preferedDoctor: string
  type: string
  study: string
  emergency: string
  hospitalized?: string
  referringDoctor?: string
  comments: string
  waitingList: string
}

export const AvailableAppointmentForm = forwardRef((_, ref) => {
  const [doctor] = useState<DoctorDto>()

  const [numberExamSelected, setNumberExamSelected] = useState('1')

  const { handleSubmit, errors, control, watch } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {
      room: '',
    },
  })
  console.log(doctor)
  console.log(watch())
  console.log(errors)

  const onSubmit = () => {
    // const newClient: ClientDto = {
    //   name: values.startDate,
    //   siretNumber: values.salle.substring(0, values.salle.length - 5),
    //   sirenNumber: values.salle,
    //   companyStatus: values.room,
    //   streetAddress: values.doctor,
    //   postalCode: values.preferedDoctor,
    //   city: values.type,
    //   state: values.type,
    //   country: values.study,
    //   email: values.comments,
    //   phoneNumber: values.emergency,
    //   //fax: values.hospitalized,
    //  // sites: [],
    // }
    // dispatch(addClient({ body: newClient }))
  }
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <Grid container spacing={4}>
      {/* 
      <Grid item xs={12}>
        <SelectInput
          options={[{ value: '1', label: 'Room1' }, { value: '2', label: 'Room2' }]}
          control={control}
          input="room"
          errors={errors}
        />{' '}
      </Grid> */}

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Typography variant="h5" style={{ width: 300, fontSize: 19 }}>
            Number Examen
          </Typography>
          <Box>
            <RadioButtonsGroup
              oneSelected={(value) => {
                setNumberExamSelected(value)
              }}
            />
          </Box>
        </Box>
      </Grid>

      {numberExamSelected === '1' && (
        <OneExam control={control} errors={errors} />
      )}
      {numberExamSelected === '2' && (
        <TwoExam control={control} errors={errors} />
      )}
      {numberExamSelected === '3' && (
        <MultiExam control={control} errors={errors} />
      )}
    </Grid>
  )
})

type ExamProps = {
  control: Control<any>
  errors?: DeepMap<any, FieldError>
}

function OneExam({ control, errors }: ExamProps) {
  return (
    <>
      <Grid item xs={12}>
        <OneRow label="Apartir du">
          <DateInput input="startTime" control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="jusqu'a">
          <SelectInput
            input="jusqu"
            options={[]}
            control={control}
            errors={errors}
          />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Plage Horaire">
          <TimeInput input="startTime" control={control} />
          <EcSpaceHorizontal size={20} />
          <TimeInput input="startTime" control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Jour de la semaine">
          <SelectInput input="jourSemaine" options={[]} control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Salles">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="room"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Type d'examen*">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="typeExam"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>
    </>
  )
}

function TwoExam({ control, errors }: ExamProps) {
  return (
    <>
      <Grid item xs={12}>
        <OneRow label="Apartir du">
          <DateInput input="startTime" control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="jusqu'a">
          <SelectInput
            input="jusqu"
            options={[]}
            control={control}
            errors={errors}
          />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Plage Horaire">
          <TimeInput input="startTime" control={control} />
          <EcSpaceHorizontal size={20} />
          <TimeInput input="startTime" control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Jour de la semaine">
          <SelectInput input="jourSemaine" options={[]} control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Salles">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="room"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Type d'examen*">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="typeExam"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <BlockTwoExam title="- RDV #1">
          <OneRow label="Type d'examen*">
            <SelectInput
              options={[
                { value: '1', label: 'Room1' },
                { value: '2', label: 'Room2' },
              ]}
              control={control}
              input="typeExam"
              errors={errors}
            />{' '}
          </OneRow>
          <EcSpaceVertical size={20} />
          <OneRow label="Lieu">
            <SelectInput
              options={[
                { value: '1', label: 'Room1' },
                { value: '2', label: 'Room2' },
              ]}
              control={control}
              input="lieu"
              errors={errors}
            />{' '}
          </OneRow>
          <EcSpaceVertical size={20} />
          <OneRow label="Praticien">
            <SelectInput
              options={[
                { value: '1', label: 'Room1' },
                { value: '2', label: 'Room2' },
              ]}
              control={control}
              input="praticien"
              errors={errors}
            />{' '}
          </OneRow>
        </BlockTwoExam>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Delai Min">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="typeExam"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Delai Max">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="typeExam"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <BlockTwoExam title="- RDV #2">
          <OneRow label="Type d'examen*">
            <SelectInput
              options={[
                { value: '1', label: 'Room1' },
                { value: '2', label: 'Room2' },
              ]}
              control={control}
              input="typeExam"
              errors={errors}
            />{' '}
          </OneRow>
          <EcSpaceVertical size={20} />
          <OneRow label="Lieu">
            <SelectInput
              options={[
                { value: '1', label: 'Room1' },
                { value: '2', label: 'Room2' },
              ]}
              control={control}
              input="lieu"
              errors={errors}
            />{' '}
          </OneRow>
          <EcSpaceVertical size={20} />
          <OneRow label="Praticien">
            <SelectInput
              options={[
                { value: '1', label: 'Room1' },
                { value: '2', label: 'Room2' },
              ]}
              control={control}
              input="praticien"
              errors={errors}
            />{' '}
          </OneRow>
        </BlockTwoExam>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="">
          <EcSwitchInput control={control} input="Meme lieu" />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Meme praticien">
          <RadioGroup
            row
            aria-label="gender"
            name="gender1"
            value={'1'}
            onChange={() => {}}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Indifferent"
            />
            {/* <EcSpaceHorizontal size={70} /> */}
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="Yes"
            />
            {/* <EcSpaceHorizontal size={70} /> */}
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </OneRow>
      </Grid>
    </>
  )
}

type BlockTwoExamProps = {
  children?: React.ReactNode
  title: string
}

function BlockTwoExam({ children, title }: BlockTwoExamProps) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" style={{ width: 300, fontSize: 21 }}>
        {title}
      </Typography>
      <EcSpaceVertical size={20} />
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  )
}

function MultiExam({ control, errors }: ExamProps) {
  return (
    <>
      <Grid item xs={12}>
        <OneRow label="Apartir du">
          <DateInput input="startTime" control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="jusqu'a">
          <SelectInput
            input="jusqu"
            options={[]}
            control={control}
            errors={errors}
          />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Plage Horaire">
          <TimeInput input="startTime" control={control} />
          <EcSpaceHorizontal size={20} />
          <TimeInput input="startTime" control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Jour de la semaine">
          <SelectInput input="jourSemaine" options={[]} control={control} />
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Salles">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="room"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Type d'examen*">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="typeExam"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Lieu">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="Lieu"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>

      <Grid item xs={12}>
        <OneRow label="Praticien">
          <SelectInput
            options={[
              { value: '1', label: 'Room1' },
              { value: '2', label: 'Room2' },
            ]}
            control={control}
            input="Praticien"
            errors={errors}
          />{' '}
        </OneRow>
      </Grid>
    </>
  )
}

function RadioButtonsGroup({
  oneSelected,
}: {
  oneSelected: (value: string) => void
}) {
  const [value, setValue] = useState('1')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    oneSelected((event.target as HTMLInputElement).value)
  }

  return (
    <RadioGroup
      row
      aria-label="gender"
      name="gender1"
      value={value}
      onChange={handleChange}
    >
      <FormControlLabel
        value="1"
        control={<Radio color="primary" />}
        label="1 Exam"
      />
      <EcSpaceHorizontal size={70} />
      <FormControlLabel
        value="2"
        control={<Radio color="primary" />}
        label="2 Exam"
      />
      <EcSpaceHorizontal size={70} />
      <FormControlLabel
        value="3"
        control={<Radio color="primary" />}
        label="Multiple exam"
      />
    </RadioGroup>
  )
}

type OneRowProps = {
  children?: React.ReactNode
  label: string
}

function OneRow({ children, label }: OneRowProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <Typography variant="h5" style={{ width: 300, fontSize: 19 }}>
        {label}
      </Typography>
      <Box width={475} display="flex">
        {children}
      </Box>
    </Box>
  )
}
