import Box from '@material-ui/core/Box'
import { EcSelectTextField, TextInput, AutocompleteInput } from '../inputs'
import { defaultSelectList, medicalEquipmentType } from '@utils/constants'
import {
  Typography,
  MenuItem,
  TextField,
  alpha,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import { EcSpaceHorizontal, EcSpaceVertical } from '@utils/Spacing'
import { Button } from '../buttons'
import { roomsInit } from './params'
import { useState } from 'react'

export default function AllTypes() {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <EcSelectTextField input="equipment" options={medicalEquipmentType} />
        <EcSpaceHorizontal size={15} />
        <AutocompleteInput input="cool" options={defaultSelectList} />
        <EcSpaceHorizontal size={15} />
        <TextInput input="cool" />
      </Box>
      <EcSpaceVertical size={20} />
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" style={{ width: 400, fontSize: 17 }}>
          Set Time Tnterval
        </Typography>
        <EcSpaceVertical size={5} />
        <Box display="flex" alignItems="center" width="65%">
          <EcSelectTextField
            input="selectTimeInterval"
            options={[
              { value: 1, label: '10 min' },
              { value: 1, label: '15 min' },
              { value: 1, label: '20 min' },
              { value: 1, label: '25 min' },
            ]}
          />
          <Typography style={{ margin: '0 8px', color: '#666' }}>OR</Typography>
          <TextInput input="enterTimeInterval" />
        </Box>
      </Box>

      <EcSpaceVertical size={20} />

      <Box display="flex" flexDirection="column">
        <Typography variant="h6" style={{ width: 400, fontSize: 17 }}>
          Select Rooms
        </Typography>
        <EcSpaceVertical size={5} />
        <Box display="flex" alignItems="center" width="65%">
          <MultiSelectRoomWithIndicator />
        </Box>
      </Box>

      <EcSpaceVertical size={40} />

      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          textColor="primary"
          color="primary"
          text="ADD TO MY LIST"
          size="large"
          startIcon={<AddCircleOutlineOutlinedIcon fontSize="large" />}
          style={{ width: '40%' }}
          //onClick={() => { setOpenDialog(true) }}
        />
      </Box>
    </Box>
  )
}

export const examTypeAll = [
  { equipment: 'Scanner', exam: 'ColosScanner', updated: false },
  { equipment: 'Otoscopes', exam: 'ORL', updated: true },
  { equipment: 'Equipment X', exam: 'Infitaion', updated: false },
  { equipment: 'Equipment Y', exam: 'exam X', updated: true },
  { equipment: 'Equipment Z', exam: 'exam X', updated: false },
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MultiSelectRoomWithIndicator = () => {
  const [option, setOption] = useState<string[]>([])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOption(event.target.value as string[])
  }

  return (
    <TextField
      style={{ borderRadius: 5 }}
      fullWidth
      variant="outlined"
      size="small"
      InputLabelProps={{
        style: {
          color: '#464855',
        },
      }}
      InputProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
      select
      SelectProps={{
        multiple: true,
        value: option,
        MenuProps: MenuProps,
        onChange: handleChange,
        //onClose: handleClose,
        renderValue: (selected) => (selected as string[]).join(', '),
      }}
    >
      {roomsInit.map((opt, i) => (
        <MenuItem
          key={i}
          value={opt.text}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <span
            style={{
              backgroundColor: alpha(opt.color[400], 1),
              borderRadius: '50%',
              width: 15,
              height: 15,
              marginRight: 10,
              display: 'inline-block',
            }}
          />
          <ListItemText primary={opt.text} />
          <Checkbox checked={option.indexOf(opt.text) > -1} />
        </MenuItem>
      ))}
    </TextField>
  )
}
