import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@material-ui/core'
import { CheckBoxGroupInput, SwitchInput } from '@components/inputs'

import {
  CT_SPECIALISTES,
  MRI_SPECIALISTES,
  typeImagingDoctor,
} from '@utils/constants'
import { EcSpaceHorizontal, EcSpaceVertical } from '@utils/Spacing'
import { addClientSchema } from '@utils/schemas'

import { CommonEnrollment, NumberStudies } from './CommonEnrollment'

type Values = {
  name: string
  einNumber: string
  companyStatus: string
  streetAddress: string
  postalCode: string
  city: string
  country: string
  phoneNumber: string
  fax: string
  website?: string
  email: string
}

export function HospitalCompleteInfos() {
  const [typeImagingSelected, setTypeImagingSelected] = useState([''])

  const { errors } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {
      companyStatus: '',
    },
  })
  console.log(errors)

  return (
    <CommonEnrollment>
      {/* #c1 */}
      <Grid item xs={12}>
        <CheckBoxGroupInput
          label="Type Imaging"
          options={typeImagingDoctor}
          onValueChange={(_typeImagingSelected) => {
            setTypeImagingSelected(_typeImagingSelected)
          }}
        />
        {(typeImagingSelected.indexOf('Conventional Radiology') > -1 ||
          typeImagingSelected.indexOf('CT') > -1 ||
          typeImagingSelected.indexOf('MR') > -1) && (
          <div>
            <Typography style={{ fontWeight: 400, fontSize: 15 }}>
              Number of Studies
            </Typography>
            <EcSpaceVertical size={5} />
            <div style={{ display: 'flex', paddingLeft: 25 }}>
              {typeImagingSelected.indexOf('Conventional Radiology') > -1 && (
                <>
                  <NumberStudies
                    label="Conventional Radiology"
                    _min={20}
                    _max={70}
                  />
                  <EcSpaceHorizontal size={30} />
                </>
              )}
              {typeImagingSelected.indexOf('CT') > -1 && (
                <>
                  <NumberStudies
                    label="CT number of studies"
                    _min={10}
                    _max={30}
                  />
                  <EcSpaceHorizontal size={30} />
                </>
              )}
              {typeImagingSelected.indexOf('MR') > -1 && (
                <NumberStudies
                  label="MR number of studies"
                  _min={10}
                  _max={30}
                />
              )}
            </div>
          </div>
        )}
      </Grid>

      {typeImagingSelected.indexOf('CT') > -1 && (
        <Grid item xs={12}>
          <CheckBoxGroupInput
            label="CT Specialities"
            options={CT_SPECIALISTES}
            onValueChange={() => {
            }}
          />
          <div>
            <Typography style={{ fontWeight: 400, fontSize: 15 }}>
              Number of Studies CT Specialities
            </Typography>
            <EcSpaceVertical size={5} />
            <div style={{ display: 'flex', paddingLeft: 25 }}>
              <NumberStudies label="CT" _min={10} _max={25} />
            </div>
          </div>
        </Grid>
      )}

      {typeImagingSelected.indexOf('MR') > -1 && (
        <Grid item xs={12}>
          <CheckBoxGroupInput
            label="MR Specialities"
            options={MRI_SPECIALISTES}
            onValueChange={() => {
            }}
          />

          <div>
            <Typography style={{ fontWeight: 400, fontSize: 15 }}>
              Number of Studies MR Specialities
            </Typography>
            <EcSpaceVertical size={5} />
            <div style={{ display: 'flex', paddingLeft: 25 }}>
              <NumberStudies label="MR" _min={10} _max={25} />
            </div>
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        <SwitchInput label="Available for Emergency / On Call / Night Session" />
      </Grid>
    </CommonEnrollment>
  )
}
