import {
  DialogTitle as DialogTitleBase,
  DialogTitleProps,
  IconButton,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import Text from '../Text'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    height: 30,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))



type CustomDialogTitleProps = DialogTitleProps & {
  title: string
  onClose: () => void
  format?: boolean
}

export function DialogTitle({
  title,
  onClose,
  format = false,
  ...other
}: CustomDialogTitleProps) {
  const classes = useStyles()
  return (
    <DialogTitleBase disableTypography className={classes.root} {...other}>
      <Text variant="h6" text={title} format={format} />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes?.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitleBase>
  )
}
