import { MenuItem } from '@material-ui/core'

import { TextInput } from './TextInput'

export interface Option {
  value: string | number
  label: string
}

export type EcSelectTextFieldProps = {
  options: Option[]
  input: string
  defaultValue?: string | number
  onChange?: (e: any) => void
}

export function EcSelectTextField({
  options,
  input,
  defaultValue = '',
  onChange,
  ...props
}: EcSelectTextFieldProps) {
  return (
    <TextInput
      {...props}
      style={{ borderRadius: 5 }}
      fullWidth
      variant="outlined"
      size="small"
      InputLabelProps={{
        style: {
          color: '#464855',
        },
      }}
      InputProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
      input={input}
      select
      // value={value}
      // onChange={onChange}
      SelectProps={{
        defaultValue: defaultValue,
        onChange: onChange,
      }}
    >
      {options.map((option, index: number) => (
        <MenuItem key={index} value={option.value}>
          <em>{option.label}</em>
        </MenuItem>
      ))}
    </TextInput>
  )
}
