import { Link } from 'react-router-dom'

import { Container, Divider, Grid } from '@material-ui/core'

import Text from './Text'

export default function Footer() {
  return (
    <Container maxWidth="sm" component="footer" className="ec-footer">
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ height: '5vh', padding: 10 }}
      >
        <Link to="/privacy-policy">
          <Text text="privacy.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="copyright.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="about.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/terms">
          <Text text="terms.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="contact-us.title" align="center" format />
        </Link>
      </Grid>
    </Container>
  )
}
