import { useSelector } from 'react-redux'

import {
  DateNavigator,
  Scheduler,
  Toolbar,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui'
import { EditingState, ViewState } from '@devexpress/dx-react-scheduler'
import { Paper, Typography, makeStyles } from '@material-ui/core'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

import { RootState } from '@state/reducers/root'

import { TimeTableLayoutComponent } from './TimeTableLayoutComponent'
import { DayScaleLayoutComponent } from './DayScaleLayoutComponent'

const useStyles = makeStyles(() => ({
  availableAppointmentSchedule: {
    // "& div div[class*='Toolbar-toolbar']": {
    //   borderBottom: 'none',
    //   padding: '10px 50px',

    //   // backgroundColor:'red',

    //   '& div': {
    //     width: '100%',
    //     display: 'flex',
    //     alignItems: 'center',

    //     "& button[class*='OpenButton']": {
    //       marginLeft: 'auto',
    //     },

    //     '& button': {
    //       paddingLeft: 0,
    //     },
    //   },
    // },

    '& div div[class*=\'ordinaryLeftPanelBorder\']': {
      border: 'none',
    },

    // '& div': {
    //   '&:last-child': {
    //     overflowX: 'hidden',
    //   },
    // },

    // "& div div[class*='ordinaryHeaderBorder']": {
    //   border: 'none',
    // },

    '& div div div[class*=\'dayScaleEmptyCell\']': {
      display: 'none',
    },

    // '& td.ec-dayScal': {
    //   backgroundColor: alpha(theme.palette.primary.main, 1),
    //   color: 'white',
    // },

    '& td.ec-timeScal': {
      //backgroundColor: alpha("#1D8EBF", .2),
      //color: theme.palette.primary.main,
    },
  },

  flexibleSpace: {
    flex: 'none',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const FlexibleSpace = ({ ...restProps }) => {
  const classes = useStyles()

  return (
    <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
      <div className={classes.flexContainer}>
        <EventAvailableIcon fontSize="large" color="primary" />
        <Typography variant="h5" style={{ marginLeft: '10px' }}>
          Availability
        </Typography>
      </div>
    </Toolbar.FlexibleSpace>
  )
}

export function AvailableAppointment() {
  const classes = useStyles()

  const defaultCurrentDate = '2018-06-27'

  const { data, hours } = useSelector(
    ({ appointement }: RootState) => appointement,
  )

  const datas = data

  const dataAvailable = data.filter((dataItem) => {
    return dataItem.taken === true
  })

  const { startHour, endHour } = hours

  return (
    <Paper
      className={classes.availableAppointmentSchedule}
      variant="outlined"
      style={{ padding: 10, borderRadius: 8 }}
    >
      <Scheduler data={datas} height={700}>
        <ViewState
          defaultCurrentViewName="Week"
          defaultCurrentDate={defaultCurrentDate}
        />

        <EditingState
          onCommitChanges={() => {}}
          addedAppointment={() => {}}
          onAddedAppointmentChange={() => {}}
          //appointmentChanges={()=>{}}
          onAppointmentChangesChange={() => {}}
          editingAppointment={() => {}}
          onEditingAppointmentChange={() => {}}
        />

        {/* <DayView
          startDayHour={startHour}
          endDayHour={endHour}
          cellDuration={cellDuration}
          timeTableCellComponent={TimeTableCell}
        /> */}

        <WeekView
          startDayHour={startHour}
          endDayHour={endHour}
          cellDuration={30}
          excludedDays={[0, 6]}
          dayScaleRowComponent={DayScaleLayoutComponent}
          //timeTableRowComponent={TimeTableLayoutComponent}

          timeTableRowComponent={(props: any) =>
            TimeTableLayoutComponent({
              dataAvailable,
              ...props,
            })
          }
          // timeTableCellComponent={TimeTableCell}

          timeScaleLayoutComponent={() => {
            return <></>
          }}
        />
        {/* <Appointments appointmentContentComponent={DisplayOneAppointment} /> */}

        <Toolbar
          flexibleSpaceComponent={(props: any) =>
            FlexibleSpace({
              ...props,
            })
          }
        />

        <DateNavigator />
        {/* <Resources data={resources} /> */}
      </Scheduler>
    </Paper>
  )
}
