import { useState, Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@material-ui/core'
import { throttle } from 'lodash'

import { autocompleteRpps } from '@state/actions/rppsActions'
import { DoctorDto } from '@services/api'
import { RootState } from '@state/reducers/root'

type AutocompleteDoctorInputProps = {
  onSelect: (doctor: { doctor: DoctorDto }) => void
  doctor?: DoctorDto
  autoRppsClass?: string
}

export function AutocompleteRppsInput({
  onSelect,
  doctor,
  autoRppsClass,
}: AutocompleteDoctorInputProps) {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState(
    `${doctor?.lastName} ${doctor?.firstName}`,
  )
  const { loading, options } = useSelector(
    ({ doctor, loading }: RootState) => ({
      options: doctor.options,
      loading: loading['GET_AUTOCOMPLETE_RPPS'],
    }),
  )

  console.log('get options', options)
  const fetch = useMemo(
    () =>
      throttle(({ inputValue }: { inputValue: string }) => {
        dispatch(autocompleteRpps({ rppsNumber: inputValue }))
      }, 200),
    [dispatch, inputValue],
  )

  // console.log(doctor)
  useEffect(
    () => setInputValue(doctor ? `${doctor.lastName} ${doctor.firstName}` : ''),
    [doctor],
  )
  useEffect(() => {
    // console.log(inputValue)
    if (inputValue) {
      fetch({ inputValue })
    }
  }, [dispatch, fetch, inputValue])

  return (
    <Autocomplete
      className={autoRppsClass}
      fullWidth
      size="small"
      loading={loading}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.fullName}`}
      onChange={(event, doctor) => {
        onSelect(doctor)
      }}
      onInputChange={(event, newInputValue) => {
        // console.log('NewInputValue', newInputValue)
        setInputValue(newInputValue)
      }}
      noOptionsText={'Search a doctor (RPPS)'}
      // inputValue={inputValue}
      options={options}
      filterOptions={(options) => options}
      renderOption={(option) => (
        <Box className="ec-box-auto" style={{ fontSize: '.85rem', padding: 2 }}>
          <Box color="text.secondary">{option.rppsNumber}</Box>{' '}
          {option.fullName}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search a doctor (RPPS)"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
