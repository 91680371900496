import {
  Button as ButtonBase,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core'

import history from '@services/history'
import Text from '../Text'

type Props = {
  text: string
  loading?: boolean
  link?: string
  textColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'initial'
    | 'error'
  format?: boolean
} & ButtonProps

export function Button({
  text,
  loading,
  link,
  textColor = 'textSecondary',
  format = false,
  ...props
}: Props) {
  return (
    <ButtonBase
      {...props}
      variant={props.variant ? props.variant : !link ? 'contained' : undefined}
      disabled={props.disabled || loading}
      startIcon={loading ? <CircularProgress size={20} /> : props.startIcon}
      onClick={link ? () => history.push(link) : props.onClick}
    >
      <Text text={text} color={textColor} format={format} />
    </ButtonBase>
  )
}
