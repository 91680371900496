import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

import MuiPhoneNumber from 'material-ui-phone-number'
import { TextFieldProps } from '@material-ui/core'

type PhoneInputProps = TextFieldProps & {
  name: string
  control: Control<any>
  errors?: DeepMap<any, FieldError>
  readOnly?: boolean
}

export function PhoneInput2({
  name,
  control,
  errors,
  readOnly,
}: PhoneInputProps) {
  const intl = useIntl()
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        // <PhoneInput
        //     country={'fr'}
        //     value={value}
        //     onChange={onChange}
        // />
        <MuiPhoneNumber
          name={name}
          defaultCountry={'fr'}
          onChange={onChange}
          value={value}
          size="small"
          variant="outlined"
          regions={'europe'}
          fullWidth={true}
          InputLabelProps={{
            style: {
              // color: '#464855',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
            },
          }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
            },
            readOnly,
          }}
          placeholder={
            name && intl.formatMessage({ id: 'inputs.labels.' + name })
          }
          label={name && intl.formatMessage({ id: 'inputs.labels.' + name })}
          countryCodeEditable={false}
          error={errors && !!errors[name]}
          helperText={
            errors &&
            errors[name] &&
            intl.formatMessage({ id: errors[name].message })
          }
        />
      )}
    />
  )
}
