import { useState } from 'react'

import { Box } from '@material-ui/core'
import LocalHotelOutlinedIcon from '@material-ui/icons/LocalHotelOutlined'
import { Button } from '@components/buttons'

import { TypeOfExaminationDialog } from './TypeOfExaminationDialog'
//import ModalSelectRooms from './ModalSelectRooms';

export function TypeOfExamination() {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <Box>
      <Button
        variant="outlined"
        textColor="primary"
        color="primary"
        text="Type Exam"
        size="large"
        startIcon={<LocalHotelOutlinedIcon fontSize="large" />}
        onClick={() => {
          setOpenDialog(true)
        }}
      />

      {openDialog && (
        <TypeOfExaminationDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false)
          }}
        />
      )}
    </Box>
  )
}
