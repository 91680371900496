import { useState, forwardRef } from 'react'

import { FormattedMessage } from 'react-intl'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'

import {
  AppBar,
  Avatar,
  Badge,
  Divider,
  Drawer,
  IconButton,
  LinkProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { ListItemLink } from '@components/lists'
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import {
  AccountCircle,
  ExitToApp,
  Mail,
  MoreVert,
  Notifications,
} from '@material-ui/icons'

import { logout } from '@state/reducers/authReducer'
import { adminLinks } from '../containers/app/admin/HomeAdmin'
import { superAdminLinks } from '../containers/app/super-admin/HomeSuperAdmin'
import { ClientDto, Role } from '@services/api'
import Logo from '../assets/img/logos/logo.png'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    background: 'linear-gradient(45deg,#360033,#0b8793)',
    color: 'white',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    border: 'none',
    background: 'inherit',
    boxShadow:
      '2px 10px 30px 0 rgba(0,0,0,.42),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(0,0,0,.2)',
  },

  drawerPaperMobile: {
    width: 200,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    background: 'linear-gradient(45deg,#360033,#0b8793)',
    marginBottom: 30,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },

  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
    color: 'white',
  },
}))

type DrawerProps = {
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  role: Role
  client?: ClientDto
}

export default function DrawerMenu({
  toggleDrawer,
  open,
  role,
  client,
}: DrawerProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [mobileOpen, setMobileOpen] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const renderLink = forwardRef<any, Omit<LinkProps, 'to'>>(
    (itemProps, ref) => <NavLink to="/" ref={ref} {...itemProps} />,
  )

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    dispatch(logout())
    //TODO: Clear all
  }

  const handleProfile = () => history.push('/profile')

  const mobileMenuId = 'menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <Mail color="disabled" />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfile}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem button onClick={handleLogout}>
        <IconButton>
          <ExitToApp />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )

  const toggle =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      toggleDrawer(open)
    }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  const SecondaryListItems = () => {
    return <List></List>
  }
  const MainListItems = () => {
    // const adminItems = [
    //   {
    //     link: '/dashboard',
    //     icon: 'fa fa-chart-pie',
    //     text: 'admin.dashboard.title',
    //   },
    //   { link: '/site', icon: 'fa fa-hospital', text: 'admin.sites.title' },
    //   {
    //     link: '/platform',
    //     icon: 'fa fa-x-ray',
    //     text: 'admin.equipments.title',
    //   },
    //   { link: '/doctors', icon: 'fa fa-user-md', text: 'admin.doctors.title' },
    //   {
    //     link: '/patients',
    //     icon: 'fa fa-procedures',
    //     text: 'admin.patients.title',
    //   },
    //   {
    //     link: '/analytics',
    //     icon: 'fa fa-chart-bar',
    //     text: 'admin.analytics.title',
    //   },
    //   { link: '/logs', icon: 'fa fa-database', text: 'admin.logs.title' },
    // ]
    return (
      <List>
        {role === 'admin' &&
          adminLinks.map((link, key) => (
            <ListItemLink
              key={key}
              to={link.to}
              IconProps={{ className: link.icon }}
              text={link.title}
            />
          ))}
        {role === 'super_admin' &&
          superAdminLinks.map((link, key) => (
            <ListItemLink
              key={key}
              to={link.to}
              IconProps={{ className: link.icon }}
              text={link.title}
            />
          ))}
      </List>
    )
  }

  return (
    <>
      {/* MENU MOBILE | smUp */}

      {!isMobile ? (
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={toggle(false)}
          onOpen={toggle(true)}
          onMouseEnter={toggle(true)}
          onMouseLeave={toggle(false)}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List>
            <ListItem
              button
              style={{ paddingLeft: 12, color: 'white' }}
              component={renderLink}
            >
              <ListItemAvatar>
                <Avatar src={Logo} alt="logo" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  client && role === 'admin' ? (
                    client.name
                  ) : (
                    <FormattedMessage id="app-name" />
                  )
                }
                primaryTypographyProps={{ style: { fontSize: 30 } }}
                color="white"
              />
            </ListItem>
          </List>
          <Divider />
          <MainListItems />
          <Divider />
          <SecondaryListItems />
        </SwipeableDrawer>
      ) : (
        <AppBar
          position="static"
          elevation={1}
          // className={clsx(classes.appBar, {
          //   [classes.appBarShift]: open,
          // })}

          className={classes.appBar}
        >
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
            >
              <Avatar src={Logo} alt="logo" />

              {/* <MenuIcon /> */}
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              onClick={() => {
                history.push('/')
              }}
            >
              {client && role === 'admin' ? client.name : 'Portal'}
            </Typography>
            <div

            // className={classes.sectionMobile}
            >
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreVert />
              </IconButton>
            </div>
          </Toolbar>
          {renderMobileMenu}
        </AppBar>
      )}

      {/* MOBILE | smUp */}
      {isMobile && (
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawer,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeMenuButton}
          >
            <CloseIcon />
          </IconButton>
          <MainListItems />
        </Drawer>
      )}
    </>
  )
}
