import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { TextField } from '@material-ui/core'

type DateTimeInputProps = {
  control: Control<any>
  input: string
  errors?: DeepMap<any, FieldError>
}

export function DateTimeInput({ input, control }: DateTimeInputProps) {
  const intl = useIntl()
  return (
    <Controller
      name={input}
      control={control}
      render={() => (
        <TextField
          id={input}
          label={input && intl.formatMessage({ id: 'inputs.labels.' + input })}
          name={input}
          type="datetime-local"
          defaultValue="2017-05-24T10:30"
          //className={classes.textField}
          InputLabelProps={{
            style: {
              color: '#464855',
            },
            shrink: true,
          }}
        />

        // <KeyboardDatePicker
        //   fullWidth
        //   onChange={onChange}
        //   value={value}
        //   clearable
        //   margin="dense"
        //   inputVariant="outlined"
        //   minDate={new Date('01/01/1900')}
        //   maxDate={new Date()}
        //   InputAdornmentProps={{ position: 'end' }}
        //   format="DD/MM/yyyy"
        //   InputLabelProps={{
        //     style: {
        //       color: '#464855',
        //     },
        //   }}
        //   InputProps={{
        //     style: {
        //       backgroundColor: '#fff',
        //     },
        //   }}
        //   label={input && intl.formatMessage({ id: 'inputs.labels.' + input })}
        //   // placeholder={
        //   //   input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
        //   // }
        //   error={errors && !!errors[input]}
        //   helperText={
        //     errors &&
        //     errors[input] &&
        //     intl.formatMessage({ id: errors[input].message })
        //   }
        // />
      )}
    />
  )
}
