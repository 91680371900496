import { Suspense, lazy } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import { Spinner } from '@components/loadings'

import AppLayout from '../layouts/appLayout'
import AuthLayout from '../layouts/authLayout'
import history from '@services/history'

const routes = [
  // CLIENT VIEW
  {
    path: '/',
    Component: lazy(() => import('./app/Home')),
    Layout: AppLayout,
  },
  // PROFILE
  {
    path: '/profile',
    Component: lazy(() => import('./app/Profile')),
    Layout: AppLayout,
  },

  // EQUIPMENTS
  {
    path: '/a/equipment',
    Component: lazy(() => import('./app/equipment/Equipments')),
    Layout: AppLayout,
  },
  // SITES
  {
    path: '/a/sites',
    Component: lazy(() => import('./app/site/Sites')),
    Layout: AppLayout,
  },
  // STOCK
  {
    path: '/a/stock',
    Component: lazy(() => import('./app/stock/Stock')),
    Layout: AppLayout,
  },
  {
    path: '/a/stock/product',
    Component: lazy(() => import('./app/stock/Product')),
    Layout: AppLayout,
  },
  {
    path: '/a/stock/product-categories',
    Component: lazy(() => import('./app/stock/ProductCategories')),
    Layout: AppLayout,
  },
  {
    path: '/a/stock/providers',
    Component: lazy(() => import('./app/stock/Providers')),
    Layout: AppLayout,
  },
  {
    path: '/a/stock/inn-products',
    Component: lazy(() => import('./app/stock/InnProducts')),
    Layout: AppLayout,
  },
  // ORGANIZATIONS
  {
    path: '/a/organismes',
    Component: lazy(() => import('./app/organismes/Organismes')),
    Layout: AppLayout,
  },

  {
    path: '/a/organismes/funds',
    Component: lazy(() => import('./app/organismes/Funds')),
    Layout: AppLayout,
  },
  {
    path: '/a/organismes/insurance-companies',
    Component: lazy(() => import('./app/organismes/InsuranceCompany')),
    Layout: AppLayout,
  },
  {
    path: '/a/organismes/amc-conventions',
    Component: lazy(() => import('./app/organismes/AmcConventions')),
    Layout: AppLayout,
  },
  {
    path: '/a/organismes/receiving-organismes',
    Component: lazy(() => import('./app/organismes/ReceivingOrganismes')),
    Layout: AppLayout,
  },
  // INVOICING
  {
    path: '/a/invoicing',
    Component: lazy(() => import('./app/invoicing/Invoicing')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/paymentMethod',
    Component: lazy(() => import('./app/invoicing/PaymentMethod')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/bankAccount',
    Component: lazy(() => import('./app/invoicing/BankAccount')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/operationcfonb',
    Component: lazy(() => import('./app/invoicing/OperationCfonb')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/newspapers',
    Component: lazy(() => import('./app/invoicing/Newspapers')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/cashregisters',
    Component: lazy(() => import('./app/invoicing/CashRegisters')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/centralizingcaisses',
    Component: lazy(() => import('./app/invoicing/CentralizingCaisses')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/reasoncashmovements',
    Component: lazy(() => import('./app/invoicing/ReasonCashMovements')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/companies',
    Component: lazy(() => import('./app/invoicing/Companies')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/groupcompanies',
    Component: lazy(() => import('./app/invoicing/GroupCompanies')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/accountingprofiles',
    Component: lazy(() => import('./app/invoicing/AccountingProfiles')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/banks',
    Component: lazy(() => import('./app/invoicing/Banks')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/breakdownsexcluding',
    Component: lazy(() => import('./app/invoicing/BreakdownsExcluding')),
    Layout: AppLayout,
  },
  {
    path: '/a/invoicing/exportmodels',
    Component: lazy(() => import('./app/invoicing/ExportModels')),
    Layout: AppLayout,
  },
  // QUOTATION
  {
    path: '/a/quotation',
    Component: lazy(() => import('./app/quotation/Quotation')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/sections',
    Component: lazy(() => import('./app/quotation/Sections')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/keyletter',
    Component: lazy(() => import('./app/quotation/KeyLetter')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/basesnomenclature',
    Component: lazy(() => import('./app/quotation/BasesNomenclature')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/precotation',
    Component: lazy(() => import('./app/quotation/Precotation')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/pricethresholds',
    Component: lazy(() => import('./app/quotation/PriceThresholds')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/prices',
    Component: lazy(() => import('./app/quotation/Prices')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/markupindicator',
    Component: lazy(() => import('./app/quotation/MarkupIndicator')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/listsites',
    Component: lazy(() => import('./app/quotation/ListSites')),
    Layout: AppLayout,
  },
  {
    path: '/a/quotation/refcatalogccam',
    Component: lazy(() => import('./app/quotation/RefCcamCatalog')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/refmodifiers',
    Component: lazy(() => import('./app/quotation/RefModifiers')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/reftechnicalpackage',
    Component: lazy(() => import('./app/quotation/RefTechnicalPackage')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/refincompatibilityft',
    Component: lazy(() => import('./app/quotation/RefIncompatibilityFt')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/refIncompatibilityacts',
    Component: lazy(() => import('./app/quotation/RefIncompatibilityActs')),
    Layout: AppLayout,
  },

  {
    path: '/a/quotation/variableautomatficoverflow',
    Component: lazy(() => import('./app/quotation/VariableAutomatficOverflow')),
    Layout: AppLayout,
  },
  // CR Model
  {
    path: '/a/crmodel/models/automaticInsertions',
    Component: lazy(() => import('./app/crModel/models/AutomaticInsertions')),
    Layout: AppLayout,
  },

  {
    path: '/a/crmodel',
    Component: lazy(() => import('./app/crModel/CrModel')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/reports/typedocuments',
    Component: lazy(() => import('./app/crModel/reports/TypeDocuments')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/reports/priorities',
    Component: lazy(() => import('./app/crModel/reports/Priorities')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/reports/recipients',
    Component: lazy(() => import('./app/crModel/reports/Recipients')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/reports/secretariat',
    Component: lazy(() => import('./app/crModel/reports/Secretariat')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/thesaurus/thesaurus',
    Component: lazy(() => import('./app/crModel/thesaurus/Thesaurus')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/thesaurus/chapters',
    Component: lazy(() => import('./app/crModel/thesaurus/Chapters')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/models/convocations',
    Component: lazy(() => import('./app/crModel/models/Convocations')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/models/labels',
    Component: lazy(() => import('./app/crModel/models/Labels')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/models/invoiceprintout',
    Component: lazy(() => import('./app/crModel/models/InvoicePrintout')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/models/modelsparameters',
    Component: lazy(() => import('./app/crModel/models/ModelParameters')),
    Layout: AppLayout,
  },
  {
    path: '/a/crmodel/models/implementationprotocol',
    Component: lazy(
      () => import('./app/crModel/models/ImplementationProtocol'),
    ),
    Layout: AppLayout,
  },
  //ToolsVarious
  {
    path: '/a/toolsvarious',
    Component: lazy(() => import('./app/toolsVarious/ToolsVarious')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/connectedusers',
    Component: lazy(() => import('./app/toolsVarious/ConnectedUsers')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/externalcallsprescriptions',
    Component: lazy(
      () => import('./app/toolsVarious/ExternalCallsPrescriptions'),
    ),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/externalcalls',
    Component: lazy(() => import('./app/toolsVarious/ExternalCalls')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/registers',
    Component: lazy(() => import('./app/toolsVarious/Registers')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/countries',
    Component: lazy(() => import('./app/toolsVarious/Countries')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/publicholidays',
    Component: lazy(() => import('./app/toolsVarious/PublicHolidays')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/eventmanagement',
    Component: lazy(() => import('./app/toolsVarious/EventManagement')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/eventlog',
    Component: lazy(() => import('./app/toolsVarious/EventLog')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/lockmanagement',
    Component: lazy(() => import('./app/toolsVarious/LockManagement')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/xmlgrids/patient',
    Component: lazy(() => import('./app/toolsVarious/XmlPatient')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/xmlgrids/request',
    Component: lazy(() => import('./app/toolsVarious/XmlRequest')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/xmlgrids/appointment',
    Component: lazy(() => import('./app/toolsVarious/XmlAppointment')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/questionnaires',
    Component: lazy(() => import('./app/toolsVarious/Questionnaires')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/postalcode',
    Component: lazy(() => import('./app/toolsVarious/PostalCode')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/teleradiology/site',
    Component: lazy(() => import('./app/toolsVarious/TeleradiologySite')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/teleradiology/protocol',
    Component: lazy(() => import('./app/toolsVarious/TeleradiologyProtocol')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/listcustomizedperiods',
    Component: lazy(() => import('./app/toolsVarious/ListCustomizedPeriods')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/languages',
    Component: lazy(() => import('./app/toolsVarious/Languages')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/purgesetting',
    Component: lazy(() => import('./app/toolsVarious/PurgeSetting')),
    Layout: AppLayout,
  },
  {
    path: '/a/toolsvarious/updateposts',
    Component: lazy(() => import('./app/toolsVarious/UpdatePosts')),
    Layout: AppLayout,
  },
  //structure
  {
    path: '/a/nuclearMedicine',
    Component: lazy(() => import('./app/nuclearMedicine/NuclearMedicine')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/waste-container-type',
    Component: lazy(() => import('./app/nuclearMedicine/WasteContainerType')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/stock-location',
    Component: lazy(() => import('./app/nuclearMedicine/StockLocation')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/injection-methods',
    Component: lazy(() => import('./app/nuclearMedicine/InjectionMethods')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/injection-area',
    Component: lazy(() => import('./app/nuclearMedicine/InjectionArea')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/pre-injection-checks',
    Component: lazy(() => import('./app/nuclearMedicine/PreInjectionChecks')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/colors',
    Component: lazy(() => import('./app/nuclearMedicine/Colors')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/aspect',
    Component: lazy(() => import('./app/nuclearMedicine/Aspects')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/custom-quality-control',
    Component: lazy(
      () => import('./app/nuclearMedicine/CustomQualityControls'),
    ),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/product-quality-links',
    Component: lazy(() => import('./app/nuclearMedicine/ProductsQualityLinks')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/measuring-device',
    Component: lazy(() => import('./app/nuclearMedicine/MeasuringDevices')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/custom-radioprotection-controls',
    Component: lazy(
      () => import('./app/nuclearMedicine/CustomRadioprotectionControls'),
    ),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/custom-sterilazation-controls',
    Component: lazy(
      () => import('./app/nuclearMedicine/CustomSterilizationProtocols'),
    ),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/dose-calibrator',
    Component: lazy(() => import('./app/nuclearMedicine/DoseCalibrator')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/rpm-production-steps',
    Component: lazy(() => import('./app/nuclearMedicine/RpmProductionSteps')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/preparation-steps-links',
    Component: lazy(
      () => import('./app/nuclearMedicine/PreparationStepsLinks'),
    ),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/preparations-docs',
    Component: lazy(() => import('./app/nuclearMedicine/PreparationDocuments')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/isotopes',
    Component: lazy(() => import('./app/nuclearMedicine/Isotopes')),
    Layout: AppLayout,
  },
  {
    path: '/a/nuclearMedicine/restrictions-site-isotopes',
    Component: lazy(
      () => import('./app/nuclearMedicine/IsotopeSiteRestictions'),
    ),
    Layout: AppLayout,
  },
  // STRUCTURE
  {
    path: '/a/structure',
    Component: lazy(() => import('./app/structure/Structure')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/origineipp',
    Component: lazy(() => import('./app/structure/OrigineIpp')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/uf',
    Component: lazy(() => import('./app/structure/Uf')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/um',
    Component: lazy(() => import('./app/structure/Um')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/transport-uf-site',
    Component: lazy(() => import('./app/structure/TransportUfSite')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/libelles-doc-num',
    Component: lazy(() => import('./app/structure/LibelleDoc')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/periode-cotassion',
    Component: lazy(() => import('./app/structure/PeriodeCotation')),
    Layout: AppLayout,
  },
  {
    path: '/a/structure/monnaie',
    Component: lazy(() => import('./app/structure/Monnaie')),
    Layout: AppLayout,
  },
  // PREFERENCES
  {
    path: '/a/preferences',
    Component: lazy(() => import('./app/preferences')),
    Layout: AppLayout,
  },
  // DOCTORS
  {
    path: '/a/doctors',
    Component: lazy(() => import('./app/doctor/ReferringDoctors')),
    Layout: AppLayout,
  },
  // PATIENTS
  {
    path: '/a/patients',
    Component: lazy(() => import('./app/patient/Patients')),
    Layout: AppLayout,
  },
  // STATS
  {
    path: '/a/stats',
    Component: lazy(() => import('./app/stat/Stats')),
    Layout: AppLayout,
  },
  // LOGS
  {
    path: '/a/logs',
    Component: lazy(() => import('./app/log/Logs')),
    Layout: AppLayout,
  },
  // SCHEDULES
  {
    path: '/a/schedule',
    Component: lazy(() => import('./app/schedule/Schedule')),
    Layout: AppLayout,
  },
  // STAFF
  {
    path: '/a/staff',
    Component: lazy(() => import('./app/admin/Admins')),
    Layout: AppLayout,
  },
  // SUPER-ADMIN
  {
    path: '/sa/equipments',
    Component: lazy(() => import('./app/equipment/Equipments')),
    Layout: AppLayout,
  },
  {
    path: '/sa/admins',
    Component: lazy(() => import('./app/admin/Admins')),
    Layout: AppLayout,
  },
  {
    path: '/sa/clients',
    Component: lazy(() => import('./app/client/Clients')),
    Layout: AppLayout,
  },
  {
    path: '/sa/clients/:id',
    Component: lazy(() => import('./app/preferences')),
    Layout: AppLayout,
  },
  {
    path: '/sa/sites',
    Component: lazy(() => import('./app/site/Sites')),
    Layout: AppLayout,
  },
  {
    path: '/sa/doctors',
    Component: lazy(() => import('./app/doctor/ReferringDoctors')),
    Layout: AppLayout,
  },
  {
    path: '/sa/patients',
    Component: lazy(() => import('./app/patient/Patients')),
    Layout: AppLayout,
  },
  {
    path: '/sa/stats',
    Component: lazy(() => import('./app/stat/Stats')),
    Layout: AppLayout,
  },
  {
    path: '/sa/dataset',
    Component: lazy(() => import('./app/directory/Dataset')),
    Layout: AppLayout,
  },
  {
    path: '/sa/update-dataset',
    Component: lazy(() => import('./app/directory/UpdateDataset')),
    Layout: AppLayout,
  },
  {
    path: '/sa/logs',
    Component: lazy(() => import('./app/log/Logs')),
    Layout: AppLayout,
  },
  {
    path: '/sa/macro',
    Component: lazy(() => import('./app/macro/Macro')),
    Layout: AppLayout,
  },

  // AUTH
  {
    path: '/enrollment/hospital',
    Component: lazy(() => import('./auth/enrollment/Hopital')),
    Layout: AuthLayout,
  },
  {
    path: '/enrollment/doctor',
    Component: lazy(() => import('./auth/enrollment/Doctor')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('./auth/Login')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/first-connexion',
    Component: lazy(() => import('./auth/FirstConnexion')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/forgotten-password',
    Component: lazy(() => import('./auth/ForgottenPassword')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('./auth/ResetPassword')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('./auth/Register')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/verification-mail',
    Component: lazy(() => import('./auth/VerificationEmail')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/test',
    Component: lazy(() => import('./auth/TesterHl7')),
    Layout: AuthLayout,
  },
  {
    path: '*',
    Component: lazy(() => import('./auth/NotFound')),
    Layout: AuthLayout,
  },
]

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {routes.map(({ path, Component, Layout }, index) => (
          <Route
            exact
            path={path}
            key={index}
            render={(props) => (
              <Layout {...props}>
                <Suspense fallback={<Spinner />}>
                  <Component {...props} />
                </Suspense>
              </Layout>
            )}
          />
        ))}
      </Switch>
    </Router>
  )
}
