import { FormattedMessage, useIntl } from 'react-intl'
import { DeepMap, FieldError } from 'react-hook-form'

import { TextField, TextFieldProps } from '@material-ui/core'

export type TextInputProps = TextFieldProps & {
  errors?: DeepMap<any, FieldError>
  input: string
  showPassword?: boolean
  fullWidth?: boolean
  readOnly?: boolean
}

export function TextInput({
  errors,
  input,
  name = input,
  type = 'text',
  InputProps,
  fullWidth = true,
  readOnly,
  ...props
}: TextInputProps) {
  const intl = useIntl()
  return (
    <TextField
      {...props}
      fullWidth={fullWidth}
      variant="outlined"
      id={input}
      size="small"
      type={type}
      style={{}}
      InputProps={{
        style: {
          backgroundColor: '#fff',
          color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
        },
        readOnly,
        ...InputProps,
      }}
      name={name}
      label={input && <FormattedMessage id={'inputs.labels.' + input} />}
      placeholder={
        input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
      }
      error={errors && !!errors[name]}
      helperText={
        errors && errors[name] && <FormattedMessage id={errors[name].message} />
      }
    />
  )
}
