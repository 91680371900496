import { useState } from 'react'

import { IconButton, Theme, makeStyles } from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'

import { CalendarPreferencesDialog } from '../dialogs'

const useStyles = makeStyles((theme: Theme) => ({
  dpcButtonIcon: {
    fontSize: 27,
    color: theme.palette.primary.main,
  },
}))

// type OneAppointement = {
//   data: any
//   formatDate: any
// }

export function DisplayPreConfig() {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => {
          setOpen(true)
        }}
      >
        <TuneIcon className={classes.dpcButtonIcon} />
      </IconButton>

      {open && (
        <CalendarPreferencesDialog
          open={open}
          onCancelChanges={() => {
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
