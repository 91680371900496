import { Grid } from '@material-ui/core'

import Logo from '../../assets/img/logos/logo.png'
import Text from '../Text'

export function AuthTitle() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <img height="50" src={Logo} alt="logo" />
      <Text
        text="app-name"
        variant="h2"
        style={{ padding: 5, color: 'white' }}
        format
      />
      {/* <Text text="®" variant="h5" color="textSecondary" gutterBottom /> */}
    </Grid>
  )
}
