import { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'

import { DialogFileInput } from '@components/inputs'
import { addClient } from '@state/actions/clientActions'
import { addClientSchema } from '@utils/schemas'
import { CreateClientDto } from '@services/api'

import { CommonEnrollment } from './CommonEnrollment'

//TODO useless component ?
export const HospitalCompleteAdminInfos = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const [file1, setFile1] = useState<File[]>([])

  const { register, handleSubmit, errors, control } = useForm<CreateClientDto>({
    resolver: yupResolver(addClientSchema) as any,
  })
  console.log(errors, register, control)

  const onSubmit = (clientDot: CreateClientDto) => {
    dispatch(addClient({ body: clientDot }))
  }
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <CommonEnrollment>
      {/* #c1 */}
      <Grid item xs={12} style={{ display: 'flex' }}>
        <DialogFileInput
          nameFile={file1.length > 0 ? file1[0].name : 'Extrait K ( Kbis )'}
          onSave={(files) => {
            console.log(files)
            setFile1(files)
          }}
          color="#f59f2d"
        />
      </Grid>
    </CommonEnrollment>
  )
})
