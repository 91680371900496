import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { RadioInput } from '@components/inputs'

import { TypeExercise } from '@utils/constants'
import { DialogFileInput } from '@components/inputs'
import { EcSpaceHorizontal } from '@utils/Spacing'
import { addClientSchema } from '@utils/schemas'

import { CommonEnrollment } from './CommonEnrollment'

type Values = {
  name: string
  einNumber: string
  companyStatus: string
  streetAddress: string
  postalCode: string
  city: string
  country: string
  phoneNumber: string
  fax: string
  website?: string
  email: string
}

export function DoctorCompleteAdminInfos() {
  const [file1, setFile1] = useState<File[]>([])
  const [file2, setFile2] = useState<File[]>([])
  const [file3, setFile3] = useState<File[]>([])

  const { errors, control } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {
      companyStatus: '',
    },
  })
  console.log(errors)

  return (
    <CommonEnrollment>
      {/* #c1 */}
      <Grid item xs={12} style={{ display: 'flex' }}>
        <DialogFileInput
          nameFile={file1.length > 0 ? file1[0].name : 'Insurance Contact'}
          onSave={(files) => {
            console.log(files)
            setFile1(files)
          }}
          color="#f59f2d"
        />

        <EcSpaceHorizontal size={25} />

        <DialogFileInput
          nameFile={file2.length > 0 ? file2[0].name : 'Diploma'}
          onSave={(files) => {
            console.log(files)
            setFile2(files)
          }}
          color="#ea4e7a"
        />
        <EcSpaceHorizontal size={25} />
        <DialogFileInput
          numberFile={10}
          nameFile={file3.length > 0 ? file3[0].name : 'Specialty Diplomas'}
          onSave={(files) => {
            console.log(files)
            setFile3(files)
          }}
          color="#356bbd"
        />
        <EcSpaceHorizontal size={25} />
        <DialogFileInput
          numberFile={10}
          nameFile={file3.length > 0 ? file3[0].name : 'Resume (CV)'}
          onSave={(files) => {
            console.log(files)
            setFile3(files)
          }}
          color="#a0a3b1"
        />

        <EcSpaceHorizontal size={25} />
        <DialogFileInput
          numberFile={10}
          nameFile={file3.length > 0 ? file3[0].name : 'Identity'}
          onSave={(files) => {
            console.log(files)
            setFile3(files)
          }}
          color="#a50403"
        />
      </Grid>

      <Grid item xs={12} style={{ display: 'flex' }}>
        <RadioInput
          input="typeexercice"
          label="Type Exercice"
          radioGroupClass="dcai-radio-button"
          control={control}
          options={TypeExercise}
        />
      </Grid>
    </CommonEnrollment>
  )
}
