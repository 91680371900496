import { useIntl } from 'react-intl'
import { DeepMap, FieldError } from 'react-hook-form'

import { TextField, TextFieldProps } from '@material-ui/core'

export type TextAreaProps = TextFieldProps & {
  errors?: DeepMap<any, FieldError>
  input: string
  showPassword?: boolean
  rows?: number
}

export function TextArea({
  errors,
  input,
  type = 'text',
  InputProps,
  rows = 5,
  ...props
}: TextAreaProps) {
  const intl = useIntl()

  // console.log('Errors', errors, errors[input]);

  return (
    <TextField
      multiline
      rows={rows}
      fullWidth
      variant="outlined"
      margin="dense"
      type={type}
      InputLabelProps={{
        style: {
          color: '#464855',
        },
      }}
      InputProps={{
        style: {
          backgroundColor: '#fff',
        },
        ...InputProps,
      }}
      name={input}
      label={input && intl.formatMessage({ id: 'inputs.labels.' + input })}
      placeholder={
        input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
      }
      error={errors && !!errors[input]}
      helperText={
        errors &&
        errors[input] &&
        intl.formatMessage({ id: errors[input].message })
      }
      {...props}
    />
  )
}
