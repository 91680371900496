import { memo } from 'react'

import {
  CheckCircleOutline,
  ErrorOutline,
  HighlightOff,
} from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { yellow } from '@material-ui/core/colors'

type Props = {
  status?: string
}

const ExamStatusIcon: React.FC<Props> = ({ status }) => {
  switch (status) {
    case 'TO_BE_VALIDATE': {
      return (
        <Tooltip title="Validation awaiting">
          <ErrorOutline
            fontSize="large"
            style={{ position: 'absolute', color: yellow[800] }}
          />
        </Tooltip>
      )
    }

    case 'VALIDATED': {
      return (
        <Tooltip title="Delete">
          <CheckCircleOutline style={{ position: 'absolute' }} />
        </Tooltip>
      )
    }

    case 'INVALID': {
      return (
        <Tooltip title="Delete">
          <HighlightOff style={{ position: 'absolute' }} />
        </Tooltip>
      )
    }

    default: {
      return <></>
    }
  }
}

export default memo(ExamStatusIcon)
