import { Filter, Sorting } from '@devexpress/dx-react-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AdminDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type adminState = {
  admins: {
    totalCount: number
    datas: AdminDto[]
  }
  filters: Filter[]
  sorting: Sorting[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  selected?: AdminDto
  columnOrder: string[]
}

const initialState: adminState = {
  admins: {
    totalCount: 0,
    datas: [],
  },
  filters: [],
  sorting: [{ columnName: 'plannedDate', direction: 'asc' }],
  currentPage: 0,
  pageSize: 10,
  selection: [],
  selected: undefined,
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringDoctor',
    'open',
    'actions',
  ],
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdmins: (state, action: PayloadAction<PaginatedDto<AdminDto>>) => {
      state.admins = action.payload
    },
    removeAdmins: (state) => {
      state.admins = initialState.admins
    },
    addAdmin: (state, action: PayloadAction<AdminDto>) => {
      state.admins.datas = [...state.admins.datas, action.payload]
      state.admins.totalCount += 1
    },
    updateAdmin: (state, action: PayloadAction<AdminDto>) => {
      state.admins.datas = state.admins.datas.map((admin) =>
        admin.id === action.payload.id ? action.payload : admin,
      )
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setSelection: (state, action: PayloadAction<(string | number)[]>) => {
      const selection = action.payload
      const index = selection[0] as number
      state.selected = state.admins.datas[index]
    },
    setSelected: (state, action: PayloadAction<AdminDto>) => {
      state.selected = action.payload
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload
    },
    cleanAdmin: () => initialState,
  },
})

export const {
  setAdmins,
  removeAdmins,
  addAdmin,
  updateAdmin,
  setFilters,
  setSorting,
  setPageSize,
  setCurrentPage,
  setSelection,
  setSelected,
  setColumnOrder,
  cleanAdmin,
} = adminSlice.actions

export default adminSlice.reducer
