import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { AdminDto, AdminService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { AdminActions } from '../actions'
import { addAdmin, setAdmins, updateAdmin } from '../reducers/adminReducer'

function* findAll() {
  try {
    const admins: PaginatedDto<AdminDto> = yield call(AdminService.findAll)
    yield put(setAdmins(admins))
    yield put({
      type: AdminActions.GET_ADMINS_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: AdminActions.GET_ADMINS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    yield call(AdminService.findOne, payload)
    yield put({
      type: AdminActions.GET_ADMIN_SUCCESS,
    })
  } catch (e) {
    console.log(e)
    yield put({
      type: AdminActions.GET_ADMIN_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}
function* create({ payload }: AnyAction) {
  try {
    const admin: AdminDto = yield call(AdminService.create, payload)
    yield put(addAdmin(admin))
    yield put({
      type: AdminActions.POST_ADMIN_SUCCESS,
      payload: { message: 'New doctor successfully added' },
    })
  } catch (e) {
    yield put({
      type: AdminActions.POST_ADMIN_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* update({ payload }: AnyAction) {
  try {
    const admin = yield call(AdminService.update, payload)
    yield put(updateAdmin(admin))
    yield put({
      type: AdminActions.PUT_ADMIN_SUCCESS,
      payload: { message: 'Doctor successfully updated' },
    })
  } catch (e) {
    yield put({
      type: AdminActions.PUT_ADMIN_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* AdminFlow() {
  yield all([
    takeLatest(AdminActions.GET_ADMINS_REQUEST, findAll),
    takeLatest(AdminActions.GET_ADMIN_REQUEST, findOne),
    takeLatest(AdminActions.POST_ADMIN_REQUEST, create),
    takeLatest(AdminActions.PUT_ADMIN_REQUEST, update),
  ])
}

const adminSagas = [fork(AdminFlow)]

export default adminSagas
