import { Dialog, DialogContent, Divider, Grid } from '@material-ui/core'

import { DialogTitle } from '@components/titles'

import { PatientForm } from './PatientForm'

type AddPatientDialogProps = {
  onClose: () => void
  open: boolean
}

export function PatientDialog({ onClose, open }: AddPatientDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle title="New Patient" onClose={onClose} />
      <Divider />

      <DialogContent>
        <Grid container>
          <PatientForm onClose={onClose} />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
