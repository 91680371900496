import {
  DialogTitle as DialogTitleBase,
  DialogTitleProps,
  IconButton,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import Text from '../Text'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    height: 30,
    backgroundImage: 'linear-gradient(45deg,#360033,#0b8793)',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    // position: 'absolute',
    // color: theme.palette.grey[500],
    // backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    height: 30,
    width: 30,
    padding: 15,
    fontSize: '3rem',

    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },

  text: {
    color: '#fff',
    fontWeight: 500,
  },
}))

type TitleCommonDialog = DialogTitleProps & {
  title: string
  onClose: () => void
  format?: boolean
}

export function TitleCommonDialog({
  title,
  onClose,
  format = false,
  ...other
}: TitleCommonDialog) {
  const classes = useStyles()
  return (
    <DialogTitleBase disableTypography className={classes.root} {...other}>
      <Text
        variant="h5"
        text={title}
        format={format}
        className={classes.text}
      />
      {onClose ? (
        <IconButton
          // aria-label="close"
          className={classes?.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitleBase>
  )
}
