import { memo } from 'react'

import { Delete, GetApp as Download } from '@material-ui/icons'
import { Button, ButtonGroup, Tooltip } from '@material-ui/core'

import { EtherFile } from '@services/extendedType'

import FileStatusIcon from './FileStatusIcon'

interface Props {
  index: number
  file: EtherFile
  onDelete(item: any): void
  onDownload(item: any): void
  onChangeStatus?(item: EtherFile): void
}

const FileItem = ({ file, onDelete, onDownload }: Props) => {
  const handleDelete = () => {
    onDelete(file)
  }

  const status = 'TO_BE_VALIDATE'

  return (
    <li className="item-base item-file">
      <FileStatusIcon status={status} />

      <img src={file.data} alt={file.filename} height="100" width="100" />

      <ButtonGroup
        className="item-actions"
        color="primary"
        aria-label="outlined primary button group"
        variant="contained"
        disableElevation
        fullWidth
      >
        <Tooltip title="Download">
          <Button onClick={onDownload} size="small">
            <Download fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button onClick={handleDelete} size="small">
            <Delete fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </li>
  )
}

export default memo(FileItem)
