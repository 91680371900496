import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { KeyboardDatePicker } from '@material-ui/pickers'
import { TextFieldProps } from '@material-ui/core'

type DateInputProps = TextFieldProps & {
  control: Control<any>
  input: string
  errors?: DeepMap<any, FieldError>
  minDate?: Date
  maxDate?: Date
  defaultDate?: Date
  readOnly?: boolean
}

export function DateInput({
  input,
  control,
  errors,
  minDate = new Date('01/01/1900'),
  maxDate = new Date('01/01/2025'),
  defaultDate = new Date(),
  readOnly,
}: DateInputProps) {
  const intl = useIntl()
  return (
    <Controller
      name={input}
      control={control}
      initialFocusedDate={null}
      defaultValue={defaultDate}
      render={({ onChange, value }) => (
        <KeyboardDatePicker
          style={{ margin: 0 }}
          fullWidth
          size="small"
          onChange={onChange}
          value={value}
          clearable
          margin="dense"
          inputVariant="outlined"
          minDate={minDate}
          maxDate={maxDate}
          InputAdornmentProps={{ position: 'end' }}
          format="DD/MM/yyyy"
          InputLabelProps={{
            style: {
              // color: '#5144a0',
              //  backgroundColor: '#fff',
              fontWeight: 500,
              padding: '0px 5px 2px 5px',
              borderRadius: 2,
            },
          }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
            },
            readOnly,
          }}
          label={input && intl.formatMessage({ id: 'inputs.labels.' + input })}
          // placeholder={
          //   input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
          // }
          error={errors && !!errors[input]}
          helperText={
            errors &&
            errors[input] &&
            intl.formatMessage({ id: errors[input].message })
          }
        />
      )}
    />
  )
}
