import { Filter } from '@services/api'
import { RppsActions } from '../actions'

export const findRpps = (params: {
  /**  */
  filters: Filter[]
  /**  */
  sort: object
  /**  */
  limit: number
  /**  */
  skip: number
}) => ({
  type: RppsActions.GET_RPPS_REQUEST,
  payload: params,
})

export const autocompleteRpps = (params: {
  /**  */
  rppsNumber: string
}) => ({
  type: RppsActions.GET_AUTOCOMPLETE_RPPS_REQUEST,
  payload: params,
})
