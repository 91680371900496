import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { DoctorDto, DoctorService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { DoctorActions } from '../actions'
import {
  addDoctor,
  setDoctors,
  setSelected,
  updateDoctor,
  // setOptions
} from '../reducers/doctorReducer'

function* findAll() {
  try {
    const doctors: PaginatedDto<DoctorDto> = yield call(DoctorService.findAll)
    yield put(setDoctors(doctors))
    yield put({
      type: DoctorActions.GET_DOCTORS_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: DoctorActions.GET_DOCTORS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    const doctor: DoctorDto = yield call(DoctorService.findOne, payload)
    yield put(setSelected(doctor))
    yield put({
      type: DoctorActions.GET_DOCTOR_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: DoctorActions.GET_DOCTOR_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

// function* autocomplete({ payload }: AnyAction) {
//   try {
//     const datas: any[] = yield call(DoctorService.findAll, payload)
//     yield put(setOptions(datas))
//     yield put({ type: DoctorActions.GET_AUTOCOMPLETE_DOCTORS_SUCCESS })
//   } catch (e) {
//     yield put({
//       type: DoctorActions.GET_AUTOCOMPLETE_DOCTORS_ERROR,
//       payload: { message: e.response.data.message },
//     })
//   }
// }

function* create({ payload }: AnyAction) {
  try {
    const doctor: DoctorDto = yield call(DoctorService.create, payload)
    yield put(addDoctor(doctor))
    yield put({
      type: DoctorActions.POST_DOCTOR_SUCCESS,
      payload: { message: 'New doctor successfully added to site' },
    })
  } catch (e) {
    yield put({
      type: DoctorActions.POST_DOCTOR_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* update({ payload }: AnyAction) {
  try {
    const doctor: DoctorDto = yield call(DoctorService.update, payload)
    yield put(updateDoctor(doctor))
    yield put({
      type: DoctorActions.PUT_DOCTOR_SUCCESS,
      payload: { message: 'Doctor successfully updated' },
    })
  } catch (e) {
    yield put({
      type: DoctorActions.PUT_DOCTOR_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* doctorFlow() {
  yield all([
    takeLatest(DoctorActions.GET_DOCTORS_REQUEST, findAll),
    takeLatest(DoctorActions.GET_DOCTOR_REQUEST, findOne),
    takeLatest(DoctorActions.GET_AUTOCOMPLETE_DOCTORS_REQUEST, findAll),
    takeLatest(DoctorActions.POST_DOCTOR_REQUEST, create),
    takeLatest(DoctorActions.PUT_DOCTOR_REQUEST, update),
  ])
}

const doctorSagas = [fork(doctorFlow)]

export default doctorSagas
