import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import {
  GedService,
  SharedStudyDto,
  StudyDto,
  StudyService,
} from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { StudyActions } from '../actions'
import {
  addShare,
  removeOrder,
  removeShare,
  setOrder,
  setReport,
  setSelected,
  setStudies,
} from '../reducers/studyReducer'

function* findAll() {
  try {
    const studies: PaginatedDto<StudyDto> = yield call(StudyService.findAll)
    yield put(setStudies(studies))
    yield put({
      type: StudyActions.GET_STUDIES_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: StudyActions.GET_STUDIES_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    const study: StudyDto = yield call(StudyService.findOne, payload)
    yield put(setSelected(study))
    yield put({
      type: StudyActions.GET_STUDY_SUCCESS,
    })
  } catch (e) {
    yield put({
      type: StudyActions.GET_STUDY_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}
// function* create({ payload }: AnyAction) {
//   try {
//     const superAdmin: StudyDto = yield call(StudyService.create, payload)
//     yield put(addSuperAdmin(superAdmin))
//     yield put({
//       type: StudyActions.POST_STUDY_SUCCESS,
//       payload: { message: 'New superAdmin successfully added to site' },
//     })
//   } catch (e) {
//     yield put({
//       type: StudyActions.POST_STUDY_ERROR,
//       payload: { message: e.response.data.message },
//     })
//   }
// }

// function* update({ payload }: AnyAction) {
//   try {
//     yield call(StudyService.update, payload)
//     yield put({
//       type: StudyActions.PUT_STUDY_SUCCESS,
//       payload: { message: 'SuperAdmin successfully updated' },
//     })
//   } catch (e) {
//     yield put({
//       type: StudyActions.PUT_STUDY_ERROR,
//       payload: { message: e.response.data.message },
//     })
//   }
// }

function* shareStudy({ payload }: AnyAction) {
  try {
    const share: SharedStudyDto = yield call(StudyService.shareStudy, payload)
    yield put(addShare(share))
    yield put({ type: StudyActions.POST_SHARE_STUDY_SUCCESS })
  } catch (e) {
    yield put({
      type: StudyActions.POST_SHARE_STUDY_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* unshareStudy({ payload }: AnyAction) {
  try {
    yield call(StudyService.unshareStudy, payload)
    yield put(removeShare(payload.id))
    yield put({ type: StudyActions.POST_UNSHARE_STUDY_SUCCESS })
  } catch (e) {
    yield put({
      type: StudyActions.POST_UNSHARE_STUDY_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* getOrder({ payload }: AnyAction) {
  try {
    //@ts-ignore
    const { data, filename } = yield call(GedService.downloadFile, payload, {
      responseType: 'blob',
    })
    yield put(setOrder({ filename, data }))
    yield put({ type: StudyActions.POST_DOWNLOAD_ORDER_SUCCESS })
  } catch (e) {
    yield put({
      type: StudyActions.POST_DOWNLOAD_ORDER_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* uploadOrder({ payload }: AnyAction) {
  try {
    yield call(StudyService.uploadAdvice, {
      id: payload.id,
      file: payload.files[0],
    })
    yield put(
      setOrder({
        filename: payload.files[0].name,
        data: URL.createObjectURL(payload.files[0]),
      }),
    )
    yield put({ type: StudyActions.POST_UPLOAD_ORDER_SUCCESS })
  } catch (e) {
    yield put({
      type: StudyActions.POST_UPLOAD_ORDER_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* deleteOrder({ payload }: AnyAction) {
  try {
    const study: StudyDto = yield call(
      StudyService.deleteAdvice,
      payload,
    ) as any
    console.log(study)
    yield put(removeOrder())
    yield put({ type: StudyActions.POST_DELETE_ORDER_SUCCESS })
  } catch (e) {
    yield put({
      type: StudyActions.POST_DELETE_ORDER_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* getReport({ payload }: AnyAction) {
  try {
    //@ts-ignore
    const { data, filename } = yield call(GedService.downloadFile, payload, {
      responseType: 'blob',
    })
    yield put(setReport({ filename, data }))
    yield put({ type: StudyActions.GET_DOWNLOAD_IMAGE_SUCCESS })
  } catch (e) {
    yield put({
      type: StudyActions.GET_DOWNLOAD_IMAGE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* downloadImage({ payload }: AnyAction) {
  try {
    //@ts-ignore
    const { data, filename } = yield call(StudyService.download, payload, {
      responseType: 'blob',
    })
    console.log(filename)
    const link = document.createElement('a')
    link.href = data
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // setImage({
    //   filename,
    //   data: URL.createObjectURL(data),
    // }),
    yield put({ type: StudyActions.GET_DOWNLOAD_IMAGE_SUCCESS })
  } catch (e) {
    console.log(e)
    yield put({
      type: StudyActions.GET_DOWNLOAD_IMAGE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* studyFlow() {
  yield all([
    takeLatest(StudyActions.GET_STUDIES_REQUEST, findAll),
    takeLatest(StudyActions.GET_STUDY_REQUEST, findOne),
    // takeLatest(StudyActions.POST_STUDY_REQUEST, create),
    // takeLatest(StudyActions.PUT_STUDY_REQUEST, update),
    takeLatest(StudyActions.POST_SHARE_STUDY_REQUEST, shareStudy),
    takeLatest(StudyActions.POST_UNSHARE_STUDY_REQUEST, unshareStudy),
    takeLatest(StudyActions.POST_DOWNLOAD_ORDER_REQUEST, getOrder),
    takeLatest(StudyActions.POST_UPLOAD_ORDER_REQUEST, uploadOrder),
    takeLatest(StudyActions.POST_DELETE_ORDER_REQUEST, deleteOrder),
    takeLatest(StudyActions.POST_DOWNLOAD_REPORT_REQUEST, getReport),
    takeLatest(StudyActions.GET_DOWNLOAD_IMAGE_REQUEST, downloadImage),
  ])
}

const studySagas = [fork(studyFlow)]

export default studySagas
