import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { Box, Grid, Icon, Paper, Theme, makeStyles } from '@material-ui/core'
import { truncate } from 'lodash'

import { EcSpaceVertical } from '@utils/Spacing'
import Text from '@components/Text'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    color: '#fff',
  },
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  grid: {
    '& div:nth-child(3n) a > div': {
      background: 'linear-gradient(45deg,#843cf7,#38b8f2)',
    },
    '& div:nth-child(3n+1) a > div': {
      backgroundImage: 'linear-gradient(45deg,#23bcbb,#45e994)',
    },
    '& div:nth-child(3n+2) a > div': {
      backgroundImage: 'linear-gradient(45deg,#000,#53346d)',
    },
  },
}))

export const adminLinks = [
  // {
  //   to: '/a/equipment',
  //   title: 'Equipment',
  //   style: { backgroundImage: 'linear-gradient(45deg,#000,#53346d)' },
  //   icon: 'fab fa-uikit',
  // },
  {
    to: '/a/preferences',
    title: 'Preferences',
    //icon: 'fas fa-sliders-h',
    icon: 'fas fa-cogs',
  },
  {
    to: '/a/stock',
    title: 'Stock',
    //icon: 'fas fa-sliders-h',
    icon: 'fas fa-cubes',
  },
  {
    to: '/a/equipment',
    title: 'Technical platform',
    icon: 'fab fa-uikit',
  },
  {
    to: '/sa/admins',
    title: 'Staff',
    icon: 'fa fa-user',
  },
  {
    to: '/a/doctors',
    title: 'Referring Doctors',
    icon: 'fa fa-user-md',
  },
  {
    to: '/a/patients',
    title: 'Patients',
    icon: 'fa fa-procedures',
  },
  {
    to: '/a/sites',
    title: 'Sites',
    icon: 'fas fa-clinic-medical',
  },
  {
    to: '/a/structure',
    title: 'Structure',
    icon: 'fa fa-hospital',
  },
  {
    to: '/a/organismes',
    title: 'Organismes',
    icon: 'fas fa-sitemap',
  },
  {
    to: '/a/nuclearMedicine',
    title: 'Nuclear Medicine',
    icon: 'fas fa-radiation',
  },
  {
    to: '/a/invoicing',
    title: 'Invoicing',
    icon: 'fas fa-file-invoice',
  },
  {
    to: '/a/quotation',
    title: 'Quotation',
    icon: 'fas fa-star-half-alt',
  },
  {
    to: '/a/crmodel',
    title: 'CR / Models',
    icon: 'fas fa-vector-square',
  },
  {
    to: '/a/toolsvarious',
    title: 'Tools Various',
    icon: 'fas fa-tools',
  },
  {
    to: '/a/stats',
    title: 'Stats',
    icon: 'fa fa-chart-bar',
  },
  {
    to: '/a/logs',
    title: 'Logs',
    icon: 'fa fa-database',
  },
  {
    to: '/a/schedule',
    title: 'Schedule',
    icon: 'fa fa-calendar-alt',
  },
]

export default function HomeAdmin() {
  const classes = useStyles()
  return (
    <Grid container spacing={4} className={classes.grid}>
      {/* {adminLinks.map((item, key) => (
        <Grid item xs={4} key={key}>
          <Link to={item.to}>
            <Paper className={classes.paper} style={item.style}>
              <Grid item container alignItems="center" justifyContent="space-between">
                <Text text={item.title} variant="h4" />
                <Icon className={item.icon} fontSize="large" />
              </Grid>
            </Paper>
          </Link>
        </Grid>
      ))} */}

      {adminLinks.map((item, key) => (
        <Grid item xs={4} key={key}>
          <Link to={item.to}>
            <Paper className={classes.paper}>
              {/* <Grid item container alignItems="center" justifyContent="space-between">
                <Text text={item.title} variant="h4" />
                <Icon className={item.icon} fontSize="large" />
              </Grid> */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isMobile ? 'column-reverse' : 'row'}
                height={80}
              >
                <Text
                  text={
                    isMobile ? truncate(item.title, { length: 20 }) : item.title
                  }
                  variant={isMobile ? 'subtitle2' : 'h4'}
                  align="center"
                />
                {isMobile && <EcSpaceVertical size={4} />}
                <Icon className={item.icon} fontSize="large" />
              </Box>
              {/* <OneElementHome title={item.title} icon={item.icon} /> */}
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
