import { blue, green, orange, red, teal } from '@material-ui/core/colors'

// export const LOCATIONS = ['Radio 1', 'Scan 1', 'Echo 1', 'Echo 2']
// export const LOCATIONS_SHORT = [1, 2, 3, 4]
// export const resources = [
//   {
//     fieldName: 'location',
//     title: 'Location',
//     instances: [
//       { id: LOCATIONS[0], text: LOCATIONS[0], color: teal },
//       { id: LOCATIONS[1], text: LOCATIONS[1], color: orange },
//       { id: LOCATIONS[2], text: LOCATIONS[2], color: red },
//       { id: LOCATIONS[3], text: LOCATIONS[3], color: purple },
//     ],
//   },
// ]

export const roomsInit = [
  {
    text: 'Scan 1',
    id: 1,
    color: green,
    site: { id: 1, name: 'Site 1' },
    typeRoom: 1,
  },
  {
    text: 'Echo 1',
    id: 2,
    color: orange,
    site: { id: 2, name: 'Site 3' },
    typeRoom: 2,
  },
  {
    text: 'Mamo',
    id: 3,
    color: red,
    site: { id: 3, name: 'Site 2' },
    typeRoom: 3,
  },
  {
    text: 'Echo 2',
    id: 4,
    color: teal,
    site: { id: 4, name: 'Site 1', typeRoom: 2 },
    typeRoom: 2,
  },
  {
    text: 'Scan 2',
    id: 5,
    color: blue,
    site: { id: 5, name: 'Site 3', typeRoom: 1 },
    typeRoom: 1,
  },
]

export const typeRoom = [
  { label: 'Scan', id: 1 },
  { label: 'Echo', id: 2 },
  { label: 'Mamo', id: 3 },
]

// export const roomResources = [
//   {
//     fieldName: 'room',
//     title: 'Rooms',
//     instances: roomsInit
//   },
// ]
