import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { TextInput } from '@components/inputs'

import { EcSpaceHorizontal } from '@utils/Spacing'
import { doctorSchema } from '@utils/schemas'
import { DoctorDto } from '@services/api'

import { CommonEnrollment } from './CommonEnrollment'

type Values = {
  name: string
  numSiret: string
  status: string
  street: string
  postalCode: string
  city: string
  country: string
  phoneNumber: string
  fax?: string
  website?: string
  email: string
  rppsNumber: string
  streetAddress: string
  title: string
}

export function DoctorCheckInfos({ doctor }: { doctor?: DoctorDto }) {
  const { register, errors } = useForm<Values>({
    resolver: yupResolver(doctorSchema) as any,
    defaultValues: doctor,
  })
  console.log(errors)

  console.log('DoctorCheckInfos ', doctor)

  return (
    <CommonEnrollment>
      <Grid item xs={7} style={{ display: 'flex' }}>
        <TextInput
          style={{ maxWidth: 100 }}
          input="title"
          inputRef={register}
          errors={errors}
        />
        <EcSpaceHorizontal size={15} />
        <TextInput input="rppsNumber" inputRef={register} errors={errors} />
      </Grid>
      <Grid item xs={7} style={{ display: 'flex' }}>
        <TextInput input="firstName" inputRef={register} errors={errors} />
        <EcSpaceHorizontal size={15} />
        <TextInput input="lastName" inputRef={register} errors={errors} />
      </Grid>
      <Grid item xs={7} style={{ display: 'flex' }}>
        <TextInput input="streetAddress" inputRef={register} errors={errors} />
        <EcSpaceHorizontal size={15} />
        <TextInput input="city" inputRef={register} errors={errors} />
        <EcSpaceHorizontal size={15} />
        <TextInput input="postalCode" inputRef={register} errors={errors} />
      </Grid>
    </CommonEnrollment>
  )
}
