import { Control, Controller } from 'react-hook-form'

import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core'
// import { useIntl } from 'react-intl'

export type CheckBoxInputProps = CheckboxProps & {
  input: string
  control: Control<any>
  label: string
}

export function CheckBoxInput({
  input,
  control,
  label,
  ...props
}: CheckBoxInputProps) {
  // const intl = useIntl()

  // console.log('Errors', errors, errors[input]);

  return (
    <Controller
      name={input}
      control={control}
      render={({ onChange, value }) => (
        <FormControlLabel
          control={
            <Checkbox
              onChange={({ target }) => onChange(target.checked)}
              name={input}
              checked={value}
              color="primary"
              {...props}
            />
          }
          label={label}
        />
      )}
    />
  )
}
