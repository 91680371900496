import { useIntl } from 'react-intl'
import { DeepMap, FieldError } from 'react-hook-form'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

interface option {
  value: string | number
  label: string
}

type AutocompleteProps = {
  errors?: DeepMap<any, FieldError>
  input: string
  options: option[]
}

export function AutocompleteInput({
  errors,
  input,
  options,
  ...props
}: AutocompleteProps) {
  const intl = useIntl()

  return (
    <Autocomplete
      {...props}
      fullWidth
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          InputLabelProps={{
            style: {
              color: '#464855',
            },
          }}
          name={input}
          label={input && intl.formatMessage({ id: 'inputs.labels.' + input })}
          placeholder={
            input && intl.formatMessage({ id: 'inputs.placeholders.' + input })
          }
          error={errors && !!errors[input]}
          helperText={
            errors &&
            errors[input] &&
            intl.formatMessage({ id: errors[input].message })
          }
        />
      )}
    />
  )
}
