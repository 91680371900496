import { Filter } from '@services/api'
import { FinessActions } from '../actions'

export const findFiness = (params: {
  /**  */
  filters: Filter[]
  /**  */
  sort?: object
  /**  */
  limit?: number
  /**  */
  skip?: number
}) => ({
  type: FinessActions.GET_FINESS_REQUEST,
  payload: params,
})

export const autocompleteFiness = (params: {
  /**  */
  finessNumber: string
}) => ({
  type: FinessActions.GET_AUTOCOMPLETE_FINESS_REQUEST,
  payload: params,
})
