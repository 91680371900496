import { Control, Controller } from 'react-hook-form'

import { MenuItem, ListItemText, Checkbox } from '@material-ui/core'

import { TextInput, TextInputProps } from './TextInput'
import { useState } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type MultiSelectInputProps = {
  options: { value: string | number; label: string }[]
  control?: Control<any>
  fullWidth?: boolean
}

export function MultipleSelectInput({
  options,
  control,
  input,
  fullWidth = true,
  ...props
}: MultiSelectInputProps & TextInputProps) {
  const [option, setOption] = useState<string[]>([])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOption(event.target.value as string[])
  }

  return (
    <Controller
      name={input}
      control={control}
      render={() => (
        <TextInput
          style={{ borderRadius: 5 }}
          fullWidth={fullWidth}
          variant="outlined"
          size="small"
          InputLabelProps={{
            style: {
              color: '#464855',
            },
          }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
            },
          }}
          input={input}
          {...props}
          select
          SelectProps={{
            multiple: true,
            value: option,
            MenuProps: MenuProps,
            onChange: handleChange,
            //onClose: handleClose,
            renderValue: (selected) => (selected as string[]).join(', '),
          }}
        >
          {options.map((opt, i) => (
            <MenuItem key={i} value={opt.label}>
              <Checkbox checked={option.indexOf(opt.label) > -1} />
              <ListItemText primary={opt.label} />
            </MenuItem>
          ))}
        </TextInput>
      )}
    />
  )
}
