import { useState } from 'react'

import Switch, { SwitchProps } from '@material-ui/core/Switch'
import { FormControlLabel } from '@material-ui/core'

type SwitchInputProps = {
  label?: string
} & SwitchProps

export function SwitchInput({ label, ...props }: SwitchInputProps) {
  const [checked, setChecked] = useState(true)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('test switch' , { ...state, [event.target.name]: event.target.checked } )
    // setState({ ...state, [event.target.name]: event.target.checked })

    setChecked(event.target.checked)
  }

  return (
    <FormControlLabel
      style={{ marginLeft: 0, fontSize: 10 }}
      control={
        <Switch
          {...props}
          checked={checked}
          onChange={handleChange}
          name="checked"
          color="primary"
          size="small"
        />
      }
      label={label}
      labelPlacement="start"
    />
  )
}
