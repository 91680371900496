import { useState } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'

import { EcSpaceHorizontal } from '@utils/Spacing'

type Props = {
  children: React.ReactNode
}

export function CommonEnrollment({ children }: Props) {
  return (
    <Grid
      container
      spacing={3}
      style={{
        //   maxWidth: 550,
        // maxHeight: 400,
        // overflowY: 'auto',
        backgroundColor: '#f7f7f7',
        marginTop: 5,
        marginBottom: 20,
        borderRadius: 6,
      }}
      direction="row"
    >
      {children}
    </Grid>
  )
}

type nsProps = {
  label?: string
  _min: number
  _max: number
}

export const NumberStudies = ({ label, _min, _max }: nsProps) => {
  //const classes = useStyles();
  const [values, setValues] = useState<number>(_min)
  const [min] = useState(_min)
  const [max] = useState(_max)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(parseInt(event.target.value))
  }

  return (
    <div
      style={{
        // width: 230,
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
      }}
      className="number-studies"
    >
      <TextField
        label="Value"
        value={values}
        onChange={handleChange}
        name="numberformat"
        type="number"
        variant="outlined"
        size="small"
        InputLabelProps={{
          style: {
            color: '#464855',
          },
        }}
        InputProps={{
          style: {
            backgroundColor: '#fff',
            textAlign: 'center',
            width: 75,
          },
          inputProps: {
            max: 70,
            min: 10,
          },
        }}
      />
      <EcSpaceHorizontal />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography>{label}</Typography>
        <Typography>
          <span className={values < min ? 'error' : ''}>Min: {min}</span> |{' '}
          <span className={values > max ? 'error' : ''}>Max: {max}</span>{' '}
        </Typography>
      </div>
    </div>
  )
}
