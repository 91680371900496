import { TableCell, TableRow, Typography } from '@material-ui/core'

export function TimeTableLayoutComponent(props: any) {
  const children: any = props.children

  return (
    <TableRow
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        padding: 2,
        marginTop: 6,
      }}
    >
      {children.map((value: any, i: number) => {
        const date = new Date(value.key)

        return (
          <TableCell
            key={i}
            className="ec-timeScal"
            style={{
              backgroundColor: i === 3 || i === 0 ? '#009DA0' : '#f1f1f1',
              padding: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none',
              borderRadius: 4,
              height: 40,
              width: 150,
            }}
          >
            <Typography
              align="center"
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: i === 3 || i === 0 ? '#fff' : '#000',
              }}
            >
              {date.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
          </TableCell>
        )
      })}
    </TableRow>
  )
}
