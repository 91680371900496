import { useState } from 'react'

import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Hidden,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import {
  AccountCircle,
  ExitToApp,
  Mail,
  Menu as MenuIcon,
  MoreVert,
  Notifications,
} from '@material-ui/icons'
import { upperFirst } from 'lodash'

import { Account, logout } from '@state/reducers/authReducer'
import { ClientDto } from '@services/api'

import Text from './Text'
// import history from '@services/history'

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    display: 'flex',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
type NavBarProps = {
  user: Account
  client?: ClientDto
  role?: string
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export default function NavBar({
  toggleDrawer,
  user,
  client,
  role,
}: NavBarProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    dispatch(logout())
    //TODO: Clear all
  }

  const toggle = () => {
    toggleDrawer((prevState) => !prevState)
  }

  const handleProfile = () => history.push('/profile')

  const menuId = 'account-menu'
  const renderAccountMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </MenuItem>
      <MenuItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      //id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <Mail color="disabled" />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfile}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem button onClick={handleLogout}>
        <IconButton>
          <ExitToApp />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )

  return (
  // <div className={classes.grow}>

    <Hidden xsDown>
      <AppBar
        position="static"
        style={{ backgroundColor: 'transparent' }}
        elevation={0}
      >
        <Toolbar>
          {client && role === 'admin' && (
            <Box ml={8.5} display="flex" alignItems="center">
              <Icon
                className="fa fa-hospital-user"
                style={{ marginRight: '1rem' }}
                color="primary"
              />

              <Text
                variant={isMobile ? 'h5' : 'h4'}
                text={upperFirst(client?.name)}
                color="primary"
              />
            </Box>
          )}

          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="open drawer"
            onClick={toggle}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip title="Messages">
              <IconButton aria-label="show 4 new mails">
                <Badge badgeContent={4} color="secondary">
                  <Mail />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications">
              <IconButton aria-label="show 17 new notifications">
                <Badge badgeContent={17} color="secondary">
                  <Notifications />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip title={user.lastName + ' ' + user.firstName}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              >
                <Avatar>
                  {user.lastName[0]}
                  {user.firstName[0]}
                </Avatar>
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </div>
        </Toolbar>
        {renderMobileMenu}
        {renderAccountMenu}
      </AppBar>
    </Hidden>
    // </div>
  )
}
