import { Control, Controller } from 'react-hook-form'

import {
  CheckboxProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { EcSpaceHorizontal } from '@utils/Spacing'
// import { useIntl } from 'react-intl'

export type RadioInputProps = CheckboxProps & {
  input: string
  control: Control<any>
  label: string
  options: { value: string | number; label: string }[]
  radioGroupClass?: string
  defaultValue?: string
  labelClass?: string
  row?: boolean
}

export function RadioInput({
  input,
  control,
  label,
  options,
  radioGroupClass,
  labelClass,
  defaultValue,
  row,
}: RadioInputProps) {
  // const intl = useIntl()
  return (
    <div className={radioGroupClass}>
      {/* <label className={labelClass} >
        {label}
      </label> */}
      <Typography className={labelClass}>{label}</Typography>
      <Controller
        as={
          <RadioGroup defaultValue={defaultValue} row={row || false}>
            {options.map(({ value, label }, index) => (
              <>
                <FormControlLabel
                  key={index}
                  value={value}
                  control={<Radio size="small" color="primary" />}
                  label={label}
                />
                <EcSpaceHorizontal size={20} />
              </>
            ))}
          </RadioGroup>
        }
        name={input}
        control={control}
      />
    </div>
  )
}
