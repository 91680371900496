import { useSelector } from 'react-redux'

import { IconButton, MenuItem, Theme, makeStyles, Box } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { TextInput } from '@components/inputs'

import { RootState } from '@state/reducers/root'
import { EcSpaceHorizontal } from '@utils/Spacing'
import Text from '../Text'

const useStyles = makeStyles((theme: Theme) => ({
  iconrefresh: {
    color: theme.palette.primary.main,
    fontSize: 27,
  },
}))

export const DisplayWeekNumber = (currentDate: any) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 15,
        top: 85,
        zIndex: 10,
        fontSize: '0.80rem',
        fontWeight: 600,
      }}
    >
      Week: {getNumberWeek(currentDate)}
    </div>
  )
}

function getNumberWeek({ currentDate }: any) {
  const now = new Date(currentDate)
  const onejan = new Date(now.getFullYear(), 0, 1)
  const week = Math.ceil(
    ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7,
  )

  return week
}

export const RefreshButton = () => {
  const classes = useStyles()

  return (
    <IconButton
      className={classes.iconrefresh}
      aria-label="delete"
      size="small"
    >
      <Refresh className={classes.iconrefresh} />
    </IconButton>
  )
}

export const AmPmSelect = ({ onHoursChange, meridiem }: any) =>
// { onEnHourChange: (time: number) => void
//   onStartHourChange: (time: number) => void  }
{
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
      case 'am':
        return onHoursChange({ startHour: 8, endHour: 13, meridiem: 'am' })
      case 'pm':
        return onHoursChange({ startHour: 12, endHour: 18, meridiem: 'pm' })
      case 'ampm':
        return onHoursChange({ startHour: 8, endHour: 18, meridiem: 'ampm' })
      default:
        return onHoursChange({ startHour: 8, endHour: 18, meridiem: 'ampm' })
    }
  }

  const options = [
    { value: 'ampm', label: 'AM+PM' },
    { value: 'am', label: 'AM' },
    { value: 'pm', label: 'PM' },
  ]

  return (
    <TextInput
      input="session"
      select
      fullWidth={false}
      value={meridiem}
      onChange={handleChange}
      style={{ minWidth: 102 }}
    >
      {options.map((option, index: number) => (
        <MenuItem key={index} value={option.value}>
          <em>{option.label}</em>
        </MenuItem>
      ))}
    </TextInput>
  )
}

export const CellDuration = ({ onCellDurationChange, duration }: any) => {
  const options = [
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 25, label: '25 min' },
    { value: 30, label: '30 min' },
    { value: 45, label: '45 min' },
    { value: 60, label: '1 h' },
  ]
  return (
    <TextInput
      input="timeInterval"
      size="small"
      select
      fullWidth={false}
      value={duration}
      onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
        onCellDurationChange(parseInt(target.value))
      }
      style={{ minWidth: 102 }}
    >
      {options.map((option, index: number) => (
        <MenuItem key={index} value={option.value}>
          <em>{option.label}</em>
        </MenuItem>
      ))}
    </TextInput>
  )
}

// export const filterRooms = (items, roomId) => items.filter(item => (
//     !roomId || item.roomId === roomId
// ));

export const FilterRooms = (items) => {
  const { roomsSelected } = useSelector(
    ({ appointement }: RootState) => appointement,
  )

  return items.filter((item) => roomsSelected.includes(String(item.roomId)))
}
interface OneDisplayInputProps {
  children?: React.ReactNode
  label: string
  required?: boolean
}

export const OneDisplayInput = ({
  label,
  required,
  children,
}: OneDisplayInputProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
    >
      <Box width="30%" display="flex">
        <Text text={label} format={true} />
        {required && (
          <>
            <EcSpaceHorizontal size={2} />
            <span> *</span>
          </>
        )}
      </Box>

      <Box width="60%">{children}</Box>
    </Box>
  )
}
