import { Fragment, useState } from 'react'

import {
  Box,
  Icon,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import TextsmsIcon from '@material-ui/icons/Textsms'
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled'
import ChildCareIcon from '@material-ui/icons/ChildCare'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import CopyrightIcon from '@material-ui/icons/Copyright'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'

import { ExceptionalOpeningDialog, UpdateAppointmentDialog } from '../dialogs'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textContainer: {
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  time: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  containerAppointement: {
    width: '100%',
    padding: 5,
    color: 'white',
  },
}))

type OneAppointement = {
  data: any
  formatDate: any
}

export function DisplayOneAppointment({ data, formatDate }: OneAppointement) {
  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)
  const [openModalExceptional, setOpenModalExceptional] = useState(false)

  return (
    <>
      {data.taken ? (
        <StripesBox
          type={data.taken}
          onClick={
            data.taken === 'close'
              ? () => {}
              : () => {
                setOpenModalExceptional(true)
              }
          }
        />
      ) : (
        <div
          className={classes.containerAppointement}
          style={{ cursor: 'pointer', height: '100%' }}
          onClick={() => {
            setOpenModal(true)
          }}
        >
          <DisplayIconInfo data={data} formatDate={formatDate} />
          <ListIcon />
          <div
            className={classes.title}
          >{`${data.title[0]}.${data.title[1]}.${data.title[2]}`}</div>
          <div className={classes.title}>{data.title}</div>
          <div className={classes.text}>{data.location}</div>
          <div className={classes.textContainer}>
            <div className={classes.time}>
              {formatDate(data.startDate, {
                hour: 'numeric',
                minute: 'numeric',
              })}
            </div>
            <div className={classes.time}>{' - '}</div>
            <div className={classes.time}>
              {formatDate(data.endDate, { hour: 'numeric', minute: 'numeric' })}
            </div>
          </div>
        </div>
      )}

      {openModal && (
        <UpdateAppointmentDialog
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
        />
      )}

      {openModalExceptional && (
        <ExceptionalOpeningDialog
          open={openModalExceptional}
          onClose={() => {
            setOpenModalExceptional(false)
          }}
        />
      )}
    </>
  )
}

const DisplayIconInfo = ({ data, formatDate }: OneAppointement) => {
  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: 10,
    },
  }))(Tooltip)

  return (
    <HtmlTooltip
      title={
        <Fragment>
          <div style={{ backgroundColor: '#f1f1f1', padding: 7 }}>
            <span>
              {`${formatDate(data.startDate, {
                hour: 'numeric',
                minute: 'numeric',
              })} - ${formatDate(data.endDate, {
                hour: 'numeric',
                minute: 'numeric',
              })}`}
              {/* 08:00 - 08:30 - jeudi 30 avril 2021 */}
            </span>
          </div>
          <div style={{ marginTop: 5 }}>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: 'green', fontSize: 15 }}>
                {data.location}
              </label>
            </div>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: '#666' }}>Statut</label>:{' '}
              <span style={{ fontSize: 13 }}>M. Mandon</span>
            </div>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: '#666' }}>Age</label>:{' '}
              <span style={{ fontSize: 13 }}>38 ans</span>
            </div>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: '#666' }}>Telephone mobile</label>:{' '}
              <span style={{ fontSize: 13 }}>+33 x xx xx xx xx</span>
            </div>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: '#666' }}>Type</label>:{' '}
              <span style={{ fontSize: 13 }}>Panoramique</span>
            </div>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: '#666' }}>Examen</label>:{' '}
              <span style={{ fontSize: 13 }}>Panoramique</span>
            </div>
            <div style={{ marginTop: 5 }}>
              <label style={{ color: '#666' }}>Praticien</label>:{' '}
              <span style={{ fontSize: 13 }}>Vincent</span>
            </div>
          </div>
        </Fragment>
      }
    >
      <InfoIcon fontSize="small" className="schedule-info-icon" />
    </HtmlTooltip>
  )
}

const ListIcon = () => {
  return (
    <>
      <TextsmsIcon fontSize="small" />
      <PhoneDisabledIcon fontSize="small" />
      <ChildCareIcon fontSize="small" />
      <CheckBoxIcon />
      <Icon
        fontSize="small"
        className="fas fa-ambulance"
        style={{ marginLeft: 3, marginRight: 5 }}
      />
      <PictureAsPdfIcon />
      <CopyrightIcon />
      <HourglassEmptyIcon />
      <LaptopMacIcon />
      <Icon
        fontSize="small"
        className="far fa-clone"
        style={{ marginLeft: 3 }}
      />
    </>
  )
}

type StripesBoxProps = {
  type: string
  onClick: () => void
}

function StripesBox({ type, onClick }: StripesBoxProps) {
  const background =
    type === 'close'
      ? 'repeating-linear-gradient( 45deg,   gray, gray 2px, white 2px, white 12px)'
      : 'repeating-linear-gradient( 45deg,   green, green 2px, white 2px, white 12px)'

  const msg = type === 'close' ? 'Salle Fermee' : 'En pause'

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      //style={{ backgroundImage: `url(${imageBack})` }}
      style={{
        background: background,
        cursor: type === 'close' ? '' : 'pointer',
      }}
      onClick={onClick}
    >
      <Typography
        style={{
          fontSize: 13,
          fontWeight: 600,
          padding: '0px 3px',
          backgroundColor: 'white',
          color: type === 'close' ? '#666' : 'green',
        }}
      >
        {msg}
      </Typography>
    </Box>
  )
}
