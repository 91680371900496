import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { roomsInit } from '@components/schedule/params'
import { dataGroupingTest } from '@utils/constants'
import { filter } from 'lodash'

type appointementState = {
  data: Array<any>
  // currentDate: string
  // currentViewName: string
  currentFilter?: string
  resources: Array<any>
  roomsSelected: Array<any>
  typeRoomSelected?: number
  hours: {
    startHour: number
    endHour: number
    meridiem: string
  }
  cellDuration: number
  appointmentDialog: {
    openAdd?: boolean
    openUpdate?: boolean
    data: Object
  }
}

const initialState: appointementState = {
  data: dataGroupingTest,

  currentFilter: '',
  resources: [
    {
      fieldName: 'roomId',
      title: 'Rooms',
      instances: [roomsInit[0]],
    },
  ],
  roomsSelected: ['1'],
  typeRoomSelected: undefined,
  hours: {
    startHour: 8,
    endHour: 18,
    meridiem: 'ampm',
  },
  cellDuration: 30,
  appointmentDialog: {
    openAdd: false,
    openUpdate: false,
    data: {},
  },
}

const appointementSlice = createSlice({
  name: 'appointement',
  initialState,
  reducers: {
    getAppointement: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },

    openAddAppointmentDialog: (
      state,
      action: PayloadAction<appointementState['appointmentDialog']>,
    ) => {
      state.appointmentDialog = {
        openAdd: true,
        data: action.payload.data,
      }
    },

    openUpdateAppointmentDialog: (
      state,
      action: PayloadAction<appointementState['appointmentDialog']>,
    ) => {
      state.appointmentDialog = {
        openUpdate: true,
        data: action.payload.data,
      }
    },

    closeAppointmentDialog: (state) => {
      state.appointmentDialog = {
        openAdd: false,
        openUpdate: false,
        data: {},
      }
    },

    // onChange: (state, action: PayloadAction<any>) => {
    //   state[action.payload.partialStateName as any] =
    //     action.payload.partialStateValue
    // },

    // onCurrentDateChange: (state, action: PayloadAction<any>) => {
    //   state.currentDate = action.payload
    // },
    // onCurrentViewNameChange: (state, action: PayloadAction<any>) => {
    //   state.currentViewName = action.payload
    // },

    setCellDuration: (state, action: PayloadAction<number>) => {
      state.cellDuration = action.payload
    },
    setCurrentFilter: (state, action: PayloadAction<any>) => {
      state.currentFilter = action.payload
    },

    setResources: (state, action: PayloadAction<Array<any>>) => {
      const newInstances = [
        {
          fieldName: 'roomId',
          title: 'Rooms',
          instances: filter(roomsInit, (item) =>
            action.payload.includes(String(item.id)),
          ),
        },
      ]

      state.resources = newInstances
    },

    setHours: (state, action: PayloadAction<appointementState['hours']>) => {
      state.hours = action.payload
    },

    setRoomsSelected: (
      state,
      action: PayloadAction<appointementState['roomsSelected']>,
    ) => {
      state.roomsSelected = action.payload
    },

    setTypeRoomSelected: (state, action: PayloadAction<number | undefined>) => {
      state.typeRoomSelected = action.payload
    },

    onSelectRoomChange: (state, action: PayloadAction<string>) => {
      let newSelectedRoom: Array<any> = []
      if (state.roomsSelected.includes(action.payload)) {
        //inside

        newSelectedRoom = filter(
          state.roomsSelected,
          (o) => o.key !== action.payload,
        )
      } else {
        newSelectedRoom = [...state.roomsSelected, action.payload]
      }
      state.roomsSelected = newSelectedRoom
    },
  },
})

export const {
  getAppointement,
  setCellDuration,
  setCurrentFilter,
  setHours,
  onSelectRoomChange,
  setRoomsSelected,
  setResources,
  openAddAppointmentDialog,
  openUpdateAppointmentDialog,
  closeAppointmentDialog,
  setTypeRoomSelected,
} = appointementSlice.actions

export default appointementSlice.reducer
